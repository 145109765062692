import { useCallback } from "react";
import { find, propEq, prop, type } from "ramda";

function useSimpleValueSelect({ onChange: originOnChange, value: originValue, options }) {
  const onChange = useCallback(
    (option) => originOnChange(type(option) === "Array" ? option.map(({ value }) => value) : prop("value", option)),
    [originOnChange],
  );

  const value =
    type(originValue) === "Array"
      ? originValue.map((originVal) => find(propEq("value", originVal), options))
      : find(propEq("value", originValue), options);

  return [value, onChange];
}

export default useSimpleValueSelect;
