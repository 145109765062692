import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { UserInfo, DropDown, StylePageNav } from "../index";
import header from "../../header.module.sass";

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  accountId: PropTypes.string,
  id: PropTypes.string,
};
const defaultProps = {
  accountId: "",
  id: "",
};

function ToggleMenuMobile({ showNav, isOpen, accountId, id }) {
  const toggleClasses = cx({
    [`${header.mobileInfoBlock}`]: true,
    [`${header.mobileInfoBlockActive}`]: isOpen,
  });

  return (
    <div className={toggleClasses}>
      <UserInfo />
      <DropDown />
      {showNav && <StylePageNav accountId={accountId} id={id} />}
    </div>
  );
}

ToggleMenuMobile.propTypes = propTypes;
ToggleMenuMobile.defaultProps = defaultProps;

export default ToggleMenuMobile;
