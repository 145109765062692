import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cl from "classnames";
import InputPhotoFile from "components/shared/formDefault/InputPhotoFile";
import { connect } from "react-redux";
import * as actions from "actions";

import styles from "./index.module.sass";
// import { getShopperData } from "selectors/shopperProfile";

const propTypes = {};

const defaultProps = {};

function Avatar({ shopper, postProfileData, handleClear }) {
  const { avatar } = shopper;
  const avatarUploadRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const [isSelectedPhoto, setIsSelectedPhoto] = useState(false);

  useEffect(() => {
    // fetchShopperPhotos();
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    if (isSelectedPhoto) {
      clearTimeout(typingTimer);
      const typingTimer = setTimeout(() => {
        setIsSelectedPhoto(false);
      }, 500);
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
    postProfileData({ ...shopper, avatar: e.target.files[0] });
    setIsSelectedPhoto(true);
  };

  return (
    <div className={styles.avatar}>
      {avatar ? (
        <>
          <img src={avatar} />
          <div className={styles.fileInputWrapper}>
            <input type="file" className={styles.fileInput} onChange={onSelectFile} />
          </div>
          <button onClick={() => handleClear(() => null)}>remove</button>
        </>
      ) : (
        <>
          <div className={styles.placeholder} />
          <div className={styles.fileInputWrapper}>
            <input type="file" className={styles.fileInput} onChange={onSelectFile} />
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    // shopper: getShopperData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  // fetchShopperPhotos: () => dispatch(actions.fetchShopperPhotos()),
  postProfileData: (photo) => dispatch(actions.postProfileData(photo)),
  // handleRemoveImage: (id) => dispatch(actions.removeImage(id)),
});

Avatar.defaultProps = defaultProps;
Avatar.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
