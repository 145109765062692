import React from "react";
import { Switch, Route } from "react-router-dom";
import * as routes from "constants/routes";

import { Header, SideBar, Footer } from "../index";
import { Home, Profile, InstagramConnect } from "../Shopper";

function ShopperRouter() {
  return (
    <>
      <Header isStylist={false} />
      <Switch>
        <Route path={routes.AUTH_INSTAGRAM_CONNECT_PATH} component={InstagramConnect} />
        <Route path={routes.DASHBOARD_SHOPPER_HOME_PATH} component={Home} />
        <Route path={routes.DASHBOARD_SHOPPER_PROFILE_PATH} component={Profile} />
      </Switch>
      <SideBar />
      {/* <Footer /> */}
    </>
  );
}

export default ShopperRouter;
