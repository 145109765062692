import React from "react";
// import PropTypes from "prop-types";

import SetupBlock from "../SetupBlock";
import setting from "../../setting.module.sass";
import WidgetSnippet from "./shared/Snippet";

const propTypes = {};
const defaultProps = {};

function ChatWidget(props) {
  return (
    <div className={setting.contentLeft}>
      <SetupBlock
        isSeparator={false}
        title="CHAT WIDGET"
        subtitle="To chat with your visitors, you’ll need to embed the widget on your website. Copy the following script and insert it into your website’s HTML source code before the closing body tag (</body>)."
      >
        <WidgetSnippet {...props} />
      </SetupBlock>
    </div>
  );
}

ChatWidget.propTypes = propTypes;
ChatWidget.defaultProps = defaultProps;

export default ChatWidget;
