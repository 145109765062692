import { mergeDeepRight, set, lensPath } from "ramda";

// import { safeSet } from "utils/entities";

import * as actionTypes from "../../actionTypes/entities";
import { initialState } from "../../selectors/entities";

export default function(state = initialState, { type, payload } = {}) {
  switch (type) {
    case actionTypes.ENTITIES_DELETE:
      return deleteEntities(state, payload);
    case actionTypes.ENTITIES_MERGE:
      return mergeDeepRight(state, payload);
    case actionTypes.ENTITIES_SYNC:
      return syncEntities(state, payload);
    // case actionTypes.ENTITIES_SET:
    //   if (safeSet(payload)) return setEntities(state, payload);
    //   return state;
    default:
      return state;
  }
}

function syncEntities(state, { entity, key }) {
  return { ...state, [key]: { ...state[key], [entity.id]: entity } };
}

function deleteEntities(state, { entityId, key }) {
  return { ...state, [key]: { ...state[key], [entityId]: undefined } };
}

// function setEntities(state, { key, entityId, path, value }) {
//   return set(lensPath([key, entityId, ...path]), value, state);
// }
