import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { without } from "ramda";

import * as actions from "actions";
import { getProductsForEditor } from "selectors/products";
import shop from "../../../Shop/shop.module.sass";

const propTypes = {
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    featuredImage: PropTypes.shape({
      originalSrc: PropTypes.string,
    }),
  }),
  onAddProductToEditor: PropTypes.func.isRequired,
  onRemoveProductToEditor: PropTypes.func.isRequired,
  onAddProductOfStorageToFavorites: PropTypes.func.isRequired,
  withoutPrice: PropTypes.bool,
};
const defaultProps = {
  product: {
    variants: [],
    featuredImage: {
      originalSrc: "",
    },
  },
  withoutPrice: false,
};

function ProductCard(props) {
  const {
    product,
    index,
    onAddProductToEditor,
    onRemoveProductToEditor,
    onProductToFavorites,
    onAddProductOfStorageToFavorites,
    productsEditor,
    shopperId,
    withoutPrice,
    sliderStyle,
    accountId,
  } = props;
  const { title, variants, formattedPrice, featuredImage, id, vendor, isFavorite } = product;
  const filteredVariants = (variants && variants.filter((v) => v.title && v)) || [];

  useEffect(() => {
    let productsFavoriteStorage;
    if (localStorage.getItem(`ProductsFavorites: ${shopperId}`) === null) {
      productsFavoriteStorage = [];
    } else {
      productsFavoriteStorage = JSON.parse(localStorage.getItem(`ProductsFavorites: ${shopperId}`));
    }
    onAddProductOfStorageToFavorites(productsFavoriteStorage);
  }, []);

  //  FIX PRIVATE METTHOD
  function onToggleProductToEditor(id) {
    if (!isAddItemEditor()) {
      let localData;
      if (localStorage.getItem(`ProductsToEditor: ${shopperId}`) === null) {
        localData = [];
      } else {
        localData = JSON.parse(localStorage.getItem(`ProductsToEditor: ${shopperId}`));
      }
      localData.push(id);
      localStorage.setItem(`ProductsToEditor: ${shopperId}`, JSON.stringify(localData));
      onAddProductToEditor(id);
    } else {
      const editorCollection = JSON.parse(localStorage.getItem(`ProductsToEditor: ${shopperId}`));
      const valuesToRemove = [id];
      const filteredItems = without(valuesToRemove, editorCollection);
      localStorage.setItem(`ProductsToEditor: ${shopperId}`, JSON.stringify(filteredItems));
      onRemoveProductToEditor([id]);
    }
  }

  function onToggleProductFavorite() {
    onProductToFavorites(accountId, id, isFavorite, product);
  }

  const isAddItemEditor = () => {
    if (productsEditor.some((item) => item.id === id)) {
      return true;
    }
    return false;
  };

  return (
    <li key={index} className={shop.productItem} style={sliderStyle}>
      <div className={shop.productWrapper}>
        <div className={shop.productBtnGroup}>
          <i
            className={isFavorite ? "iconFavorite iconFavoriteTarget" : "iconFavorite"}
            onClick={() => onToggleProductFavorite()}
          />
          <i
            className={isAddItemEditor() ? "iconRemove" : "iconAdd"}
            data-name={`${isAddItemEditor() ? "removeProduct" : "addProduct"}`}
            onClick={() => onToggleProductToEditor(id)}
          />
        </div>
        <img className={shop.productImg} src={featuredImage && featuredImage.src} alt="product img" />

        {!withoutPrice && (
          <div className={shop.productItemFooter}>
            <span>{vendor}</span>
            <p className={shop.productItemDescription}>{title}</p>
            {filteredVariants.length > 0 && (
              <div className={shop.variants}>
                <select name="choice">
                  {filteredVariants.map(
                    (v) =>
                      v.title && (
                        <option key={v.id} value={v.id}>
                          {v.title}
                        </option>
                      ),
                  )}
                </select>
              </div>
            )}

            <p className={shop.productItemPrice}>{formattedPrice}</p>
          </div>
        )}
      </div>
    </li>
  );
}

function mapStateToProps(state) {
  return {
    productsEditor: getProductsForEditor(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  onAddProductToEditor: (productId) => dispatch(actions.addProductToEditorSucceses(productId)),
  onProductToFavorites: (accountId, productId, isFavorite, product) =>
    dispatch(actions.productToFavorites(accountId, productId, isFavorite, product)),
  onRemoveProductToEditor: (productId) => dispatch(actions.removeProductToEditorSucceses(productId)),
  onAddProductOfStorageToFavorites: (productIds) =>
    dispatch(actions.addProductOfStorageToFavoritesSucceses(productIds)),
});

ProductCard.propTypes = propTypes;
ProductCard.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
