import React from "react";
import PropTypes from "prop-types";

import Button from "components/shared/formDefault/Button";
import styles from "./index.module.sass";

const propTypes = {
  submitting: PropTypes.bool,
};

const defaultProps = {
  submitting: false,
};

function StaffRole({ submitting }) {
  return (
    <div className={styles.btnGroup}>
      <div className={styles.btnGroupContent}>
        <Button type="submit" disable={submitting}>
          {submitting ? "saving..." : "save"}
        </Button>
      </div>
    </div>
  );
}

StaffRole.defaultProps = defaultProps;
StaffRole.propTypes = propTypes;

export default StaffRole;
