export const AUTH_TOKEN_PATH = "/token";
export const NOT_FOUND_PAGE = "/404";

export const REFERENCES_PATH = "/references";

export const WIDGET_CODE_PATH = "/accounts/:accountId/widget";

export const DASHBOARD_AUTH_PATH = "/auth";
export const DASHBOARD_AUTH_REGISTRATION_PATH = "/auth/sign_up";
export const DASHBOARD_AUTH_SIGN_IN_PATH = "/auth/sign_in";
export const DASHBOARD_AUTH_SIGN_OUT_PATH = "/auth/sign_out";
export const DASHBOARD_AUTH_RESTORE_PASSWORD_PATH = "/auth/password/reset";
export const DASHBOARD_AUTH_UPDATE_PASSWORD_PATH = "/auth/password";
export const DASHBOARD_AUTH_POST_UPDATE_PASSWORD_PATH = "/auth/password/update/:token";

export const CHOOSE_ACCOUNT_PATH = "/";
export const DASHBOARD_ACCOUNTS_PATH = "/accounts";
export const DASHBOARD_ACCOUNT_PATH = "/accounts/:accountId";
export const DASHBOARD_ACCOUNT_CONSULTANT_PATH = "/accounts/:accountId/consultant";
export const DASHBOARD_ACCOUNT_CONSULTANT_PAUSE_PATH = "/accounts/:accountId/consultant/pause";
export const DASHBOARD_ACCOUNT_PRODUCTS_PATH = "/accounts/:accountId/products";
export const DASHBOARD_ACCOUNT_PRODUCT_PATH = "/accounts/:accountId/products/:id";
export const DASHBOARD_ACCOUNT_PRODUCT_RECOMMENDED_PATH = "/accounts/:accountId/products/recommended";
export const DASHBOARD_ACCOUNT_SHOPPER_PRODUCTS_WISHLIST_PATH = "/accounts/:accountId/shoppers/:id/products/wished";
export const DASHBOARD_ACCOUNT_PRODUCT_FAVORITE_PATH = "/accounts/:accountId/products/:id/favorite";

export const DASHBOARD_ACCOUNT_SHOPPER_STYLE_CLOSET_PATH = "/accounts/:accountId/shoppers/:id/style/closet";
export const DASHBOARD_ACCOUNT_SHOPPER_STYLE_BROWSE_PATH = "/accounts/:accountId/shoppers/:id/style/browse";
export const DASHBOARD_ACCOUNT_SHOPPER_STYLE_WISHLIST_PATH = "/accounts/:accountId/shoppers/:id/style/wishlist";
export const DASHBOARD_ACCOUNT_SHOPPER_STYLE_ABANDONED_PATH = "/accounts/:accountId/shoppers/:id/style/abandoned";
export const DASHBOARD_ACCOUNT_SHOPPER_STYLE_RETURNS_PATH = "/accounts/:accountId/shoppers/:id/style/returns";

export const DASHBOARD_ACCOUNT_INSTAGRAM_MEDIA_PATH = "/accounts/:accountId/consultant/instagram_media";
export const DASHBOARD_ACCOUNT_SHOPPER_CURRENT_EVENTS_PATH = "/accounts/:accountId/shoppers/:id/events/current";

export const DASHBOARD_ACCOUNT_APPEARANCE_PATH = "/accounts/:accountId/setup/appearance";

export const DASHBOARD_ACCOUNT_ANALYTICS_PATH = "/accounts/:accountId/analytics";

export const DASHBOARD_ACCOUNT_STORE_PATH = "/accounts/:accountId/store";

export const DASHBOARD_ACCOUNT_REPORTS_PATH = "/accounts/:accountId/reports";

export const DASHBOARD_ACCOUNT_SETTINGS_SETUP_PATH = "/accounts/:accountId/settings/setup";
export const DASHBOARD_ACCOUNT_SETTINGS_SETUP_INTEGRATE_PATH = "/accounts/:accountId/settings/setup/integrate";
export const DASHBOARD_ACCOUNT_SETTINGS_SETUP_EMBED_PATH = "/accounts/:accountId/settings/setup/embed";
export const DASHBOARD_ACCOUNT_SETTINGS_SETUP_APPEARANCE_PATH = "/accounts/:accountId/settings/setup/appearance";

export const DASHBOARD_ACCOUNT_SHOPPERS_PATH = "/accounts/:accountId/shoppers";
export const MESSAGES_DASHBOARD_ACCOUNT_SHOPPER_CHAT_PATH = "/accounts/:accountId/shoppers/:id/messages";
export const DASHBOARD_ACCOUNT_SHOPPER_PATH = "/accounts/:accountId/shoppers/:id";

export const WEATHER_ACCOUNT_SHOPPER_PATH = "/accounts/:accountId/shoppers/:id/weather";

export const SHOP_DASHBOARD_ACCOUNT_SHOPPER_PATH = "/accounts/:accountId/shoppers/:id/shop";
export const PROFILE_DASHBOARD_ACCOUNT_SHOPPER_PATH = "/accounts/:accountId/shoppers/:id/profile";
export const STYLE_DASHBOARD_ACCOUNT_SHOPPER_PATH = "/accounts/:accountId/shoppers/:id/style";

export const INVITE_CLIENT_ACCOUNT_STAFF_PATH = "/accounts/:accountId/staff/:staffId/invite_client";
export const BULK_UPLOAD_CLIENT_ACCOUNT_STAFF_PATH = "/accounts/:accountId/staff/:staffId/bulk_upload";

// staff page
export const DASHBOARD_ACCOUNT_STAFF_LIST_PATH = "/accounts/:accountId/staff";
export const DASHBOARD_ACCOUNT_STAFF_PATH = "/accounts/:accountId/staff/:staffId";
export const DASHBOARD_ACCOUNT_STAFF_NEW_PATH = "/accounts/:accountId/staff/new";
export const DASHBOARD_ACCOUNT_STAFF_ACTIVATE_PATH = "/accounts/:accountId/staff/:staffId/activate";
export const DASHBOARD_ACCOUNT_STAFF_DEACTIVATE_PATH = "/accounts/:accountId/staff/:staffId/deactivate";

export const LOGIN_AS_PATH = "/login";

// shopper page

export const DASHBOARD_SHOPPER_HOME_PATH = "/shopper/home";
export const DASHBOARD_SHOPPER_PROFILE_PATH = "/shopper/profile";
export const DASHBOARD_SHOPPER_PROFILE_PASSWORD_PATH = "/shopper/profile/password";
export const DASHBOARD_SHOPPER_PHOTOS_PATH = "/shopper_photo";
export const DASHBOARD_SHOPPER_DELETE_PHOTO_PATH = "/shopper_photo/:id";
export const DASHBOARD_SHOPPER_INSTAGRAM_FEED_PATH = "/social_media/:id/instagram_photos";
export const AUTH_INSTAGRAM_CONNECT_PATH = "/auth/instagram/connect";
export const AUTH_INSTAGRAM_GRAPH_PATH = "/auth/instagram_graph/callback";

export const SHOPPER_PROFFILE_DELETE_ME_EMAIL = "/shopper/delete_me";
export const SHOPPER_PROFFILE_DOWNLOAD_DATA_EMAIL = "/shopper/download_data";
export const SHOPPER_PROFFILE_DEACTIVATE_LUXLOCK_EMAIL = "/shopper/deactivate";
export const SHOPPER_PROFFILE_PORTABILITY_EMAIL = "/shopper/portability";

export const ME_PATH = "/me";

export const getPath = (path, params) => {
  let result = path;
  Object.keys(params).forEach((key) => {
    result = result.replace(`:${key}`, params[key]);
  });
  return result;
};
