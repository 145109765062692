import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import cl from "classnames";

import * as actions from "actions";
import * as requestTypes from "constants/requestTypes";
import { requestInProcess } from "selectors/request";
import { includes } from "ramda";

import { history } from "stores/configureStore";
import { DASHBOARD_ACCOUNT_PATH, DASHBOARD_SHOPPER_HOME_PATH, getPath } from "../../constants/routes";
import { getAccountsCollection } from "../../selectors/accounts";
import { getSessionMe } from "../../selectors/session";

import style from "./style.module.sass";

const propTypes = {
  accountsCollection: PropTypes.oneOfType([
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.object),
    }),
    PropTypes.object,
  ]),
  me: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    marketingOptIn: PropTypes.bool,
    powerBiUrl: PropTypes.string,
  }),
  isLoadingAccounts: PropTypes.bool,
};
const defaultProps = {
  accountsCollection: {
    data: [],
  },
  me: {},
  isLoadingAccounts: true,
};
function ChooseCloset({ accountsCollection, isLoadingAccounts, me }) {
  document.title = "Luxlock";
  if (isLoadingAccounts) {
    return null;
  }
  if (isLoadingAccounts === false && !accountsCollection.data) {
    history.push(`/shopper/home`);
  }

  const isAddItemFavorites = (accountId) => {
    let openAccountsStorage;
    if (localStorage.getItem(`OpenAccounts:`) === null) {
      openAccountsStorage = [];
    } else {
      openAccountsStorage = JSON.parse(localStorage.getItem(`OpenAccounts:`));
    }

    if (includes(accountId, openAccountsStorage)) {
      return true;
    }
    return false;
  };

  return (
    <div className={style.wrapper}>
      <h2 className={style.title}>Login as</h2>
      <ul className={style.accountList}>
        <li className={style.accountItem}>
          <NavLink className={style.accountLink} to={DASHBOARD_SHOPPER_HOME_PATH}>
            <i className="iconShop iconLarge" />
            <h3 className={style.accountItemTitle}>Enter Closet</h3>
          </NavLink>
        </li>
        {me.powerBiUrl && (
          <li className={style.accountItem}>
            <a className={style.accountLink} href={me.powerBiUrl} target="_blank">
              <i className="iconAnalytics iconLarge" />
              <h3 className={style.accountItemTitle}>Statistics</h3>
            </a>
          </li>
        )}

        {accountsCollection.data &&
          accountsCollection.data.map((account) => (
            <li
              key={account.id}
              className={cl(style.accountItem, isAddItemFavorites(account.id) && style.accountItemDisabled)}
            >
              <NavLink className={style.accountLink} to={getPath(DASHBOARD_ACCOUNT_PATH, { accountId: account.id })}>
                <i className="iconCloset iconLarge" />
                <h3 className={style.accountItemTitle}>{account.name}</h3>
                <h3 className={style.accountDomain}>{account.domain}</h3>
              </NavLink>
            </li>
          ))}
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    accountsCollection: getAccountsCollection(state),
    me: getSessionMe(state),
    isLoadingAccounts: requestInProcess(state, { requestType: requestTypes.ACCOUNTS_FETCH }),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchAccountsCollection: () => dispatch(actions.fetchAccountsCollection()),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchAccountsCollection } = this.props;
      fetchAccountsCollection();
    },
  }),
);

ChooseCloset.propTypes = propTypes;
ChooseCloset.defaultProps = defaultProps;

export default enhance(ChooseCloset);
