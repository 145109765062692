import active from "./active.svg";
import arrow from "./arrow.svg";
import pause from "./pause.png";
import logo from "./logo.svg";
import defaultAvatar from "./defaultAvatar.svg";
import star from "./star.png";
import luxlockLogo from "./luxlockLogo.svg";
import arrowWhite from "./arrowWhite.svg";

export { active, arrow, pause, logo, defaultAvatar, star, luxlockLogo, arrowWhite };
