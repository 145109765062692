import { objectToFormData } from "object-to-formdata";

import {
  DASHBOARD_ACCOUNTS_PATH,
  DASHBOARD_ACCOUNT_SHOPPERS_PATH,
  DASHBOARD_ACCOUNT_SHOPPER_PATH,
  INVITE_CLIENT_ACCOUNT_STAFF_PATH,
  BULK_UPLOAD_CLIENT_ACCOUNT_STAFF_PATH,
  MESSAGES_DASHBOARD_ACCOUNT_SHOPPER_CHAT_PATH,
  DASHBOARD_ACCOUNT_SHOPPER_CURRENT_EVENTS_PATH,
  DASHBOARD_ACCOUNT_APPEARANCE_PATH,
  DASHBOARD_ACCOUNT_INSTAGRAM_MEDIA_PATH,
  DASHBOARD_ACCOUNT_CONSULTANT_PATH,
  DASHBOARD_ACCOUNT_CONSULTANT_PAUSE_PATH,
  getPath,
} from "constants/routes";
import { decamelizeKeys } from "../../utils/humps";
import { getRequest, postRequest, patchRequest } from "../config/requests";

import fetch, { cfetchFormData } from "../config/fetch";

export default class AccountsApiAdapter {
  static getAccountsApiAdapter() {
    return fetch(DASHBOARD_ACCOUNTS_PATH, getRequest());
  }

  static getAccountShoppersApiAdapter(accountId) {
    return fetch(getPath(DASHBOARD_ACCOUNT_SHOPPERS_PATH, { accountId }), getRequest());
  }

  static getAccountShopperApiAdapter(accountId, id) {
    return fetch(getPath(DASHBOARD_ACCOUNT_SHOPPER_PATH, { accountId, id }), getRequest());
  }
  // WIP !!!!!!!!!!!

  static getAccountChatMessages(accountId, shopperId) {
    return fetch(getPath(MESSAGES_DASHBOARD_ACCOUNT_SHOPPER_CHAT_PATH, { accountId, id: shopperId }), getRequest());
  }

  static getAccountChatMessagesLoad(accountId, id, page) {
    return fetch(`/accounts/${accountId}/shoppers/${id}/messages?page=${page}`, getRequest());
  }

  static postAccountEditorProductsCollection(accountId, id, productIds) {
    const url = getPath(MESSAGES_DASHBOARD_ACCOUNT_SHOPPER_CHAT_PATH, { accountId, id });
    return fetch(
      url,
      postRequest({
        body: {
          message: {
            data: {
              product_ids: productIds,
            },
          },
        },
      }),
    );
  }

  static postInstagramLinkMessage(accountId, id, instagramLink) {
    const url = getPath(MESSAGES_DASHBOARD_ACCOUNT_SHOPPER_CHAT_PATH, { accountId, id });
    return fetch(
      url,
      postRequest({
        body: {
          message: {
            text: instagramLink,
          },
        },
      }),
    );
  }

  static postMessageInChat(accountId, id, message) {
    const url = getPath(MESSAGES_DASHBOARD_ACCOUNT_SHOPPER_CHAT_PATH, { accountId, id });
    return fetch(
      url,
      postRequest({
        body: {
          message: {
            text: message,
          },
        },
      }),
    );
  }

  static postNewConsultantBandWidth(accountId, newBandwidth) {
    const url = getPath(DASHBOARD_ACCOUNT_CONSULTANT_PATH, { accountId });
    return fetch(
      url,
      patchRequest({
        body: {
          consultant: {
            bandwidth: newBandwidth,
          },
        },
      }),
    );
  }

  static getShopperCurrentEvents(accountId, id) {
    return fetch(getPath(DASHBOARD_ACCOUNT_SHOPPER_CURRENT_EVENTS_PATH, { accountId, id }), getRequest());
  }

  static getAppearance(accountId) {
    return fetch(getPath(DASHBOARD_ACCOUNT_APPEARANCE_PATH, { accountId }), getRequest());
  }

  // static postAppearance(accountId) {
  //   return fetch(getPath(DASHBOARD_ACCOUNT_APPEARANCE_PATH, { accountId }), getRequest());
  // }

  static postAppearance = async (accountId, data) => {
    const formData = objectToFormData(decamelizeKeys({ account: data }));
    formData.delete("account[emblem]", data.emblem);
    formData.delete("account[logo]", data.logo);

    if (data.emblem && typeof data.emblem !== "string") {
      formData.append("account[emblem]", data.emblem);
    }
    if (data.emblem === null) {
      formData.append("account[emblem]", "");
    }
    if (data.logo && typeof data.logo !== "string") {
      formData.append("account[logo]", data.logo);
    }
    if (data.logo === null) {
      formData.append("account[logo]", "");
    }
    if (data.phone === undefined) {
      formData.append("account[phone]", "");
    }
    const response = await cfetchFormData(getPath(DASHBOARD_ACCOUNT_APPEARANCE_PATH, { accountId }), {
      method: "PATCH",
      body: formData,
    });

    return response;
  };

  static postInviteClient = async (accountId, staffId, body) => {
    const url = getPath(INVITE_CLIENT_ACCOUNT_STAFF_PATH, { accountId, staffId });
    return fetch(url, postRequest({ body }));
  };

  static postBulkUploadClients = async (accountId, staffId, data) => {
    const formData = objectToFormData(decamelizeKeys(data));
    formData.delete("file");

    if (data.file !== undefined) {
      formData.append("file", data.file);
    }

    const url = getPath(BULK_UPLOAD_CLIENT_ACCOUNT_STAFF_PATH, { accountId, staffId });

    const response = await cfetchFormData(url, {
      method: "POST",
      body: formData,
    });

    return response;
  };

  static getAccountInstagramFeed(accountId) {
    return fetch(getPath(DASHBOARD_ACCOUNT_INSTAGRAM_MEDIA_PATH, { accountId }), getRequest());
  }

  static getAccountConsultant(accountId) {
    return fetch(getPath(DASHBOARD_ACCOUNT_CONSULTANT_PATH, { accountId }), getRequest());
  }

  static postConsultantStatus(accountId, stylistStatus) {
    const url = getPath(DASHBOARD_ACCOUNT_CONSULTANT_PAUSE_PATH, { accountId });
    return fetch(
      url,
      patchRequest({
        body: {
          consultant: {
            paused: stylistStatus,
          },
        },
      }),
    );
  }
}
