import React from "react";
import PropTypes from "prop-types";

import SettingsHeader from "./SettingsHeader";
import SettingsTabs from "./SettingsTabs";

const propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
const defaultProps = {
  accountId: null,
};

function Settings(props) {
  const { match } = props;
  const { accountId } = match.params;

  return (
    <div className="contentWrapper">
      <SettingsHeader accountId={accountId} />
      <div className="container">
        <SettingsTabs accountId={accountId} />
      </div>
    </div>
  );
}

Settings.propTypes = propTypes;
Settings.defaultProps = defaultProps;

export default Settings;
