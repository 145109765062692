import React from "react";
import PropTypes from "prop-types";
import cl from "classnames";
import { propOr } from "ramda";

import { ActivityStream, Weather, Info, InstagramFeed } from "./shared";
import style from "../../profile.module.sass";
import { imgShop } from "../Image";

const propTypes = {
  accountId: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    jobTitle: PropTypes.string,
    maritalStatus: PropTypes.object,
    profile: PropTypes.object,
    deviceInfo: PropTypes.shape({
      browser: PropTypes.string,
      device: PropTypes.string,
      platform: PropTypes.string,
    }),
    sizingInfo: PropTypes.object,
  }),
  onSendInstagramMesage: PropTypes.func.isRequired,
};
const defaultProps = {
  accountId: "",
  user: {
    id: "",
    jobTitle: "",
    maritalStatus: {},
    profile: {},
    sizingInfo: {},
    deviceInfo: {},
  },
};
function Body({ user, onSendInstagramMesage, accountId }) {
  const { profile, deviceInfo, sizingInfo, jobTitle, maritalStatus, id } = user;
  return (
    <div className={style.body}>
      <div className="col-lg-5 col-md-12">
        <Info
          profile={profile}
          jobTitle={jobTitle}
          maritalStatus={maritalStatus}
          deviceInfo={deviceInfo}
          sizingInfo={sizingInfo}
        />
      </div>
      <div className="col-lg-7 col-md-12">
        <Weather accountId={accountId} id={id} />
        <InstagramFeed onSendInstagramMesage={onSendInstagramMesage} />
        <ActivityStream accountId={accountId} id={id} />
      </div>
    </div>
  );
}

Body.propTypes = propTypes;
Body.defaultProps = defaultProps;

export default Body;
