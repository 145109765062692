import { createSelector } from "reselect";

import { always, identity, propOr, find, propEq, includes } from "ramda";
import { entityDetailGetter, entityListGetter } from "utils/entities";
import { getEntities } from "../entities";

export const initialState = {
  data: {
    photos: [],
  },
  instagramFeed: {},
};

function shoppersSelector(state) {
  return state.shopperProfile || initialState;
}

export const getShopperProfileStore = createSelector(shoppersSelector, identity);

export const getShopperData = createSelector(getShopperProfileStore, (shopper) => shopper.data);

export const getShopperInstagramFeed = createSelector(getShopperProfileStore, (shopper) => shopper.instagramFeed);
