import React from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import { DASHBOARD_ACCOUNT_SETTINGS_SETUP_PATH, getPath } from "../../../constants/routes";

import setting from "../setting.module.sass";

const propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
const defaultProps = {
  accountId: "3",
};

function SettingsHeader({ accountId }) {
  return (
    <div className={setting.header}>
      <NavLink
        className={setting.navLink}
        activeClassName="navLinkActive"
        to={getPath(DASHBOARD_ACCOUNT_SETTINGS_SETUP_PATH, { accountId })}
      >
        <i className="iconNav iconNavSetting iconNavYellow" />
        <span>Setup</span>
      </NavLink>
    </div>
  );
}

SettingsHeader.propTypes = propTypes;
SettingsHeader.defaultProps = defaultProps;

export default SettingsHeader;
