import { throwSubmissionError } from "utils/form";
import { history } from "stores/configureStore";
import { pushToast } from "components";
import { ShopperApiAdapter } from "../../apiAdapters";

import * as requestTypes from "../../constants/requestTypes";
import * as types from "../../constants/actionTypes";

import setRequestInProcess from "../request";

export function fetchInstagramConnect(queryString, redirectUrl) {
  const requestType = requestTypes.SHOPPER_PROFILE_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    ShopperApiAdapter.instagramConnect(queryString).then(() => {
      history.push(redirectUrl);
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchInstagramFeed(id) {
  const requestType = requestTypes.SHOPPER_INSTAGRAM_FETCH;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    ShopperApiAdapter.getInstagramFeed(id).then((res) => {
      dispatch(fetchInstagramFeedSuccess(res));
      dispatch(setRequestInProcess(false, requestType));
    });
    // .catch((error) => {
    //   if (error.response) {
    //     openNotification('error', error.response.data.message);
    //   }
    // });
  };
}

export function fetchShopperProfile() {
  const requestType = requestTypes.SHOPPER_PROFILE_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    ShopperApiAdapter.getShopperProfile().then((res) => {
      dispatch(fetchShopperProfileSucceses(res.data));
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchShopperPhotos() {
  const requestType = requestTypes.SHOPPER_PROFILE_PHOTOS_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    ShopperApiAdapter.getShopperPhotos().then((res) => {
      dispatch(fetchShopperPhotosSucceses(res.data));
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function submitPhoto(photo) {
  const requestType = requestTypes.SHOPPER_PROFILE_PHOTOS_FETCH;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    ShopperApiAdapter.postPhoto(photo).then((res) => {
      dispatch(fetchShopperPhotosUpdateSucceses(res));
      dispatch(setRequestInProcess(false, requestType));
      pushToast({
        text: "Updated",
      });
    });
  };
}

export function removeImage(id) {
  return (dispatch) => {
    ShopperApiAdapter.deletePhoto(id).then((res) => {
      dispatch(fetchShopperPhotosRemoveSucceses(id));
      pushToast({
        text: "Updated",
      });
    });
  };
}

export const postProfileData = (body) => async (dispatch) => {
  try {
    const { data } = await ShopperApiAdapter.postProfile(body);
    pushToast({
      text: "Updated",
    });
    dispatch(fetchShopperProfileSucceses(data));
  } catch (error) {
    if (error) {
      return throwSubmissionError(error);
    }
    return throwSubmissionError({ errors: { message: "Something went wrong" } });
  } finally {
  }
};

export const updatePassword = (body) => async (dispatch) => {
  try {
    const { data } = await ShopperApiAdapter.updatePassword(body);
    pushToast({
      text: "Updated",
    });
  } catch (error) {
    // pushToast({
    //   type: "error",
    //   text: "Error",
    // });
    if (error) {
      return throwSubmissionError(error);
    }
    return throwSubmissionError({ errors: { message: "Something went wrong" } });
  } finally {
    // dispatch(setRequestInProcess(false, requestType));
  }
};

export function fetchInstagramFeedSuccess(payload) {
  return {
    type: types.SHOPPER_INSTAGRAM_FETCH_SUCCESS,
    payload,
  };
}

export function fetchShopperProfileSucceses(payload) {
  return {
    type: types.SHOPPER_PROFILE_FETCH_SUCCESS,
    payload,
  };
}

export function fetchShopperPhotosSucceses(payload) {
  return {
    type: types.SHOPPER_PROFILE_PHOTOS_FETCH_SUCCESS,
    payload,
  };
}

export function fetchShopperPhotosRemoveSucceses(payload) {
  return {
    type: types.SHOPPER_PROFILE_REMOVE_PHOTOS_FETCH_SUCCESS,
    payload,
  };
}

export function fetchShopperPhotosUpdateSucceses(payload) {
  return {
    type: types.SHOPPER_PROFILE_UPDATE_PHOTOS_FETCH_SUCCESS,
    payload,
  };
}
