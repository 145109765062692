import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cl from "classnames";
import * as actions from "actions";

import PhotoItem from "./shared/PhotoItem";
import styles from "./index.module.sass";

const propTypes = {
  shopper: PropTypes.shape({
    photos: PropTypes.array,
  }),
};

const defaultProps = {
  shopper: {
    photos: [],
  },
};

function Photos({ fetchShopperPhotos, shopper }) {
  return (
    <div className={styles.photoWrapper}>
      <PhotoItem shopper={shopper} />
      {/* <PhotoItem item={shopper && shopper.photos && shopper.photos[1] && shopper.photos[1]} />
      <PhotoItem item={shopper && shopper.photos && shopper.photos[2] && shopper.photos[2]} /> */}
    </div>
  );
}

Photos.defaultProps = defaultProps;
Photos.propTypes = propTypes;

export default Photos;
