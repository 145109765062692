import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Button from "components/shared/formDefault/Button";
import styles from "./index.module.sass";

const propTypes = {
  submitting: PropTypes.bool,
};

const defaultProps = {
  submitting: false,
};

function StaffRole({
  submitting,
  match: {
    params: { staffId },
  },
}) {
  return (
    <div className={styles.btnGroup}>
      {staffId !== "setup" && (
        <Button type="reset" onClick={() => console.log("on CLick cancel")}>
          deactivate
        </Button>
      )}

      <Button type="submit" disable={submitting}>
        {submitting ? "saving..." : "save"}
      </Button>
    </div>
  );
}

StaffRole.defaultProps = defaultProps;
StaffRole.propTypes = propTypes;

export default withRouter(StaffRole);
