import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  baseClass: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
};

const defaultProps = {
  baseClass: undefined,
  className: undefined,
  type: "text",
};

function InputPassword({ type, onChange, name, className, baseClass, ...props }) {
  return <input name={name} onChange={onChange} type={type} {...props} className={className} />;
}

InputPassword.propTypes = propTypes;
InputPassword.defaultProps = defaultProps;

export default InputPassword;
