import React, { useState } from "react";
import PropTypes from "prop-types";
import cl from "classnames";

import fetch from "apiAdapters/config/fetch";
import InfoBlock from "../../InfoBlock";
import {
  SHOPPER_PROFFILE_DOWNLOAD_DATA_EMAIL,
  SHOPPER_PROFFILE_DELETE_ME_EMAIL,
  SHOPPER_PROFFILE_DEACTIVATE_LUXLOCK_EMAIL,
  SHOPPER_PROFFILE_PORTABILITY_EMAIL,
} from "constants/routes";
import styles from "./index.module.sass";

const propTypes = {};

const defaultProps = {};

function DataCompliance({ shopper }) {
  const [isDownload, setIsDownLoad] = useState(false);
  const [isDeactivate, setIsDeactivate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const handleDownload = (url) => {
    handleSubmitMessage(url);
    setIsDownLoad(true);
  };

  const handleDeactivate = (url) => {
    handleSubmitMessage(url);
    setIsDeactivate(true);
  };

  const handleDelete = (url) => {
    handleSubmitMessage(url);
    setIsDelete(true);
  };

  const handleSubmitMessage = async (url) => {
    const response = await fetch(url, {
      method: "POST",
    });
    return response;
  };

  return (
    <div className={styles.btnGroup}>
      <div className={styles.buttonWrapper}>
        <div
          className={cl(styles.btn, styles.btnPortability)}
          onClick={() => handleSubmitMessage(SHOPPER_PROFFILE_PORTABILITY_EMAIL)}
        >
          Portability
        </div>
        <InfoBlock title="It’s your data, take it with you and unlock your preferences whenever you want with Luxlock. Personalized service, not ads. We hate ads!" />
      </div>
      <div className={styles.buttonWrapper}>
        <div
          className={cl(styles.btn, (shopper.deactivateRequestedAt || isDeactivate) && styles.btnActive)}
          onClick={() =>
            window.confirm("Are you sure you want to deactivate Luxlock?") &&
            handleDeactivate(SHOPPER_PROFFILE_DEACTIVATE_LUXLOCK_EMAIL)
          }
        >
          Deactivate Luxlock
        </div>
        <InfoBlock title="If you want to take a break from Luxlock, you can deactivate your account, and we’ll archive it just in case you change your mind”" />
      </div>
      <div className={styles.buttonWrapper}>
        <div
          className={cl(styles.btn, (shopper.downloadRequestedAt || isDownload) && styles.btnActive)}
          onClick={() =>
            window.confirm("Your download data request has been received and will be processed within 30 days.") &&
            handleDownload(SHOPPER_PROFFILE_DOWNLOAD_DATA_EMAIL)
          }
        >
          Download Data
        </div>
        <InfoBlock title="Download an archive of your data, it all belongs to you. Just be careful with it; it's personal. Your data will be emailed to you as CSV." />
      </div>
      <div className={styles.buttonWrapper}>
        <div
          className={cl(styles.btn, (shopper.deleteRequestedAt || isDelete) && styles.btnActive)}
          onClick={() =>
            window.confirm(
              "Are you sure you want to delete your account? This will permanently erase your profile, orders and messages.",
            ) && handleDelete(SHOPPER_PROFFILE_DELETE_ME_EMAIL)
          }
        >
          Delete me forever
        </div>
        <InfoBlock title="When you delete your Luxlock account, you won't be able to retrieve the content or information you've shared through Luxlock Your live chat accessibility and all of your message history will also be deleted. You’d have to start from scratch." />
      </div>
    </div>
  );
}

DataCompliance.defaultProps = defaultProps;
DataCompliance.propTypes = propTypes;

export default DataCompliance;
