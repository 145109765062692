import {
  FETCH_ME_SUCCESS,
  FETCH_MY_PROFILE_SUCCESS,
  ACCOUNTS_USER_SUCCESS,
  ACCOUNTS_USER_SUCCESS_BANDWIDTH,
  ACCOUNTS_REMOVE_STATE_SUCCESS,
} from "constants/actionTypes";
import { initialState } from "../../selectors/session";

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_ME_SUCCESS:
      return { ...state, me: payload };

    case FETCH_MY_PROFILE_SUCCESS:
      return { ...state, user: payload };

    case ACCOUNTS_USER_SUCCESS_BANDWIDTH:
      return {
        ...state,
        user: {
          ...payload.data,
          bandwidth: payload.data.bandwidth,
        },
      };

    case ACCOUNTS_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...payload.data,
        },
      };

    case ACCOUNTS_REMOVE_STATE_SUCCESS:
      return {};
    // return { user: { bandwidth: payload } };

    default:
      return state;
  }
}
