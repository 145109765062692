import { toast } from "react-toastify";

const toastContainerOptions = {
  closeButton: false,
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: true,
  autoClose: 2500,
};

export default toastContainerOptions;
