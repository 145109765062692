import React from "react";
import { withRouter } from "react-router-dom";

import Header from "./Header";
import styles from "./index.module.sass";
import Row from "./Row";

function StaffTable({
  staffsIds,
  match: {
    params: { accountId },
  },
}) {
  return (
    <table className={styles.tableContainer}>
      <Header />
      <tbody className={styles.tableBody}>
        {staffsIds.map((staffsId) => (
          <Row key={staffsId} staffId={staffsId} accountId={accountId} />
        ))}
      </tbody>
    </table>
  );
}

export default withRouter(StaffTable);
