import React from "react";
import { NavLink, withRouter } from "react-router-dom";

import { getPath, DASHBOARD_ACCOUNT_STAFF_PATH } from "constants/routes";

import Avatar from "./shared/Avatar";
import InfoName from "./shared/InfoName";
import InfoEmail from "./shared/InfoEmail";

import styles from "./index.module.sass";

function AccountInfo({
  firstName,
  lastName,
  email,
  staffId,
  avatar,
  match: {
    params: { accountId },
  },
  ...props
}) {
  return (
    <NavLink to={getPath(DASHBOARD_ACCOUNT_STAFF_PATH, { accountId, staffId })} className={styles.accountInfoWrapper}>
      <Avatar avatar={avatar} />
      <div className={styles.accountInfoАactsWrapper}>
        <InfoName>{`${firstName} ${lastName}`}</InfoName>

        <InfoEmail>{email}</InfoEmail>
      </div>
    </NavLink>
  );
}

export default withRouter(AccountInfo);
