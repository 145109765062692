import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { getIn } from "utils/ramda";
import { connect } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import { pipe, map, includes, prop, reverse } from "ramda";
import { getShopperDetail } from "selectors/shoppers";
import moment from "moment";
import { formatChatDate } from "utils/format";
import * as actions from "actions";
import { requestInProcess } from "selectors/request";
import * as requestTypes from "constants/requestTypes";

import ShopperMessage from "./shared/ShopperMessage";
import StylistMessage from "./shared/StylistMessage";
import { paperPlane, loading } from "../shared/Image";

import chat from "../../chat.module.sass";

const propTypes = {
  messages: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  currentUser: PropTypes.shape({
    contactId: PropTypes.string,
  }),
  shopper: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

const defaultProps = {
  messages: {
    data: [],
  },
  currentUser: {
    contactId: null,
  },
  shopper: {
    firstName: "",
    lastName: "",
  },
};

function Messenger(props) {
  const {
    match,
    messages,
    loadingMessages,
    onSubmit,
    addMessageToChat,
    currentUser,
    onTyping,
    connection,
    isTyping,
    shopperIsTyping,
    shopper,
    fetchAccountChatMessagesLoad,
    isNewShopperId,
    setIsNewShopperId,
    typingState,
    countMessages,
    removeCountMessage,
  } = props;

  const { accountId, id } = match.params;
  const { meta, data } = messages;

  const [isMessage, setIsMessage] = useState("");
  const messagesBottomRef = useRef();
  const messagesTopRef = useRef();

  const reverseMessages = data ? reverse(data) : [];
  const nextPage = meta ? meta.nextPage : null;
  const isLastPage = meta ? meta.isLastPage : true;

  const [isLoading, setIsLoading] = useState(false);
  const node = useRef();

  // const [isScroll, setIsScroll] = useState(0);
  const scrollToBottom = () => {
    messagesBottomRef.current.scrollIntoView({ behavior: "auto" });
    // setIsScroll(isScroll + 1);
    // !!!!!!!!!!!!HOT FIX, WIP !!!!
  };

  function sendMessage(event) {
    event.preventDefault();
    if (isMessage) {
      onSubmit(accountId, id, isMessage);
      clearTimeout(typingTimer);
      const typingTimer = setTimeout(() => {
        scrollToBottom();
      }, 300);
      setIsMessage("");
    }
  }

  const handleLoadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      fetchAccountChatMessagesLoad(accountId, id);
      if (!isLastPage) {
        messagesTopRef.current.scrollIntoView({ behavior: "auto" });
      }
    }, 100);
  };

  useEffect(() => {
    scrollToBottom();
    const scrollHandler = (event) => {
      if (event.target.scrollTop === 0 && nextPage) {
        handleLoadMore();
      }
    };
    node.current.addEventListener("scroll", scrollHandler);
    return () => {
      node.current.removeEventListener("scroll", scrollHandler);
    };
  }, [messages]);

  const isIncludes = pipe(map(prop("id")), includes(id))(countMessages);
  const hanleRemoveMessageCount = () => {
    // setNewMessageCount(newMessageCount.filter((item) => item.id !== id));
    removeCountMessage(countMessages.filter((item) => item.id !== id));
  };

  return (
    <>
      <div
        onClick={() => {
          if (isNewShopperId === id) return setIsNewShopperId("");
          if (isIncludes) return hanleRemoveMessageCount();
        }}
        onFocus={() => {
          if (isIncludes) return hanleRemoveMessageCount();
        }}
        className={chat.messenger}
      >
        {/* it is necessary to normalize messages for the state to work correctly */}
        <div className={chat.messengerWrap}>
          {isLoading ? <p className={chat.loadingMessage}> loading More...</p> : ""}
          <ul style={{ overflowX: "scroll" }} className={chat.messages} id="messages-list" ref={node}>
            {loadingMessages && (
              <div className={chat.loadingWrapper}>
                {/* <img className={chat.loading} src={loading} alt="loading" /> */}
                <div className="loading" />
              </div>
            )}
            <div style={{ position: "absolute", top: "50%" }} ref={messagesTopRef} />
            {reverseMessages.map((message, index) => (
              <>
                {index === 0 && !nextPage && (
                  <div>
                    <span className={chat.nextHistory}>This is the beginning of your chat history</span>
                    <div className={chat.date}>{formatChatDate(message.createdAt)}</div>
                  </div>
                )}
                {index === 0 ||
                  (index > 0 &&
                    moment(message.createdAt).format("DD MMM") !==
                      moment(reverseMessages[index - 1].createdAt).format("DD MMM") && (
                      <div className={chat.date}>{formatChatDate(message.createdAt)}</div>
                    ))}
                <li>
                  {message.contact.id === id && (
                    <ShopperMessage
                      key={message.id}
                      message={message}
                      avatar={message.contact.avatar}
                      firstName={message.contact.firstName}
                      lastName={message.contact.lastName}
                      isGuest={message.contact.isGuest}
                    />
                  )}
                  {message.consultant === true && (
                    <StylistMessage
                      key={message.id}
                      message={message}
                      avatar={message.contact.avatar}
                      firstName={message.contact.firstName}
                      lastName={message.contact.lastName}
                    />
                  )}
                </li>
              </>
            ))}
            <div className={chat.shadow}>
              {typingState.map((item) => (
                <>{item.contactId === id && item.typing && <span>Typing a message...</span>}</>
              ))}
            </div>
            <div ref={messagesBottomRef} />
          </ul>
        </div>
        <form onSubmit={(e) => sendMessage(e)} className={chat.inputGroup}>
          <TextareaAutosize
            minRows={2}
            maxRows={8}
            id="chatInput"
            className={chat.textArea}
            type="text"
            onChange={(e) => setIsMessage(e.target.value)}
            onKeyUp={() => onTyping(id)}
            v-model="message"
            value={isMessage}
            placeholder="Type a message"
            autoComplete="off"
            data-name="entryField"
          />
          <button type="submit" value="Отправить" className={chat.btn}>
            <img src={paperPlane} alt="icon" />
          </button>
        </form>
      </div>
    </>
  );
}

function mapStateToProps(state, props) {
  const id = getIn("match.params.id", props);
  return {
    shopper: getShopperDetail(state, id),
    loadingMessages: requestInProcess(state, { requestType: requestTypes.ACCOUNT_CHAT_MESSAGES_FETCH }),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchAccountChatMessagesLoad: (accountId, id, nextPage) =>
    dispatch(actions.fetchAccountChatMessagesLoad(accountId, id, nextPage)),
  removeCountMessage: (ids) => dispatch(actions.removeCountMessage(ids)),
});

Messenger.propTypes = propTypes;
Messenger.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Messenger);
