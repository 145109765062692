import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./index.module.sass";

const defaultProps = {
  type: "submit",
};

const propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function Button({ type, children, disable, auth, ...props }) {
  const isSubmit = type === "submit";

  return (
    <button
      className={classnames(styles.button, auth && styles.buttonDark, !isSubmit && styles.outline)}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
export default Button;
