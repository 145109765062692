import { REQUEST_IN_PROCESS_SET } from "constants/actionTypes";

const initialState = {};

function setRequestInProcess(state, payload) {
  const { inProcess, requestType, error } = payload;
  const requestObject = {};
  requestObject[requestType] = {
    inProcess,
    error,
  };
  return Object.assign({}, state, requestObject);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_IN_PROCESS_SET:
      return setRequestInProcess(state, action.payload);
    default:
      return state;
  }
};
