import React from "react";
import PropTypes from "prop-types";
import { defaultAvatar } from "../Image";

import header from "../../header.module.sass";

const propTypes = {
  isStylist: PropTypes.bool,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    contact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatar: PropTypes.string,
    }),
  }),
  currentAccount: PropTypes.shape({
    domain: PropTypes.string,
  }),
};
const defaultProps = {
  isStylist: false,
  user: {
    contact: {
      firstName: "",
      lastName: "",
      avatar: "",
    },
  },
  currentAccount: {},
};

function UserInfo(props) {
  const { currentAccount, user, isStylist } = props;
  const { contact = {} } = user;

  return (
    <div className={header.userInfo}>
      {isStylist && <img className={header.img} src={contact.avatar || defaultAvatar} alt="avatar" />}
      <div className={header.userName}>
        {isStylist && (
          <div>
            <span>
              {contact.firstName} 
{' '}
{contact.lastName}
            </span>
            <span className={header.domain}>{currentAccount.domain}</span>
          </div>
        )}
        {/* {isStylist && (
          <div className="flex">
            {[...Array(5)].map((x, i) => (
              <span key={i} className={header.IconStar}>
                <img src={star} alt="" />
              </span>
            ))}
          </div>
        )} */}
      </div>
    </div>
  );
}

UserInfo.propTypes = propTypes;
UserInfo.defaultProps = defaultProps;

export default UserInfo;
