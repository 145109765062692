import React from "react";
import PropTypes from "prop-types";
import cl from "classnames";

import analytics from "./analytics.module.sass";
import { pieChart } from "./Image";

const propTypes = {};
const defaultProps = {};

function Analytics(props) {
  return (
    <div className="contentWrapper">
      <div className="container">
        <div className={cl(`col-lg-12 ${analytics.countBox}`)}>
          <ul className={analytics.countBoxlist}>
            {[...Array(9)].map((x, i) => (
              <li key={i} className={analytics.countBoxItem}>
                <span className={analytics.attributeCount}>787</span>
                <span className={analytics.attributeName}>Clients</span>
              </li>
            ))}
          </ul>
        </div>
        <div className={analytics.periodBox}>
          <div className={cl(analytics.blockWrapper, analytics.periodBoxLeft)}>
            <h3 className={analytics.periodBoxTitle}>Period</h3>
            <h4>QTD</h4>
            <a href="#" className={analytics.btn}>
              set period
            </a>
          </div>
          <div className={cl(analytics.blockWrapper, analytics.periodBoxRight)}>
            <ul className={analytics.periodBoxList}>
              {[...Array(4)].map((x, i) => (
                <li key={i} className={analytics.periodBoxItem}>
                  <h3 className={analytics.periodBoxTitle}>Period</h3>
                  <div className={analytics.periodRange}>
                    <span>$900</span>
                    <span>30%</span>
                  </div>
                  <span>45 units / $54</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={analytics.staticBoxs}>
          <div className={cl(analytics.blockWrapper, analytics.staticBox)}>
            <h3 className={analytics.periodBoxTitle}>Period</h3>
            <img src={pieChart} alt="pieChart" />
          </div>
          <div className={cl(analytics.blockWrapper, analytics.staticBox)}>
            <h3 className={analytics.periodBoxTitle}>Period</h3>
            <img src={pieChart} alt="pieChart" />
          </div>
          <div className={cl(analytics.blockWrapper, analytics.staticBox)}>
            <h3 className={analytics.periodBoxTitle}>Period</h3>
            <img src={pieChart} alt="pieChart" />
          </div>
        </div>
      </div>
    </div>
  );
}

Analytics.propTypes = propTypes;
Analytics.defaultProps = defaultProps;

export default Analytics;
