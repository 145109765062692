import * as actionTypes from "constants/actionTypes";
import { initialState } from "selectors/staffs";

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.STAFF_LIST_FETCH_SUCCESS:
      return {
        ...state,
        ids: payload,
      };
    case actionTypes.ACCOUNTS_REMOVE_STATE_SUCCESS:
      return {};

    // case actionTypes.STAFF_FETCH_SUCCESS:
    //   return {
    //     ...state,

    //   };

    default:
      return state;
  }
};
