import moment from "moment";
import { string } from "prop-types";

import { objectToFormData } from "object-to-formdata";
import {
  DASHBOARD_SHOPPER_PROFILE_PATH,
  AUTH_INSTAGRAM_GRAPH_PATH,
  DASHBOARD_SHOPPER_INSTAGRAM_FEED_PATH,
  DASHBOARD_SHOPPER_PHOTOS_PATH,
  DASHBOARD_SHOPPER_DELETE_PHOTO_PATH,
  DASHBOARD_SHOPPER_PROFILE_PASSWORD_PATH,
  getPath,
} from "../../constants/routes";

import { decamelizeKeys } from "../../utils/humps";

import { getRequest, deleteRequest, patchRequest, postRequest } from "../config/requests";

import fetch, { cfetchFormData, urlFetch } from "../config/fetch";

export default class ShopperApiAdapter {
  static instagramConnect(queryString) {
    return urlFetch(`/auth/instagram_graph/callback${queryString}`, getRequest());
  }

  static getInstagramFeed(id) {
    return fetch(getPath(DASHBOARD_SHOPPER_INSTAGRAM_FEED_PATH, { id }), getRequest());
  }

  static getShopperProfile() {
    return fetch(DASHBOARD_SHOPPER_PROFILE_PATH, getRequest());
  }

  static getShopperPhotos() {
    return fetch(DASHBOARD_SHOPPER_PHOTOS_PATH, getRequest());
  }

  static postPhoto = async (photo) => {
    const formData = new FormData();
    formData.append("[photo][image]", photo);

    const response = await cfetchFormData(DASHBOARD_SHOPPER_PHOTOS_PATH, {
      method: "POST",
      body: formData,
    });
    return response;
  };

  static deletePhoto(id) {
    return fetch(getPath(DASHBOARD_SHOPPER_DELETE_PHOTO_PATH, { id }), deleteRequest());
  }

  static postProfile = async (data) => {
    const formData = objectToFormData(
      decamelizeKeys({
        shopper_profile: { ...data, sizingInfo: { ...data.sizingInfo, children: [] } },
      }),
    );
    const inch =
      data.sizingInfo && data.sizingInfo.height
        ? parseInt(data.sizingInfo.height.foot, 10) * 12 + parseInt(data.sizingInfo.height.inch, 10)
        : "";

    // FOR TEST!!
    formData.delete("shopper_profile[avatar]");
    formData.delete("shopper_profile[profile][dress_code][label]");
    formData.delete("shopper_profile[profile][dress_code][value]");
    formData.delete("shopper_profile[profile][wear_preference][label]");
    formData.delete("shopper_profile[profile][wear_preference][value]");
    formData.delete("shopper_profile[profile][mobile_device_color][label]");
    formData.delete("shopper_profile[profile][mobile_device_color][value]");
    formData.delete("shopper_profile[sizing_info][height][foot]");
    formData.delete("shopper_profile[sizing_info][height][inch]");
    formData.delete("shopper_profile[sizing_info][pets][][updated_at]");
    formData.delete("shopper_profile[photos]");
    formData.delete("shopper_profile[sizing_info][children][][updated_at]");
    formData.delete("shopper_profile[sizing_info][hair_color][label]");
    formData.delete("shopper_profile[sizing_info][hair_color][value]");
    formData.delete("shopper_profile[sizing_info][fit_preference][label]");
    formData.delete("shopper_profile[sizing_info][fit_preference][value]");
    formData.delete("shopper_profile[sizing_info][body_shape][label]");
    formData.delete("shopper_profile[sizing_info][body_shape][value]");
    formData.delete("shopper_profile[sizing_info][body_length][label]");
    formData.delete("shopper_profile[sizing_info][body_length][value]");
    formData.delete("shopper_profile[sizing_info][body_waist][label]");
    formData.delete("shopper_profile[sizing_info][body_waist][value]");

    formData.append(
      "shopper_profile[profile][dress_code]",
      data.profile && data.profile.dressCode && data.profile.dressCode.value && data.profile.dressCode.value
        ? data.profile.dressCode.value
        : "",
    );
    formData.append(
      "shopper_profile[profile][wear_preference]",
      data.profile &&
        data.profile.wearPreference &&
        data.profile.wearPreference.value &&
        data.profile.wearPreference.value
        ? data.profile.wearPreference.value
        : "",
    );
    formData.append(
      "shopper_profile[profile][mobile_device_color]",
      data.profile &&
        data.profile.mobileDeviceColor &&
        data.profile.mobileDeviceColor.value &&
        data.profile.mobileDeviceColor.value
        ? data.profile.mobileDeviceColor.value
        : "",
    );
    formData.append(
      "shopper_profile[sizing_info][hair_color]",
      data.sizingInfo && data.sizingInfo.hairColor && data.sizingInfo.hairColor.value && data.sizingInfo.hairColor.value
        ? data.sizingInfo.hairColor.value
        : "",
    );
    formData.append(
      "shopper_profile[sizing_info][fit_preference]",
      data.sizingInfo &&
        data.sizingInfo.fitPreference &&
        data.sizingInfo.fitPreference.value &&
        data.sizingInfo.fitPreference.value
        ? data.sizingInfo.fitPreference.value
        : "",
    );
    formData.append(
      "shopper_profile[sizing_info][body_shape]",
      data.sizingInfo && data.sizingInfo.bodyShape && data.sizingInfo.bodyShape.value && data.sizingInfo.bodyShape.value
        ? data.sizingInfo.bodyShape.value
        : "",
    );
    formData.append(
      "shopper_profile[sizing_info][body_length]",
      data.sizingInfo &&
        data.sizingInfo.bodyLength &&
        data.sizingInfo.bodyLength.value &&
        data.sizingInfo.bodyLength.value
        ? data.sizingInfo.bodyLength.value
        : "",
    );
    formData.append(
      "shopper_profile[sizing_info][body_waist]",
      data.sizingInfo && data.sizingInfo.bodyWaist && data.sizingInfo.bodyWaist.value && data.sizingInfo.bodyWaist.value
        ? data.sizingInfo.bodyWaist.value
        : "",
    );
    // // ____________________________-

    formData.append("shopper_profile[sizing_info][height]", inch);

    if (data.anniversaryDate) {
      formData.append("shopper_profile[anniversary_date]", moment(data.anniversaryDate).format());
    }
    if (data.dateOfBirth) {
      formData.append("shopper_profile[date_of_birth]", moment(data.dateOfBirth).format());
    }
    if (data.avatar && typeof data.avatar !== "string") {
      formData.append("shopper_profile[avatar]", data.avatar);
    }
    if (data.avatar === null) {
      formData.append("shopper_profile[avatar]", "");
    }
    if (data.sizingInfo && data.sizingInfo.children && data.sizingInfo.children.length < 1) {
      formData.append("shopper_profile[sizing_info][children]", []);
    }
    if (data.sizingInfo && data.sizingInfo.pets && data.sizingInfo.pets.length < 1) {
      formData.append("shopper_profile[sizing_info][pets]", []);
    }

    data.sizingInfo &&
      data.sizingInfo.children &&
      data.sizingInfo.children.forEach((val) => {
        formData.append("shopper_profile[sizing_info][children][][name]", val.name ? val.name : "");
        formData.append("shopper_profile[sizing_info][children][][preference]", val.preference ? val.preference : "");
        formData.append("shopper_profile[sizing_info][children][][size]", val.size ? val.size : "");
        formData.append(
          "shopper_profile[sizing_info][children][][birthday]",
          val.birthday ? moment(val.birthday).format() : "",
        );
      });

    data.photos &&
      data.photos.forEach((val) => {
        // if (val.destroy) {
        //   formData.append("shopper_profile[photo][image]", "");
        // }
        if (typeof val.image !== "string") {
          formData.append("shopper_profile[photo][image]", val.image);
        }
      });

    if (data.profile && data.profile.languages && data.profile.languages.length < 1) {
      formData.append("shopper_profile[profile][languages]", "");
    }

    if (data.profile && data.profile.hobbies && data.profile.hobbies.length < 1) {
      formData.append("shopper_profile[profile][hobbies]", "");
    }

    const response = await cfetchFormData(DASHBOARD_SHOPPER_PROFILE_PATH, {
      method: "PATCH",
      body: formData,
    });
    return response;
  };

  static updatePassword(data) {
    const url = DASHBOARD_SHOPPER_PROFILE_PASSWORD_PATH;
    return fetch(
      url,
      patchRequest({
        body: {
          profile: data,
        },
      }),
    );
  }
}
