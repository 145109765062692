import React from "react";
import PropTypes from "prop-types";

// import styles from "./index.module.sass";

const propTypes = {
  isActive: PropTypes.bool.isRequired,
};

const defaultProps = {};

function ActiveStatus({ isActive }) {
  if (isActive) {
    return <i className="iconActive" />;
  }

  return null;
}

ActiveStatus.defaultProps = defaultProps;
ActiveStatus.propTypes = propTypes;

export default ActiveStatus;
