import * as React from "react";
import ReactSelect from "components/shared/formBase/MultiReactSelect/SimpleValueAdapter";

import "../ReactSelect/rsStyles.sass";

function ClassicSelect({ isError, ...props }) {
  return <ReactSelect multi {...props} className={isError && "isError"} />;
}

export default ClassicSelect;
