import {
  DASHBOARD_ACCOUNT_PRODUCTS_PATH,
  DASHBOARD_ACCOUNT_SHOPPER_PRODUCTS_WISHLIST_PATH,
  DASHBOARD_ACCOUNT_PRODUCT_PATH,
  DASHBOARD_ACCOUNT_PRODUCT_RECOMMENDED_PATH,
  DASHBOARD_ACCOUNT_PRODUCT_FAVORITE_PATH,
  getPath,
} from "constants/routes";
import { getIn } from "utils/ramda";
import { deleteRequest, getRequest, postRequest, putRequest } from "../config/requests";

import fetch from "../config/fetch";

export default class ProductsApiAdapter {
  // WIP!!!!!!!!!!!!
  static getProductsCollectionApiAdapter(accountId, id, params = {}) {
    const q = params.q || "";
    const filter = params.filter || "";
    return fetch(`/accounts/${accountId}/products?s[q]=${q}&s[order]=${filter}`, getRequest());

    // return fetch(getPath(DASHBOARD_ACCOUNT_PRODUCTS_PATH, { accountId }), getRequest());
  }
  static getShopProductsLoadCollectionApiAdapter(accountId, currentPage, params) {
    const q = params.q || "";
    const filter = params.filter || "";
    return fetch(`/accounts/${accountId}/products?page=${currentPage}&s[q]=${q}&s[order]=${filter}`, getRequest());
  }

  // static getShopProductsCollectionApiAdapter(accountId, id, params = {}) {
  //   const q = params.q || "";
  //   const filter = params.filter || "";
  //   return fetch(`/accounts/${accountId}/products?s[q]=${q}&s[order]=${filter}`, getRequest());

  //   // return fetch(getPath(DASHBOARD_ACCOUNT_PRODUCTS_PATH, { accountId }), getRequest());
  // }

  // static getShopProductsLoadCollectionApiAdapter(accountId, currentPage, params) {
  //   const q = params.q || "";
  //   const filter = params.filter || "";
  //   return fetch(`/accounts/${accountId}/products?page=${currentPage}&s[q]=${q}&s[order]=${filter}`, getRequest());
  // }

  static getProductApiAdapter(accountId, productId) {
    return fetch(getPath(DASHBOARD_ACCOUNT_PRODUCT_PATH, { accountId, id: productId }), getRequest());
  }

  static getStyleProductsCollectionApiAdapter(accountId, id, params, name) {
    const q = params.q || "";
    const filter = params.filter || "";
    return fetch(`/accounts/${accountId}/shoppers/${id}/products/${name}?s[q]=${q}&s[order]=${filter}`, getRequest());
    // return fetch(getPath(DASHBOARD_ACCOUNT_SHOPPER_PRODUCTS_WISHLIST_PATH, { accountId, id }), getRequest());
  }

  static getStyleProductsLoadCollectionApiAdapter(accountId, id, name, currentPage, params) {
    const q = params.q || "";
    const filter = params.filter || "";
    return fetch(
      `/accounts/${accountId}/shoppers/${id}/products/${name}?page=${currentPage}&s[q]=${q}&s[order]=${filter}`,
      getRequest(),
    );
  }

  static getProductsCollectionRecommended(accountId) {
    return fetch(getPath(DASHBOARD_ACCOUNT_PRODUCT_RECOMMENDED_PATH, { accountId }), getRequest());
  }

  static postProductToFavorites(accountId, id) {
    const url = getPath(DASHBOARD_ACCOUNT_PRODUCT_FAVORITE_PATH, { accountId, id });
    return fetch(
      url,
      postRequest({
        body: {},
      }),
    );
  }

  static deleteProductToFavorites(accountId, id) {
    const url = getPath(DASHBOARD_ACCOUNT_PRODUCT_FAVORITE_PATH, { accountId, id });
    return fetch(url, deleteRequest());
  }
}
