import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Field, FormSpy } from "react-final-form";
import PropTypes from "prop-types";
import * as actions from "actions";

import { getAppearanceData } from "selectors/accounts";

import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import Input from "components/shared/formDefault/Input";
import InputFile from "components/shared/formDefault/InputFile";
import Switch from "components/shared/formDefault/Switch";
import ErrorMessage from "components/shared/formDefault/ErrorMessage";
import ItemsList from "components/shared/formDefault/ItemsList";
import Mask from "components/shared/formDefault/Mask";
import PhoneAdapter from "components/shared/formDefault/PhoneAdapter";

import {
  composeValidators,
  validateRequired,
  validatePhone,
  validateEmail,
  validateImage,
} from "components/shared/formDefault/validations";

import BtnGroup from "./shared/BtnGroup";
import FormBlock from "../../SetupBlock";
import mutators from "./shared/mutators";
import styles from "./index.module.sass";

const propTypes = {};

const defaultProps = {};

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);
function AppearanceForm({ fetchAppearance, match, appearanceData, onSubmitAppearanceData }) {
  const { accountId } = match.params;
  useEffect(() => {
    fetchAppearance(accountId);
  }, []);

  const PhoneComponent = (props) => <PhoneAdapter {...props} />;

  return (
    <Form
      initialValues={appearanceData}
      mutators={mutators}
      onSubmit={(data) => onSubmitAppearanceData(accountId, data)}
      render={({ handleSubmit, hasSubmitErrors, submitError, submitting, form, form: { mutators }, values }) => (
        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <div className={styles.formBlocks}>
            <FormBlock title="Appearance">
              <Field
                label="Name"
                component={FormGroupAdapter}
                inputComponent={Input}
                name="name"
                placeholder="Brand Name"
                type="text"
                validate={validateRequired()}
              />

              <Field
                label="Change Login title"
                component={FormGroupAdapter}
                inputComponent={Input}
                name="loginPageTitle"
                placeholder="Change Login title"
                type="text"
                parse={(value) => (value === "" ? null : value)}
              />
              <Field
                label="Change Login text"
                component={FormGroupAdapter}
                inputComponent={Input}
                name="loginPageText"
                placeholder="Change Login text"
                type="text"
                parse={(value) => (value === "" ? null : value)}
              />

              <Field
                label="Change Register title"
                component={FormGroupAdapter}
                inputComponent={Input}
                name="registerPageTitle"
                placeholder="Change Register title"
                type="text"
                parse={(value) => (value === "" ? null : value)}
              />
              <Field
                label="Change Register text"
                component={FormGroupAdapter}
                inputComponent={Input}
                name="registerPageText"
                placeholder="Change Register text"
                type="text"
                parse={(value) => (value === "" ? null : value)}
              />

              <Switch
                title="Marketing Opt-In Enabled"
                appearance
                value="false"
                options={[
                  { id: 1, title: "yes", value: "true", name: "marketingOptInEnabled" },
                  { id: 2, title: "no", value: "false", name: "marketingOptInEnabled" },
                ]}
              />

              <Condition when="marketingOptInEnabled" is="true">
                <Field
                  label="Marketing Opt-In Text"
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="marketingOptInText"
                  placeholder="I want to receive exclusive perks and promotions."
                  type="text"
                  parse={(value) => (value === "" ? null : value)}
                />
              </Condition>

              {appearanceData.guestAccessEnabled && (
                <Switch
                  title="Guest Allowed"
                  appearance
                  options={[
                    { id: 1, title: "yes", value: "true", name: "guestAccessAllowed" },
                    { id: 2, title: "no", value: "false", name: "guestAccessAllowed" },
                  ]}
                />
              )}

              <Switch
                title="Theme Color"
                appearance
                options={[
                  { id: 1, title: "dark", value: "dark", name: "theme" },
                  { id: 2, title: "light", value: "light", name: "theme" },
                ]}
              />

              {/* <Switch
                  title="History Opt-in"
                  appearance
                  options={[
                    { id: 1, title: "single", value: "single", name: "historyPolicy" },
                    { id: 2, title: "multi-brand", value: "multi_brand", name: "historyPolicy" },
                    { id: 3, title: "all", value: "all", name: "historyPolicy" },
                  ]}
                /> */}

              <Field
                label="Brand Emblem"
                component={FormGroupAdapter}
                inputComponent={InputFile}
                name="emblem"
                type="file"
                form={form}
                isLogo
                handleClear={mutators.clearEmblemField}
                validate={validateImage()}
              />

              <Field
                label="Brand Logo"
                component={FormGroupAdapter}
                inputComponent={InputFile}
                name="logo"
                type="file"
                form={form}
                isLogo
                handleClear={mutators.clearLogoField}
                validate={validateImage()}
              />
            </FormBlock>
            <FormBlock title="WHITELIST DOMAINS" subtitle="Domains where the LUXLOCK chat widget should be displayed.">
              <Field
                component={FormGroupAdapter}
                inputComponent={Input}
                name="domain"
                placeholder="Enter domain name"
                type="text"
                parse={(value) => (value === "" ? null : value)}
                // onKeyPress={mutators.addDomainName}
              />

              {/* <FormSpy subscription={{ domainNames: true }}>
                  {({ form }) => {
                    const {
                      values: { contact },
                    } = form.getState();

                    return (
                      <ItemsList
                        items={(contact && contact.domainNames) || []}
                        handleDelete={mutators.deleteDomainName}
                        isSimple
                      />
                    );
                  }}
                </FormSpy> */}
            </FormBlock>
            <FormBlock title="CUSTOMER SERVICE" isSeparator={false}>
              <Field
                component={FormGroupAdapter}
                inputComponent={Input}
                name="email"
                placeholder="luxlock@email.com"
                type="email"
                validate={composeValidators(validateRequired(), validateEmail())}
              />

              {/* WIP!!!!!!!!!!!!!! */}
              <div className={styles.wrapper}>
                <p className={styles.title}>Brand Phone Number</p>
                <Field component={PhoneComponent} inputComponent={Input} name="phone" validate={validatePhone()} />
              </div>
            </FormBlock>
          </div>
          {hasSubmitErrors && submitError && (
            <div className={styles.errorMessageContainer}>
              <ErrorMessage>{submitError}</ErrorMessage>
            </div>
          )}
          <BtnGroup submitting={submitting} />
        </form>
      )}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    appearanceData: getAppearanceData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAppearance: (accountId) => dispatch(actions.fetchAppearance(accountId)),
  onSubmitAppearanceData: (accountId, props) => dispatch(actions.postAppearanceData(accountId, props)),
});

AppearanceForm.defaultProps = defaultProps;
AppearanceForm.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppearanceForm));
