import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import styles from "./index.module.sass";

const propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func,
};

const defaultProps = {
  action: null,
};

function DropDownItem({ title, action, iconClassName }) {
  return (
    <li onClick={action} className={styles.dropItemContainer}>
      <i className={iconClassName} />
      <span>{title}</span>
    </li>
  );
}

DropDownItem.defaultProps = defaultProps;
DropDownItem.propTypes = propTypes;
export default DropDownItem;
