import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as actions from "actions";
import { getStaffsIds } from "selectors/staffs";
import { requestInProcess } from "selectors/request";
import { STAFF_FETCH } from "constants/requestTypes";

import NoStaff from "./shared/NoStaff";
import StaffTable from "./shared/Table/index";
import styles from "./index.module.sass";

const propTypes = {
  fetchAccountStaff: PropTypes.func.isRequired,
  staffsIds: PropTypes.PropTypes.arrayOf(PropTypes.number),
  isLoading: PropTypes.bool,
};

const defaultProps = {
  staffsIds: [],
  isLoading: false,
};

function StaffListPage({
  fetchAccountStaff,
  match: {
    params: { accountId },
  },
  staffsIds,
  isLoading,
}) {
  useEffect(() => {
    fetchAccountStaff(accountId);
  }, []);

  const getTableComponent = () => {
    if (isLoading) return <span>Loading</span>;

    if (staffsIds.length > 0) {
      return (
        <div className={styles.tableWrapper}>
          <StaffTable staffsIds={staffsIds} />
        </div>
      );
    }

    return <NoStaff />;
  };

  return <div className={styles.staffListWrapper}>{getTableComponent()}</div>;
}

const mapStateToProps = (state) => ({
  staffsIds: getStaffsIds(state),
  isLoading: requestInProcess(state, { requestType: STAFF_FETCH }),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccountStaff: (accountId) => dispatch(actions.fetchAccountStaff(accountId)),
});

StaffListPage.propTypes = propTypes;
StaffListPage.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StaffListPage));
