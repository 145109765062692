import { createSelector } from "reselect";

import { always, identity } from "ramda";
import { entityDetailGetter, entityListGetter } from "utils/entities";
import { getEntities } from "../entities";

export const initialState = {
  ids: [],
};

function staffsSelector(state) {
  return state.staff.ids || initialState.ids;
}

export const getStaffsIds = createSelector(staffsSelector, identity);

export const getStaffsDetail = createSelector([always("staffs"), getStaffsIds, getEntities], entityListGetter);

export const getStaffDetail = (state = initialState, id) => entityDetailGetter("staffs", id, state.entities);

export const getContactStaffDetail = createSelector(getStaffDetail, (staff) => staff.contact);

export const getContactStaffFullName = createSelector(
  getStaffDetail,
  (staff) => `${staff.contact.firstName} ${staff.contact.lastName}`,
);

export const getIsOnlineStatusStaffDetail = createSelector(getStaffDetail, (staff) => staff.isOnline);

export const getIsActiveStatusStaffDetail = createSelector(getStaffDetail, (staff) => staff.isActive);

export const getStaffRoleDetail = createSelector(getStaffDetail, (staff) => staff.role);
