import React from "react";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";

import * as actions from "actions";
import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import Input from "components/shared/formDefault/Input";
import InputPassword from "components/shared/formDefault/InputPassword";
import Button from "components/shared/formDefault/Button";

import { composeValidators, validateRequired } from "components/shared/formDefault/validations";

import styles from "./index.module.sass";

const propTypes = {
  sendCredentials: PropTypes.func.isRequired,
};

const defaultProps = {};

function SignInForm({ sendCredentials }) {
  return (
    <Form
      initialValues={{
        grant_type: "password",
        client_secret: process.env.CLIENT_SECRET,
        client_id: process.env.CLIENT_ID,
      }}
      onSubmit={(props) => sendCredentials(props)}
      render={({ handleSubmit, hasSubmitErrors, submitError, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <div className={styles.fieldsCollection}>
            <Field
              component={FormGroupAdapter}
              inputComponent={Input}
              name="username"
              help=""
              placeholder="Username / Email"
              type="text"
              // label="Email"
              autoFocus
              validate={composeValidators(validateRequired())}
            />

            <Field
              component={FormGroupAdapter}
              inputComponent={InputPassword}
              name="password"
              placeholder="Password"
              type="password"
              autoComplete="password"
              validate={validateRequired()}
            />
            {hasSubmitErrors && submitError && (
              <div className={styles.errorMessageContainer}>
                <span className={styles.errorMessage}>{submitError}</span>
              </div>
            )}
          </div>

          <Button type="submit" disable={submitting} auth>
            {submitting ? "Submitting…" : "Login"}
          </Button>
        </form>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  sendCredentials: (userData) => dispatch(actions.sendSignInCredentials(userData)),
});

const enhance = compose(withRouter, connect(null, mapDispatchToProps));

SignInForm.defaultProps = defaultProps;
SignInForm.propTypes = propTypes;

export default enhance(SignInForm);
