import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
// import cl from "classnames";

import Input from "components/shared/formDefault/Input";
import Mask from "components/shared/formDefault/Mask";
import Switch from "components/shared/formDefault/Switch";
import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import ReactSelect from "components/shared/formDefault/ReactSelect";

import { validateDate } from "components/shared/formDefault/validations";
// import styles from "./index.module.sass";

const propTypes = {
  references: PropTypes.shape({
    sizingInfo: PropTypes.shape({
      womenswear: PropTypes.shape({
        jean: PropTypes.arrayOf(),
      }),
    }),
  }),
};

const defaultProps = {
  references: {
    sizingInfo: {
      womenswear: {
        jean: [],
      },
    },
  },
};

function Pereferences({ references }) {
  return (
    <>
      <Switch
        title="I prefer sustainable products"
        radioToggle
        options={[
          { title: "No", value: "false", name: "profile.sustainableProductsFan" },
          { title: "Yes", value: "true", name: "profile.sustainableProductsFan" },
        ]}
        // options={[
        //   { label: "Fund", value: "true" },
        //   { label: "Investment", value: "false" },
        // ]}
      />
      <Field
        label="I like birthday gifts"
        component={FormGroupAdapter}
        inputComponent={Mask}
        name="dateOfBirth"
        placeholder="mm/dd/yy"
        typeOfMask="date"
        className="inputFlex"
        parse={(value) => (value === "" ? null : value)}
        validate={validateDate()}
      />
      <Field
        label="Job Title"
        component={FormGroupAdapter}
        inputComponent={Input}
        name="jobTitle"
        placeholder="Type here"
        className="inputFlex"
        isInfo
        titleInfo="This just helps us understand your work style requirements"
        parse={(value) => (value === "" ? null : value)}
      />

      <Field
        label="Dress Code"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="profile.dressCode.value"
        placeholder="select"
        className="inputFlex"
        type="select"
        options={references.profile && references.profile.dressCodes}
      />

      <Field
        label="Hair Color"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.hairColor.value"
        placeholder="select"
        className="inputFlex"
        type="select"
        isInfo
        titleInfo="Everyone’s got a color palette but sometimes it take a stylist to get you out of a rut. Hair color can make a difference in your recommendations and preferences."
        options={references.sizingInfo && references.sizingInfo.hairColors}
        parse={(value) => (value === "" ? null : value)}
      />
      <Field
        label="Hardware"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="profile.mobileDeviceColor.value"
        placeholder="select"
        className="inputFlex"
        type="select"
        options={references.profile && references.profile.mobileDeviceColors}
      />
    </>
  );
}

Pereferences.defaultProps = defaultProps;
Pereferences.propTypes = propTypes;

export default Pereferences;
