import React from "react";
import { connect } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { map, find, propEq, filter, contains, isEmpty } from "ramda";
import * as actions from "actions";

import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import Input from "components/shared/formDefault/Input";
import InputFile from "components/shared/formDefault/InputFile";
import ReactSelect from "components/shared/formDefault/ReactSelect";
import RadioGroup from "components/shared/formDefault/RadioGroup";
import ErrorMessage from "components/shared/formDefault/ErrorMessage";
import Button from "components/shared/formDefault/Button";

import {
  composeValidators,
  validateRequired,
  validateImage,
  validateEmail,
} from "components/shared/formDefault/validations";

import styles from "../index.module.sass";
import FormBlock from "../shared/FormBlock";
import FieldsCollection from "../shared/FieldsCollection";
import BtnGroup from "../shared/BtnGroup";
import SelectedItems from "../shared/SelectedItems";
import { languagesOptions, levelsOptions } from "../shared/mockData";

const propTypes = {};

const defaultProps = {};

const testOptions = [
  { value: 23, label: "49ers" },
  { value: 58, label: "76ers" },
  { value: 13, label: "Bears" },
  { value: 15, label: "Bengals" },
  { value: 3, label: "Bills" },
];
function NewStaffForm({ onSubmitStaffData, match }) {
  const { accountId } = match.params;
  return (
    <Form
      initialValues={{ contact: {} }}
      mutators={{
        clearAvataField: ([callBack], state, { changeValue }) => {
          changeValue(state, "contact.avatar", () => {
            callBack();
            return null;
          });
        },
      }}
      onSubmit={(props) => onSubmitStaffData(accountId, props)}
      render={({ handleSubmit, hasSubmitErrors, submitError, submitting, form, form: { mutators }, values }) => (
        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <div className={styles.formBlocks}>
            <FormBlock>
              <FieldsCollection title="PROFILE PHOTO">
                <Field
                  component={FormGroupAdapter}
                  inputComponent={InputFile}
                  name="contact.avatar"
                  type="file"
                  form={form}
                  handleClear={mutators.clearAvataField}
                  validate={validateImage()}
                />
                <Field
                  label="Email"
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="email"
                  placeholder="Email"
                  type="email"
                  validate={composeValidators(validateRequired(), validateEmail())}
                />
                <Field
                  label="First Name"
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  validate={composeValidators(validateRequired())}
                />

                <Field
                  label="Last Name"
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  validate={composeValidators(validateRequired())}
                />
              </FieldsCollection>
            </FormBlock>
          </div>
          {hasSubmitErrors && submitError && (
            <div className={styles.errorMessageContainer}>
              <ErrorMessage>{submitError}</ErrorMessage>
            </div>
          )}
          <div className={styles.btnGroup}>
            <Button type="submit" disable={submitting}>
              {submitting ? "saving..." : "save"}
            </Button>
          </div>
        </form>
      )}
    />
  );
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = (dispatch) => ({
  onSubmitStaffData: (accountId, staff) => dispatch(actions.postStaffData(accountId, staff)),
});

NewStaffForm.defaultProps = defaultProps;
NewStaffForm.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewStaffForm));
