import React from "react";
import PropTypes from "prop-types";
import cl from "classnames";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import {
  DASHBOARD_ACCOUNT_SHOPPER_STYLE_CLOSET_PATH,
  DASHBOARD_ACCOUNT_SHOPPER_STYLE_WISHLIST_PATH,
  DASHBOARD_ACCOUNT_SHOPPER_STYLE_BROWSE_PATH,
  DASHBOARD_ACCOUNT_SHOPPER_STYLE_ABANDONED_PATH,
  DASHBOARD_ACCOUNT_SHOPPER_STYLE_RETURNS_PATH,
  getPath,
} from "constants/routes";
import style from "../style.module.sass";

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
};
const defaultProps = {
  match: {
    params: {
      accountId: "",
      id: "",
    },
  },
};

function NavTabs(props) {
  const { match } = props;
  const { accountId, id } = match.params;
  return (
    <>
      <div className={cl(`blockWrapper ${style.navTabs}`)}>
        <ul className={style.navList}>
          <li className={style.navItem}>
            <NavLink
              className={style.navLink}
              activeClassName="navLinkActive"
              to={getPath(DASHBOARD_ACCOUNT_SHOPPER_STYLE_CLOSET_PATH, { accountId, id })}
            >
              Closet
            </NavLink>
          </li>
          <li className={style.navItem}>
            <NavLink
              className={style.navLink}
              activeClassName="navLinkActive"
              to={getPath(DASHBOARD_ACCOUNT_SHOPPER_STYLE_WISHLIST_PATH, { accountId, id })}
            >
              Wishlist
            </NavLink>
          </li>
          <li className={style.navItem}>
            <NavLink
              className={style.navLink}
              activeClassName="navLinkActive"
              to={getPath(DASHBOARD_ACCOUNT_SHOPPER_STYLE_BROWSE_PATH, { accountId, id })}
            >
              Browse
            </NavLink>
          </li>
          <li className={style.navItem}>
            <NavLink
              className={style.navLink}
              activeClassName="navLinkActive"
              to={getPath(DASHBOARD_ACCOUNT_SHOPPER_STYLE_ABANDONED_PATH, { accountId, id })}
            >
              Abandoned
            </NavLink>
          </li>
          <li className={style.navItem}>
            <NavLink
              className={style.navLink}
              activeClassName="navLinkActive"
              to={getPath(DASHBOARD_ACCOUNT_SHOPPER_STYLE_RETURNS_PATH, { accountId, id })}
            >
              Returns
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
}

NavTabs.propTypes = propTypes;
NavTabs.defaultProps = defaultProps;

export default withRouter(NavTabs);
