import React from "react";
import PropTypes from "prop-types";

import cl from "classnames";

import { Item } from "../../shared";
import styles from "../../index.module.sass";

const propTypes = {
  children: PropTypes.arrayOf({}),
};

const defaultProps = {
  children: [{}],
};

function Childrenswear({ children }) {
  return (
    <>
      {children.map((item, index) => (
        <ul className={cl(`${styles.personalList}`)}>
          {index > 0 && <div className={styles.border} />}
          <Item label="Name" value={item.name} styleColumn />
          <Item label="Preference" value={item.preference} styleColumn />
          <Item label="Birthday" value={item.birthday} styleColumn />
          <Item label="Size" value={item.size} styleColumn />
        </ul>
      ))}
    </>
  );
}

Childrenswear.defaultProps = defaultProps;
Childrenswear.propTypes = propTypes;

export default Childrenswear;
