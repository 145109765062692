import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import classNames from "classnames";
import * as actions from "actions";

import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import InputPassword from "components/shared/formDefault/InputPassword";
import ErrorMessage from "components/shared/formDefault/ErrorMessage";
import Button from "components/shared/formDefault/Button";
import iconClear from "../../Image/iconClear.svg";

import styles from "./index.module.sass";

const propTypes = {};
const defaultProps = {};

function PasswordUpdate({ updatePassword }) {
  const [isOpen, setIsOpen] = useState(false);

  const classes = classNames({
    [styles.PasswordUpdateForm]: true,
    [styles.PasswordUpdateFormOpen]: isOpen,
  });

  return (
    <div className={styles.password}>
      <span>Password</span>
      <span className={styles.passwordChange} onClick={() => setIsOpen(!isOpen)}>
        {" "}
        Click to change your password
      </span>
      <div className={classes}>
        <img className={styles.iconClear} src={iconClear} onClick={() => setIsOpen(false)} />
        <Form
          initialValues={{}}
          mutators={{
            clearAvataField: ([callBack], state, { changeValue }) => {
              changeValue(state, "contact.avatar", () => {
                callBack();
                return null;
              });
            },
          }}
          onSubmit={(props) => updatePassword(props)}
          render={({ handleSubmit, hasSubmitErrors, submitError, submitting, form, form: { mutators }, values }) => (
            <form onSubmit={handleSubmit} className={styles.formContainer}>
              <div>
                <Field
                  label="Current password"
                  component={FormGroupAdapter}
                  inputComponent={InputPassword}
                  name="currentPassword"
                  className="inputFlexFill"
                  placeholder="Old password"
                  type="password"
                />
                <Field
                  label="New password"
                  component={FormGroupAdapter}
                  inputComponent={InputPassword}
                  name="password"
                  className="inputFlexFill"
                  placeholder="New password"
                  type="password"
                />
                <Field
                  label="Confirm password"
                  component={FormGroupAdapter}
                  inputComponent={InputPassword}
                  name="passwordConfirmation"
                  className="inputFlexFill"
                  placeholder="Confirm password"
                  type="password"
                />
              </div>
              {hasSubmitErrors && submitError && (
                <div className={styles.errorMessageContainer}>
                  <ErrorMessage>{submitError}</ErrorMessage>
                </div>
              )}
              <div className={styles.btnRight}>
                <Button type="submit" disable={submitting}>
                  {submitting ? "saving..." : "save"}
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (props) => dispatch(actions.updatePassword(props)),
});

PasswordUpdate.defaultProps = defaultProps;
PasswordUpdate.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(PasswordUpdate);
