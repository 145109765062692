import React, { useState, useEffect, useCallback, useRef } from "react";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { without, includes, reverse } from "ramda";
import { connect } from "react-redux";
import * as actions from "actions";
import cl from "classnames";
import Slider from "react-slick";

import { getProductsForEditor } from "selectors/products";
import editor from "./editor.module.sass";
import { headerBg } from "../shared/Image";
import TargetBox from "./TargetBox";
import EditorItem from "./EditorItem";

const propTypes = {
  productsEditor: PropTypes.arrayOf(PropTypes.object),
  onAddProductOfStorageToEditor: PropTypes.func.isRequired,
};
const defaultProps = {
  productsEditor: [],
};

function Editor(props) {
  const {
    productsEditor,
    onAddProductOfStorageToEditor,
    match,
    fetchProductsCollection,
    onRemoveProductToEditor,
  } = props;
  const { accountId, id } = match.params;
  const [isOpen, setIsOpen] = useState(false);
  const [isPage, setIsPage] = useState(1);
  const handleClick = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };

  const toggleEditorClasses = cl({
    [editor.wrapper]: true,
    [editor.open]: isOpen,
  });

  useEffect(() => {
    let productsEditorStorage;
    if (localStorage.getItem(`ProductsToEditor: ${id}`) === null) {
      productsEditorStorage = [];
    } else {
      productsEditorStorage = JSON.parse(localStorage.getItem(`ProductsToEditor: ${id}`));
    }
    onAddProductOfStorageToEditor(productsEditorStorage);
  }, []);

  const [droppedBoxIds, setDroppedBoxIds] = useState([]);
  function isDropped(boxName) {
    return droppedBoxIds.indexOf(boxName) > -1;
  }

  const handleDrop = useCallback(
    (item) => {
      const { id } = item.product;
      if (droppedBoxIds.length < 4) {
        setDroppedBoxIds(update(droppedBoxIds, id ? { $push: [id] } : { $push: [] }));
      }
    },
    [droppedBoxIds],
  );

  function onRemoveProductEditor(productId) {
    const editorCollection = JSON.parse(localStorage.getItem(`ProductsToEditor: ${id}`));
    const filteredItems = without(productId, editorCollection);
    localStorage.setItem(`ProductsToEditor: ${id}`, JSON.stringify(filteredItems));

    onRemoveProductToEditor(productId);
  }

  const settings = {
    infinite: false,
    swipe: false,
    slidesToShow: 1,
    speed: 500,
    rows: 3,
    slidesPerRow: 3,
    dots: true,
    beforeChange: (prev, next) => {
      setIsPage(next + 1);
    },
    appendDots: function(dots, i) {
      return (
        <div>
          <span className={editor.dots}> {`${isPage} / ${dots.length}`} </span>
        </div>
      );
    },
  };

  const productIds = productsEditor.map((productData) => productData.id);
  const handleProductsCollection = () => {
    const editorCollection = JSON.parse(localStorage.getItem(`ProductsToEditor: ${id}`));
    const filteredItems = without(productIds, editorCollection);

    localStorage.setItem(`ProductsToEditor: ${id}`, JSON.stringify(filteredItems));
    onRemoveProductToEditor(productIds);
  };

  const [dropProducts, setDropProducts] = useState([]);

  const isDrop = dropProducts.length === 4 ? false : true;
  return (
    <div className={toggleEditorClasses}>
      <div data-name="buttonEditor" className={editor.label} onClick={handleClick}>
        <span>The Editor</span>
      </div>
      <div className={editor.main}>
        <div className={editor.header} style={{ backgroundImage: `url(${headerBg})` }}>
          Editor
        </div>
        <div className={editor.body}>
          <ul
            className={cl(`editorSlider ${editor.list}`)}
            style={{ overflow: "hidden", clear: "both", marginTop: "1.5rem" }}
          >
            <Slider {...settings}>
              {reverse(productsEditor).map((product, index) => (
                <EditorItem
                  key={product.id}
                  onRemoveProductEditor={onRemoveProductEditor}
                  isDropped={isDropped(product.id)}
                  product={product}
                  index={index}
                  isDrop={isDrop}
                />
              ))}
            </Slider>
            {productsEditor.length > 0 && (
              <div className={editor.btnGroup}>
                <button
                  data-name="buttonClear"
                  onClick={handleProductsCollection}
                  className={cl(`btn ${editor.btnClear}`)}
                >
                  Clear
                </button>
              </div>
            )}
          </ul>
          <TargetBox
            dropProducts={dropProducts}
            setDropProducts={setDropProducts}
            fetchProductsCollection={fetchProductsCollection}
            onDrop={(item) => handleDrop(item)}
            accountId={accountId}
            id={id}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    productsEditor: getProductsForEditor(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  onAddProductOfStorageToEditor: (productIds) => dispatch(actions.addProductOfStorageToEditorSucceses(productIds)),
  onRemoveProductToEditor: (productId) => dispatch(actions.removeProductToEditorSucceses(productId)),
});

// const enhance = compose(
//   connect(mapStateToProps, null),
//   lifecycle({
//     componentDidMount() {
//       // const { fetchAccountShopProductsCollection, match } = this.props;
//       // const { accountId } = match.params;
//       // fetchAccountShopProductsCollection(accountId);
//     },
//   }),
// );

Editor.propTypes = propTypes;
Editor.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Editor));
