import React from "react";
import PropTypes from "prop-types";
import cl from "classnames";
import { propOr, reject, isNil } from "ramda";

import { defaultAvatar } from "../Image";
import profile from "../../profile.module.sass";

const propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    gender: PropTypes.object,
    id: PropTypes.string,
    email: PropTypes.string,
    lastOnline: PropTypes.string,
  }),
};
const defaultProps = {
  user: {
    firstName: "",
    lastName: "",
    gender: {},
    id: "",
    email: "",
    lastOnline: "N/A",
  },
};

function Header({ user }) {
  const { firstName, lastName, gender, id, email, avatar, lastOnline } = user;
  const stats = propOr({}, "stats", user);
  const location = propOr({}, "location", user);
  const dispalyLocation = reject(isNil)([location.country, location.subdivisions.join(", "), location.city]).join(", ");

  // wip temporary fix!!!!!!!!
  const privatId = id.replace(/^(.{6})([0-9]{7})/, "*************");
  return (
    <div className="col-lg-12">
      <div className={cl(profile.headeWrapper, profile.header)}>
        <div className={profile.headerLeft}>
          <div className="flex" style={{ marginBottom: "5px" }}>
            <img className={profile.avatar} src={avatar ? avatar : defaultAvatar} alt="avatar" />
            <div className={profile.info}>
              <h2 className={profile.name}>
                {firstName} {lastName}
              </h2>
              <p className={profile.paragraph}>{gender && gender.label}</p>
              <p className={profile.paragraph}>{dispalyLocation}</p>
              <div className={profile.customerId}>
                <span>Email: </span>
                <a className={profile.email}>
                  {email}
                </a>
              </div>
              <div className={profile.customerId}>
                <span>Customer ID: </span>
                <h3>{privatId}</h3>
              </div>
              <div className="flex">
                <span>Last online: </span>
                <p className={profile.paragraph}>{lastOnline}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={profile.headerRight}>
          <div className={profile.countDetail}>
            <h2>6:22</h2>
            <span>AVG TIME</span>
          </div>
          <div className={profile.countDetail}>
            <h2>{stats.formattedAov && stats.formattedAov}</h2>
            <span>AOV</span>
          </div>
          <div className={profile.countDetail}>
            <h2>{stats.formattedLtv && stats.formattedLtv}</h2>
            <span>LTV</span>
          </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
