export const ACCOUNTS_REMOVE_STATE_SUCCESS = "ACCOUNTS_REMOVE_STATE_SUCCESS";
export const ACCOUNTS_COUNT_MESSAGE_SUCCESS = "ACCOUNTS_COUNT_MESSAGE_SUCCESS";
export const ACCOUNTS_REMOVE_COUNT_MESSAGE_SUCCESS = "ACCOUNTS_REMOVE_COUNT_MESSAGE_SUCCESS";
// Entities
export const ENTITIES_DELETE = "ENTITIES_DELETE";
export const ENTITIES_MERGE = "ENTITIES_MERGE";
export const ENTITIES_SYNC = "ENTITIES_SYNC";

export const ACCOUNT_APPEARANCE_FETCH_SUCCESS = "ACCOUNT_APPEARANCE_FETCH_SUCCESS";

export const ACCOUNT_SHOPPERS_FETCH_SUCCESS = "ACCOUNT_SHOPPERS_FETCH_SUCCESS";
export const ACCOUNT_SHOPPER_REMOVE_SUCCESS = "ACCOUNT_SHOPPER_REMOVE_SUCCESS";

export const ACCOUNTS_SHOPPER_CURRENT_EVENTS_SUCCESS = "ACCOUNTS_SHOPPER_CURRENT_EVENTS_SUCCESS";
export const ACCOUNTS_CONSULTANT_SUCCESS = "ACCOUNTS_CONSULTANT_SUCCESS";
export const ACCOUNTS_CONSULTANT_STATUS_SUCCESS = "ACCOUNTS_CONSULTANT_STATUS_SUCCESS";
export const ACCOUNTS_USER_SUCCESS = "ACCOUNTS_USER_SUCCESS";
export const ACCOUNTS_USER_SUCCESS_BANDWIDTH = "ACCOUNTS_USER_SUCCESS_BANDWIDTH";

export const LOCATIONS_FETCH_SUCCESS = "LOCATIONS_FETCH_SUCCESS";

export const ACCOUNTS_FETCH_SUCCESS = "ACCOUNTS_FETCH_SUCCESS";
export const ACCOUNTS_ADD_PRODUCT_TO_EDITOR_SUCCESS = "ACCOUNTS_ADD_PRODUCT_TO_EDITOR_SUCCESS";
export const ACCOUNTS_ADD_PRODUCT_TO_FAVORITES_SUCCESS = "ACCOUNTS_ADD_PRODUCT_TO_FAVORITES_SUCCESS";
export const ACCOUNTS_ADD_PRODUCT_OF_STORAGE_TO_EDITOR_SUCCESS = "ACCOUNTS_ADD_PRODUCT_OF_STORAGE_TO_EDITOR_SUCCESS";
export const ACCOUNTS_ADD_PRODUCT_OF_STORAGE_TO_FAVORITES_SUCCESS =
  "ACCOUNTS_ADD_PRODUCT_OF_STORAGE_TO_FAVORITES_SUCCESS";
export const ACCOUNTS_REMOVE_PRODUCT_TO_EDITOR_SUCCESS = "ACCOUNTS_REMOVE_PRODUCT_TO_EDITOR_SUCCESS";
export const ACCOUNTS_REMOVE_PRODUCT_TO_FAVORITES_SUCCESS = "ACCOUNTS_REMOVE_PRODUCT_TO_FAVORITES_SUCCESS";
export const ADD_MESSAGE_TO_CHAT_SUCCESS = "ADD_MESSAGE_TO_CHAT_SUCCESS";
export const ADD_ACTIVITY_EVENT_SUCCESS = "ADD_ACTIVITY_EVENT_SUCCESS";

export const TYPING_STATE_SUCCESS = "TYPING_STATE_SUCCESS";

export const PRODUCT_FETCH_SUCCESS = "PRODUCT_FETCH_SUCCESS";

export const STAFF_LIST_FETCH_SUCCESS = "STAFF_LIST_FETCH_SUCCESS";
export const STAFF_FETCH_SUCCESS = "STAFF_FETCH_SUCCESS";

export const PRODUCTS_COLLECTION_WITH_FILTTER_FETCH_SUCCESS = "PRODUCTS_COLLECTION_WITH_FILTTER_FETCH_SUCCESS";
export const PRODUCTS_COLLECTION_RECOMMENDED_FETCH_SUCCESS = "PRODUCTS_COLLECTION_RECOMMENDED_FETCH_SUCCESS";
export const SHOP_PRODUCTS_COLLECTION_FETCH_SUCCESS = "SHOP_PRODUCTS_COLLECTION_FETCH_SUCCESS";
export const PRODUCTS_LOAD_COLLECTION_FETCH_SUCCESS = "PRODUCTS_LOAD_COLLECTION_FETCH_SUCCESS";
export const SHOP_PRODUCTS_COLLECTION_LOAD_FETCH_SUCCESS = "SHOP_PRODUCTS_COLLECTION_LOAD_FETCH_SUCCESS";
export const STYLE_PRODUCTS_RESULT_COLLECTION_FETCH_SUCCESS = "STYLE_PRODUCTS_RESULT_COLLECTION_FETCH_SUCCESS";
export const STYLE_PRODUCTS_COLLECTION_LOAD_FETCH_SUCCESS = "STYLE_PRODUCTS_COLLECTION_LOAD_FETCH_SUCCESS";

export const SHOP_PRODUCTS_COLLECTION_SEARCH_FETCH_SUCCESS = "SHOP_PRODUCTS_COLLECTION_SEARCH_FETCH_SUCCESS";

export const STYLE_PRODUCTS_COLLECTION_FETCH_SUCCESS = "STYLE_PRODUCTS_COLLECTION_FETCH_SUCCESS";

export const PRODUCTS_COLLECTION_FETCH_SUCCESS = "PRODUCTS_COLLECTION_FETCH_SUCCESS";

export const ACCOUNT_ACTIVITY_EVENTS_FETCH_SUCCESS = "ACCOUNT_ACTIVITY_EVENTS_FETCH_SUCCESS";

export const ACCOUNT_CHAT_MESSAGES_FETCH_SUCCESS = "ACCOUNT_CHAT_MESSAGES_FETCH_SUCCESS";
export const ACCOUNT_CHAT_MESSAGES_LOAD_FETCH_SUCCESS = "ACCOUNT_CHAT_MESSAGES_LOAD_FETCH_SUCCESS";
export const ACCOUNTS_INSTAGRAM_FEED_FETCH_SUCCESS = "ACCOUNTS_INSTAGRAM_FEED_FETCH_SUCCESS";
// Request in progress
export const REQUEST_IN_PROCESS_SET = "REQUEST_IN_PROCESS_SET";
export const FETCH_MY_PROFILE_SUCCESS = "FETCH_MY_PROFILE_SUCCESS";

// shopper profile
export const SHOPPER_PROFILE_FETCH_SUCCESS = "SHOPPER_PROFILE_FETCH_SUCCESS";
export const SHOPPER_PROFILE_PHOTOS_FETCH_SUCCESS = "SHOPPER_PROFILE_PHOTOS_FETCH_SUCCESS";
export const SHOPPER_PROFILE_REMOVE_PHOTOS_FETCH_SUCCESS = "SHOPPER_PROFILE_REMOVE_PHOTOS_FETCH_SUCCESS";
export const SHOPPER_PROFILE_UPDATE_PHOTOS_FETCH_SUCCESS = "SHOPPER_PROFILE_UPDATE_PHOTOS_FETCH_SUCCESS";

export const SHOPPER_INSTAGRAM_FETCH_SUCCESS = "SHOPPER_INSTAGRAM_FETCH_SUCCESS";

// me
export const FETCH_ME_SUCCESS = "FETCH_ME_SUCCESS";
