import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import { without, find, propEq, filter } from "ramda";
import cl from "classnames";

import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import ReactSelect from "components/shared/formDefault/ReactSelect";
import Input from "components/shared/formDefault/Input";
import Mask from "components/shared/formDefault/Mask";

import iconAdd from "../../Image/iconAdd.svg";
import iconClear from "../../Image/iconClear.svg";
import { validateDate } from "components/shared/formDefault/validations";

import styles from "./index.module.sass";

const propTypes = {
  references: PropTypes.shape({
    sizingInfo: PropTypes.shape({
      womenswear: PropTypes.shape({
        jean: PropTypes.arrayOf(),
      }),
    }),
  }),
  pets: PropTypes.arrayOf(),
};

const defaultProps = {
  references: {
    sizingInfo: {
      womenswear: {
        jean: [],
      },
    },
  },
  pets: [],
};

function Pets({ references, pets, handleDeletePet }) {
  function getOptions(value) {
    const options =
      value &&
      value.map((item) => {
        return { value: item, label: item };
      });
    return options;
  }
  const [newPets, setPets] = useState(pets);

  function onFormSubmitHandler() {
    setPets([...newPets, { delete: false }]);
  }

  const removeTags = (indexToRemove) => {
    const updatePets = [...pets.filter((_, index) => index !== indexToRemove)];
    handleDeletePet(updatePets);
    setPets(updatePets);
  };

  return (
    <>
      {newPets &&
        newPets.map((item, index) => (
          <>
            {!item.delete && (
              <div className={styles.wrapper}>
                <img className={styles.iconClear} src={iconClear} onClick={() => removeTags(index)} />
                <div className={styles.input}>
                  <Field
                    label="Name"
                    component={FormGroupAdapter}
                    inputComponent={Input}
                    name={`sizingInfo.pets.${index}.name`}
                    placeholder="First Name"
                    type="text"
                  />
                </div>
                <div className={styles.select}>
                  <Field
                    label="Preference"
                    component={FormGroupAdapter}
                    inputComponent={ReactSelect}
                    name={`sizingInfo.pets.${index}.preference`}
                    placeholder="select"
                    type="select"
                    options={getOptions(references.sizingInfo && references.sizingInfo.genders)}
                  />
                </div>
                <div className={styles.input}>
                  <Field
                    label="Type"
                    component={FormGroupAdapter}
                    inputComponent={Input}
                    name={`sizingInfo.pets.${index}.type`}
                    placeholder="Type"
                    type="text"
                  />
                </div>
                <div className={styles.age}>
                  <Field
                    label="Age"
                    component={FormGroupAdapter}
                    inputComponent={Input}
                    name={`sizingInfo.pets.${index}.age`}
                    placeholder="Age"
                    type="text"
                  />
                </div>
                <div className={styles.select}>
                  <Field
                    label="Sweater size"
                    component={FormGroupAdapter}
                    inputComponent={ReactSelect}
                    name={`sizingInfo.pets.${index}.sweaterSize`}
                    placeholder=""
                    type="select"
                    options={getOptions(references.sizingInfo && references.sizingInfo.petSizes.sweater)}
                  />
                </div>
                <div className={styles.select}>
                  <Field
                    label="Collar size"
                    component={FormGroupAdapter}
                    inputComponent={ReactSelect}
                    name={`sizingInfo.pets.${index}.collarSize`}
                    placeholder=""
                    type="select"
                    options={getOptions(references.sizingInfo && references.sizingInfo.petSizes.collar)}
                  />
                </div>
              </div>
            )}
          </>
        ))}
      <img src={iconAdd} onClick={() => onFormSubmitHandler()} />
    </>
  );
}

Pets.defaultProps = defaultProps;
Pets.propTypes = propTypes;

export default Pets;
