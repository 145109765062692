import React, { useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Button from "../Button";
import BaseFileInput from "../../formBase/InputFile";
import Input from "../Input";
import styles from "./index.module.sass";

const propTypes = {
  isError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLogo: PropTypes.bool,
  handleClear: PropTypes.func,
};

const defaultProps = {
  isError: false,
  isLogo: false,
  value: undefined,
};

function ClassicFileInput({ isError, onChange, handleClear, ...props }) {
  const avatarUploadRef = useRef(null);
  const { value, isLogo } = props;
  const binaryData = [];
  binaryData.push(value);
  return (
    <div className={classNames(styles.container, isLogo && styles.logoUploadContainer)}>
      {value ? (
        <img
          src={
            typeof value === "string" ? value : window.URL.createObjectURL(new Blob(binaryData, { type: value.type }))
          }
          alt="Avatar"
          className={styles.avatarImage}
        />
      ) : (
        <div className={styles.placeHolder} />
      )}
      <div className={styles.actionGroup}>
        <div className={classNames(styles.fileInputWrapper, value && styles.marginBottom)}>
          <Input placeholder={value.name || "Click here to upload"} disabled />
          <BaseFileInput
            onChange={onChange}
            {...props}
            refComponent={avatarUploadRef}
            className={styles.hideFileInput}
          />
        </div>
        {value && (
          <div className={styles.resetButtonWrapper}>
            <Button type="reset" onClick={() => handleClear(() => (avatarUploadRef.current.value = null))}>
              remove
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

ClassicFileInput.defaultProps = defaultProps;
ClassicFileInput.propTypes = propTypes;

export default ClassicFileInput;
