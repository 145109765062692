import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { getIn } from "utils/ramda";
import { withRouter, Switch, Route } from "react-router-dom";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import * as actions from "actions";
import cl from "classnames";
import { requestInProcess } from "selectors/request";
import * as routes from "constants/routes";
import * as requestTypes from "constants/requestTypes";

import { getShoppersDetail } from "selectors/shoppers";
import Contact from "./Contact";
import ContactList from "./ContactList";
import chat from "./chat.module.sass";
import noActiveShoppers from "./image/noActiveShoppers.png";

const propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setShowNav: PropTypes.func.isRequired,
  shopProductsCollection: PropTypes.shape({
    meta: PropTypes.shape({
      isLastPage: PropTypes.bool,
      nextPage: PropTypes.number,
    }),
  }),
  shoppers: PropTypes.arrayOf(PropTypes.object),
  isLoadingShoppers: PropTypes.bool,
};
const defaultProps = {
  shopProductsCollection: {
    meta: {},
  },
  shoppers: [],
  isLoadingShoppers: false,
};

function Chat(props) {
  const { setShowNav, shopProductsCollection, shoppers, isLoadingShoppers, fetchAccountShoppers, match } = props;

  const [isLoading, setIsLoading] = useState(true);
  const { accountId } = match.params;

  useEffect(() => {
    fetchAccountShoppers(accountId);
    if (!isLoadingShoppers) {
      clearTimeout(typingTimer);
      const typingTimer = setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
    return setIsLoading(false);
  }, []);

  if (isLoading) {
    return null;
  }
  return (
    <div className="contentWrapper">
      <div className="pageWrapperFlex">
        <div className={chat.wrapper}>
          <ContactList {...props} />
        </div>
        <DndProvider backend={Backend}>
          <div className={cl(`${chat.content}`)}>
            {shoppers.length > 0 ? (
              <Route
                path={routes.DASHBOARD_ACCOUNT_SHOPPER_PATH}
                render={(p) => {
                  const key = p.match.params.id;
                  return <Contact {...props} key={key} />;
                }}
              />
            ) : (
              <div className={chat.waitingBlock} style={{ backgroundImage: `url(${noActiveShoppers})` }}>
                {/* <p>No active shoppers</p> */}
              </div>
            )}
          </div>
        </DndProvider>
      </div>
    </div>
  );
}

function mapStateToProps(state, props) {
  const accountId = getIn("match.params.accountId", props);
  return {
    shoppers: getShoppersDetail(state),
    isLoadingShoppers: requestInProcess(state, { requestType: requestTypes.ACCOUNT_SHOPPERS_FETCH }),
    accountId,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchAccountShoppers: (accountId) => dispatch(actions.fetchAccountShoppers(accountId)),
  fetchShopProductsLoadCollection: (accountId, nextPage) =>
    dispatch(actions.fetchShopProductsLoadCollection(accountId, nextPage)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

Chat.propTypes = propTypes;
Chat.defaultProps = defaultProps;

export default enhance(Chat);
