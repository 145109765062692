import React from "react";
import style from "./notFoundPage.module.sass";

import background from "./static/bg.jpg";
import refreshIcon from "./static/refresh.svg";

function NotFoundPage() {
  return (
    <div className={style.container} style={{ backgroundImage: `url(${background})` }}>
      <div className={style.wrapper}>
        <h1 className={style.header}>We're never really lost</h1>
        <p className={style.subheader}>404 error page</p>
        <div className={style.contentPage}>
          <a href="/" className={style.refresh}>
            <img src={refreshIcon} alt="refresh" />
            <span className={style.span}>Find me</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
