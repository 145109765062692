import React from "react";
import PropTypes from "prop-types";
import ReactSelect, { components } from "react-select";

const propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  openMenuOnFocus: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  classNamePrefix: "react-select",
  disabled: false,
  openMenuOnFocus: true,
  options: [],
  value: undefined,
};

function Select({ options, openMenuOnFocus, onChange, name, value, classNamePrefix, disabled, ...rest }) {
  const customStyles = { multiValue: (base) => ({ ...base, display: "none" }) };
  return (
    <ReactSelect
      isMulti
      simpleValue
      name={name}
      options={options}
      isDisabled={disabled}
      isClearable
      value={value}
      onChange={onChange}
      openMenuOnFocus={openMenuOnFocus}
      classNamePrefix={classNamePrefix}
      {...rest}
    />
  );
}

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
