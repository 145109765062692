import defaultAvatar from "./defaultAvatar.svg";
import profileAvatar from "./profileAvatar.jpg";
import recomendedImg from "./recomendedImg.jpg";
import search from "./search.png";
import paperPlane from "./paperPlane.svg";
import envelope from "./envelope.svg";
import plus from "./plus.svg";
import minus from "./minus.svg";
import headerBg from "./headerBg.png";
import shape from "./shape.png";
import loading from "./loading.gif";

export {
  defaultAvatar,
  profileAvatar,
  paperPlane,
  recomendedImg,
  search,
  envelope,
  plus,
  minus,
  headerBg,
  shape,
  loading,
};
