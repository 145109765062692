import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import cl from "classnames";

import ProductsGallery from "../../../shared/ProductsGallery";
import style from "../../style.module.sass";
import defaultAvatar from "../../../shared/Image/defaultAvatar.svg";

const propTypes = {};

const defaultProps = {};

function ShopperMessage({ message, avatar, firstName, lastName, isGuest }) {
  const initials = (value) => {
    const name = value ? value.match(/\b\w/g) : [];
    return (((name && name.shift()) || "") + ((name && name.pop()) || "")).toUpperCase();
  };

  const initialsAvatar = initials(firstName) + initials(lastName);

  return (
    <div key={message.id} className={cl(style.message, style.message_reverse)} data-name="bubbleShopper">
      {isGuest ? (
        <img className={style.imgAvatar} src={defaultAvatar} alt="defaultAvatar" />
      ) : (
        <>
          {avatar ? (
            <img className={style.imgAvatar} src={avatar} alt="avatar" />
          ) : (
            <span className={style.avatarInitials}>{initialsAvatar}</span>
          )}
        </>
      )}
      {message.products && (
        <div className="products" style={{ marginTop: "10px" }}>
          <ProductsGallery isGrid products={message.products} />
        </div>
      )}
      {message.text && (
        <div className={style.messageText}>
          <div dangerouslySetInnerHTML={{ __html: message.text }} />
          {/* FIXME. Add date if it's not today */}
          <span className={style.timesTamp}>{moment(message.createdAt).format("h:mm A")}</span>
        </div>
      )}
    </div>
  );
}

ShopperMessage.propTypes = propTypes;
ShopperMessage.defaultProps = defaultProps;

export default ShopperMessage;
