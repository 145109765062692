import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.sass";

const defaultProps = {
  children: null,
};

const propTypes = {
  children: PropTypes.string,
};

function Title({ children }) {
  return (
    <div className={styles.titleWrap}>
      <span className={styles.title}>{children}</span>
    </div>
  );
}
Title.defaultProps = defaultProps;
Title.propTypes = propTypes;

export default Title;
