import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SliderCarousel from "react-slick";
import { connect } from "react-redux";
import cl from "classnames";
import * as actions from "actions";
import { requestInProcess } from "selectors/request";
import * as requestTypes from "constants/requestTypes";

import { getProductsRecommendedDetail } from "selectors/products";
import ProductCard from "../Products/ProductCard";
import { recomendedImg } from "../Image";
import style from "./style.module.sass";

const propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
const defaultProps = {
  accountId: null,
  id: null,
};

function Slider({ accountId, id, products, fetchProductsRecommended, isLoading }) {
  const settings = {
    className: "slider variable-width",
    infinite: true,
    centerMode: false,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  useEffect(() => {
    // const param = {};
    // fetchStyleProductsCollection(accountId, id, param, "closet");
    fetchProductsRecommended(accountId);
  }, []);

  const sliderStyle = {
    width: "100%",
  };

  return (
    <>
      <div className={cl(`blockWrapper ${style.slider}`)}>
        <h4 className={style.title}>Recommendations</h4>
        {isLoading ? (
          <span>No products</span>
        ) : (
          <SliderCarousel {...settings}>
            {products.map((product, index) => (
              <ProductCard
                key={index}
                index={index}
                product={product}
                accountId={accountId}
                shopperId={id}
                sliderStyle={sliderStyle}
              />
            ))}
          </SliderCarousel>
        )}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    isLoading: requestInProcess(state, { requestType: requestTypes.PRODUCTS_COLLECTION_RECOMMENDED_FETCH }),
    products: getProductsRecommendedDetail(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchProductsRecommended: (accountId) => dispatch(actions.fetchProductsRecommended(accountId)),
});

Slider.propTypes = propTypes;
Slider.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
