import React from "react";

import { DASHBOARD_AUTH_SIGN_IN_PATH } from "constants/routes";

import styles from "./index.module.sass";
import NavigateLink from "../shared/NavigateLink";
import RestorePasswordForm from "./shared/Form";

function RestorePasswordPage() {
  return (
    <div className={styles.formWrapper}>
      <RestorePasswordForm />
      <NavigateLink to={DASHBOARD_AUTH_SIGN_IN_PATH}>Login</NavigateLink>
    </div>
  );
}

export default RestorePasswordPage;
