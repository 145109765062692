import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "actions";
import * as requestTypes from "constants/requestTypes";
import { requestInProcess } from "selectors/request";

import { getProductDetail } from "selectors/products";
import style from "./style.module.sass";
import loading from "./preloader/loading.gif";

const propTypes = {
  product: PropTypes.shape({
    images: PropTypes.arrayOf({}),
  }),
};

const defaultProps = {
  product: {
    images: [],
  },
};

function Product(props) {
  const { fetchProduct, id, match, product, isLoading } = props;
  const { images, title, formattedPrice } = product;
  const { accountId } = match.params;

  const [activeImg, setActiveImg] = useState(undefined);
  useEffect(() => {
    fetchProduct(accountId, id);
  }, []);

  if (images && !activeImg && images.length > 0) {
    return setActiveImg(images[0].src);
  }

  // if (isLoading) {
  //   return (
  //     <div className={style.preloader}>
  //       <img src={loading} />
  //     </div>
  //   );
  // }
  return (
    <>
      {images && (
        <>
          <h3 className={style.title}>{title}</h3>
          <div className="listFlex">
            <div className={style.blockImg}>
              {/* <img  /> */}
              <img alt="products img" className={style.img} src={activeImg} effect="blur" />
            </div>
            {images.length > 1 && (
              <div className={style.blockImgSmall}>
                {images.map(
                  (img, index) =>
                    activeImg !== img.src && (
                      <img
                        key={img.id}
                        alt="products img"
                        className={style.imgSmall}
                        src={img.src}
                        onClick={() => setActiveImg(img.src)}
                      />
                    ),
                )}
              </div>
            )}
          </div>
          <span className={style.priceTitle}>Price</span>
          <h3 className={style.price}>{formattedPrice}</h3>
        </>
      )}
    </>
  );
}

function mapStateToProps(state, props) {
  return {
    product: getProductDetail(state, props.id),
    isLoading: requestInProcess(state, { requestType: requestTypes.PRODUCT_FETCH }),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchProduct: (accountId, productId) => dispatch(actions.fetchProduct(accountId, productId)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

Product.propTypes = propTypes;
Product.defaultProps = defaultProps;

export default enhance(Product);
