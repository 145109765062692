import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { getIn } from "utils/ramda";
import { NavLink, withRouter } from "react-router-dom";
import cx from "classnames";

import { getCurrentAccount } from "selectors/accounts";
import { getSessionMe, getSessionUser } from "selectors/session";

import useOnClickOutside from "hooks/useOnClickOutside";
import { DASHBOARD_ACCOUNT_PATH, getPath } from "constants/routes";
import header from "./header.module.sass";

import { UserInfo, DropDown, StylePageNav } from "./shared";
import { luxlockLogo } from "./shared/Image";
import ToggleMenuMobile from "./shared/ToggleMenuMobile";

const propTypes = {
  showNav: PropTypes.bool,
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isStylist: PropTypes.bool,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    contact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatar: PropTypes.string,
    }),
  }),
  me: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    marketingOptIn: PropTypes.bool,
    powerBiUrl: PropTypes.string,
  }),
  currentAccount: PropTypes.shape({
    domain: PropTypes.string,
  }),
  isPaused: PropTypes.bool,
};
const defaultProps = {
  showNav: false,
  isStylist: false,
  accountId: null,
  isPaused: false,
  user: {
    contact: {
      firstName: "",
      lastName: "",
      avatar: "",
    },
  },
  me: {},
  currentAccount: {},
};

function Header(props) {
  const { showNav, accountId, isStylist, isPaused, user, me, currentAccount } = props;

  const location = getIn("location.pathname", props);
  const match = matchPath(`${location}`, {
    path: "/accounts/:accountId/shoppers/:id",
    exact: false,
    strict: false,
  });
  const id = getIn("params.id", match);

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };

  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  const toggleItemClasses = cx({
    [`toggleItem`]: true,
    [`toggleItemActive`]: isOpen,
  });

  return (
    <header ref={ref} className={header.wrapper}>
      <div className="flex">
        {isStylist ? (
          <NavLink className={header.logoLink} to={getPath(DASHBOARD_ACCOUNT_PATH, { accountId })}>
            <img className={header.logo} src={luxlockLogo} alt="logo" />
          </NavLink>
        ) : (
          <div className={header.logoLink}>
            <img className={header.logo} src={luxlockLogo} alt="logo" />
          </div>
        )}
        {showNav && (
          <div className="desctopBlock">
            <StylePageNav accountId={accountId} id={id} />
          </div>
        )}
      </div>
      <div className="flex">
        <div className="flex desctopBlock">
          <UserInfo accountId={accountId} currentAccount={currentAccount} isStylist={isStylist} user={user} />
          <DropDown accountId={accountId} isPaused={isPaused} me={me} />
        </div>
        <ToggleMenuMobile accountId={accountId} id={id} showNav={showNav} isOpen={isOpen} />
        <div onClick={handleClick} className="toggleMenu toggleMenuRight">
          <span className={toggleItemClasses} />
        </div>
      </div>
    </header>
  );
}

function mapStateToProps(state, props) {
  const { accountId } = props;

  return {
    currentAccount: getCurrentAccount(state, { accountId }),
    user: getSessionUser(state),
    me: getSessionMe(state),
  };
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

const enhance = compose(connect(mapStateToProps, null), withRouter);

export default enhance(Header);
