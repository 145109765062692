import React from "react";
import style from "./style.module.sass";

import backgroundComingSoon from "./backgroundComingSoon.jpg";

function ComingSoon() {
  return (
    <div className="contentWrapper">
      <div className={style.wrap} style={{ backgroundImage: `url(${backgroundComingSoon})` }}>
        <div className={style.description}>
          <span>nothing to see here</span>
          <h1>comming soon</h1>
          <p>
            Our ideas for the future entail the joys of technology subject to humanity; uphold with the elegance of a
            noble discussion.
          </p>
          <p>— Brunello Cucinelli</p>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
