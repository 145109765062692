import { ProductsApiAdapter } from "apiAdapters";

import * as requestTypes from "constants/requestTypes";
import { getIn } from "utils/ramda";
import * as types from "constants/actionTypes";
import { productsNormalizr, productNormalizr } from "schemas";

import { mergeEntities } from "../entities";
import setRequestInProcess from "../request";

// export function fetchShopProductsCollection(accountId, id, params = {}) {
//   const requestType = requestTypes.SHOP_PRODUCTS_COLLECTION_FETCH;
//   return (dispatch) => {
//     dispatch(setRequestInProcess(true, requestType));
//     ProductsApiAdapter.getShopProductsCollectionApiAdapter(accountId, id, params).then((res) => {
//       dispatch(fetchShopProductsCollectionSuccess({ meta: res.meta, data: res.data, params }));
//       dispatch(setRequestInProcess(false, requestType));
//     });
//   };
// }
// case actionTypes.SHOP_PRODUCTS_COLLECTION_FETCH_SUCCESS:
//       return {
//         ...state,
//         productsShop: {
//           meta: action.payload.meta,
//           data: action.payload.data,
//           params: { ...state.productsShop.params, ...action.payload.params },
//         },
//       };

export function fetchProductsCollection(accountId, id, params = {}) {
  const requestType = requestTypes.SHOP_PRODUCTS_COLLECTION_FETCH;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    ProductsApiAdapter.getProductsCollectionApiAdapter(accountId, id, params).then((res) => {
      if (res.data) {
        const { entities, result } = productsNormalizr(res.data);
        dispatch(mergeEntities(entities));
        dispatch(fetchProductsCollectionSuccess(result));
        dispatch(fetchShopProductsCollectionSuccess({ meta: res.meta, data: result, params }));
      } else {
        dispatch(fetchShopProductsCollectionSuccess({ meta: res.meta, data: [], params }));
      }

      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchProductsRecommended(accountId) {
  const requestType = requestTypes.PRODUCTS_COLLECTION_RECOMMENDED_FETCH;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    ProductsApiAdapter.getProductsCollectionRecommended(accountId).then((res) => {
      const { entities, result } = productsNormalizr(res.data);
      dispatch(mergeEntities(entities));
      dispatch(fetchProductsRecommendedSuccess(result));

      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

// export function fetchProductsWithFilterCollection(accountId, id, params = {}) {
//   const requestType = requestTypes.PRODUCTS_COLLECTION_WITH_FILTTER_FETCH_SUCCESS;
//   return (dispatch) => {
//     dispatch(setRequestInProcess(true, requestType));
//     ProductsApiAdapter.getProductsCollectionApiAdapter(accountId, id, params).then((res) => {
//       const { entities, result } = productsNormalizr(res.data);
//       dispatch(mergeEntities(entities));
//       dispatch(fetchProductsCollectionSuccess(result));
//       dispatch(setRequestInProcess(false, requestType));
//     });
//   };
// }

export function fetchShopProductsLoadCollection(accountId, currentPage) {
  const requestType = requestTypes.SHOP_PRODUCTS_COLLECTION_FETCH;
  return (dispatch, getState) => {
    const params = getState().products.productsShop.params || {};
    dispatch(setRequestInProcess(true, requestType));
    ProductsApiAdapter.getShopProductsLoadCollectionApiAdapter(accountId, currentPage, params).then((res) => {
      const { entities, result } = productsNormalizr(res.data);
      dispatch(mergeEntities(entities));
      dispatch(fetchProductsLoadCollectionSuccess(result));
      dispatch(fetchShopProductsLoadCollectionSuccess(res));
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchStyleProductsLoadCollection(accountId, id, name, currentPage) {
  const requestType = requestTypes.SHOP_PRODUCTS_COLLECTION_FETCH;
  return (dispatch, getState) => {
    const params = getState().products.productsStyle.params || {};
    dispatch(setRequestInProcess(true, requestType));
    ProductsApiAdapter.getStyleProductsLoadCollectionApiAdapter(accountId, id, name, currentPage, params).then(
      (res) => {
        const { entities, result } = productsNormalizr(res.data);
        dispatch(mergeEntities(entities));
        dispatch(fetchStyleProductResultCollectionSuccess(result));
        dispatch(fetchStyleProductsLoadCollectionSuccess(res));
        dispatch(setRequestInProcess(false, requestType));
      },
    );
  };
}

// needed fixed product actions!!!
export function fetchStyleProductsCollection(accountId, id, params = {}, name) {
  const requestType = requestTypes.STYLE_PRODUCTS_COLLECTION_FETCH;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    ProductsApiAdapter.getStyleProductsCollectionApiAdapter(accountId, id, params, name).then((res) => {
      if (res.data) {
        const { entities, result } = productsNormalizr(res.data);
        dispatch(mergeEntities(entities));
        dispatch(fetchStyleProductsCollectionSuccess({ meta: res.meta, data: result, params }));
      } else {
        dispatch(fetchStyleProductsCollectionSuccess({ meta: res.meta, data: [], params }));
      }
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchProduct(accountId, productId) {
  const requestType = requestTypes.PRODUCT_FETCH;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    ProductsApiAdapter.getProductApiAdapter(accountId, productId).then((res) => {
      const { entities } = productNormalizr(res.data);
      dispatch(mergeEntities(entities));
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export const productToFavorites = (accountId, id, isFavorites, product) => async (dispatch) => {
  try {
    if (isFavorites) {
      await ProductsApiAdapter.deleteProductToFavorites(accountId, id);
      const newProduct = { ...product, isFavorite: false };
      const { entities } = productNormalizr(newProduct);
      dispatch(mergeEntities(entities));
    } else {
      await ProductsApiAdapter.postProductToFavorites(accountId, id);
      const newProduct = { ...product, isFavorite: true };
      const { entities } = productNormalizr(newProduct);
      dispatch(mergeEntities(entities));
    }
  } catch (error) {
    if (error) {
      return throwSubmissionError(error);
    }
    return throwSubmissionError({ errors: { message: "Something went wrong" } });
  } finally {
  }
};

function fetchShopProductsLoadCollectionSuccess(payload) {
  return {
    type: types.SHOP_PRODUCTS_COLLECTION_LOAD_FETCH_SUCCESS,
    payload,
  };
}

function fetchStyleProductResultCollectionSuccess(payload) {
  return {
    type: types.STYLE_PRODUCTS_RESULT_COLLECTION_FETCH_SUCCESS,
    payload,
  };
}

function fetchStyleProductsLoadCollectionSuccess(payload) {
  return {
    type: types.STYLE_PRODUCTS_COLLECTION_LOAD_FETCH_SUCCESS,
    payload,
  };
}

function fetchStyleProductsCollectionSuccess(payload) {
  return {
    type: types.STYLE_PRODUCTS_COLLECTION_FETCH_SUCCESS,
    payload,
  };
}

function fetchShopProductsCollectionSuccess(payload) {
  return {
    type: types.SHOP_PRODUCTS_COLLECTION_FETCH_SUCCESS,
    payload,
  };
}

function fetchProductsRecommendedSuccess(payload) {
  return {
    type: types.PRODUCTS_COLLECTION_RECOMMENDED_FETCH_SUCCESS,
    payload,
  };
}

function fetchProductsCollectionSuccess(payload) {
  return {
    type: types.PRODUCTS_COLLECTION_FETCH_SUCCESS,
    payload,
  };
}

function fetchProductsLoadCollectionSuccess(payload) {
  return {
    type: types.PRODUCTS_LOAD_COLLECTION_FETCH_SUCCESS,
    payload,
  };
}
