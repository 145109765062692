import React from "react";
import PropTypes from "prop-types";
import cl from "classnames";

import InfoBlock from "../../InfoBlock";
import styles from "./index.module.sass";
import check from "./image/check.svg";

const propTypes = {};

const defaultProps = {};

function Social({ isInstagram }) {
  const oauthUrl = "/auth/instagram_graph";
  const handleSubmitMessage = (url) => {
    // const response = fetch(url, {
    //   method: "GET",
    // });
    // return response;
    window.location = url;
  };
  return (
    <div className={styles.buttonWrapper}>
      {!isInstagram ? (
        <div className={styles.btn} onClick={() => handleSubmitMessage(oauthUrl)}>
          Instagram Connect
        </div>
      ) : (
        <div className={cl(styles.btn, styles.btnActive)}>
          <p>Instagram connected</p>
          <img src={check} alt="check" />
        </div>
      )}
      <InfoBlock title="It’s your data, take it with you and unlock your preferences whenever you want with Luxlock. Personalized service, not ads. We hate ads!" />
    </div>
  );
}

Social.defaultProps = defaultProps;
Social.propTypes = propTypes;

export default Social;
