import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

const propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  openMenuOnFocus: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  classNamePrefix: "react-select",
  disabled: false,
  openMenuOnFocus: true,
  options: [],
  value: undefined,
};

function Select({
  options,
  openMenuOnFocus,
  onChange,
  name,
  value,
  classNamePrefix,
  disabled,
  smInput,
  foot,
  inch,
  ...rest
}) {
  const customStyles = {
    container: (base, state) => ({
      // ...base,
      transition: "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
      "&:hover": {
        boxShadow: "0 2px 4px 0 rgba(41, 56, 78, 0.1)",
      },
    }),
    control: (base, state) => ({
      // ...base,
      display: "flex",
      border: "1px solid #c3c1c1",
      borderTopLeftRadius: foot ? "4px" : 0,
      borderBottomLeftRadius: foot ? "4px" : 0,
      borderTopRightRadius: inch ? "4px" : 0,
      borderBottomRightRadius: inch ? "4px" : 0,
    }),
    valueContainer: (base, state) => ({
      // ...base,
      width: "60px",
      padding: "0 10px 0 10px !important",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      padding: "4px",
    }),
  };

  return (
    <ReactSelect
      name={name}
      options={options}
      isDisabled={disabled}
      value={value}
      onChange={onChange}
      openMenuOnFocus={openMenuOnFocus}
      classNamePrefix={classNamePrefix}
      styles={smInput && customStyles}
      {...rest}
    />
  );
}

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
