import { createSelector } from "reselect";

import { always, identity, propOr, find, propEq, includes } from "ramda";
import { entityDetailGetter, entityListGetter } from "utils/entities";
import { getEntities } from "../entities";

export const initialState = {
  ids: [],
};

function shoppersSelector(state) {
  return state.shoppers.ids || initialState.ids;
}

export const getShoppersIds = createSelector(shoppersSelector, identity);

export const getShoppersDetail = createSelector([always("shoppers"), getShoppersIds, getEntities], entityListGetter);

export const getShopperDetail = (state = initialState, id) => entityDetailGetter("shoppers", id, state.entities);
