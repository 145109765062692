import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import * as actions from "actions";
import { withRouter, NavLink } from "react-router-dom";
import { without } from "ramda";

import { getAccountsCollection } from "selectors/accounts";
import useOnClickOutside from "hooks/useOnClickOutside";
import { CHOOSE_ACCOUNT_PATH } from "constants/routes";
import { active, pause, arrowWhite } from "../Image";
import styles from "../../header.module.sass";

const propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  me: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    marketingOptIn: PropTypes.bool,
    powerBiUrl: PropTypes.string,
  }),
};
const defaultProps = {
  accountId: null,
  me: {},
};

function DropDown(props) {
  const {
    accountId,
    accountsCollection,
    fetchAccountsCollection,
    removeAccountState,
    toggleConsultantStatus,
    me,
    match,
    isPaused,
  } = props;

  useEffect(() => {
    fetchAccountsCollection();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  function showDropDown() {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  }

  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  const [isActive, setIsActive] = useState(true);

  const handleDropDownList = cx({
    [`${styles.dropDownList}`]: true,
    [`${styles.dropDownListActive}`]: isOpen,
  });

  const dropDownArrowClasses = cx({
    [`${styles.dropDownArrow}`]: true,
    [`${styles.dropDownArrowRotate}`]: isOpen,
  });

  function handleLogout() {
    removeAccountState();
    const AccountIds = JSON.parse(localStorage.getItem(`OpenAccounts:`));
    const valuesToRemove = [accountId];
    const filteredItems = without(valuesToRemove, AccountIds);
    localStorage.setItem(`OpenAccounts:`, JSON.stringify(filteredItems));
  }

  return (
    <div ref={ref} onClick={showDropDown} className={styles.dropDown}>
      <div className={styles.status}>
        {isPaused ? (
          <>
            <img className={styles.dropDownImg} src={pause} alt="pause" />
            <p>Pause</p>
          </>
        ) : (
          <>
            <img className={styles.dropDownImg} src={active} alt="active" />
            <p>Active</p>
          </>
        )}
        <img className={dropDownArrowClasses} src={arrowWhite} alt="arrow" />
      </div>
      <ul className={handleDropDownList}>
        {isPaused ? (
          <li className={styles.dropDownItem} onClick={() => toggleConsultantStatus(accountId, false)}>
            Unpause
          </li>
        ) : (
          <li className={styles.dropDownItem} onClick={() => toggleConsultantStatus(accountId, true)}>
            Pause
          </li>
        )}
        {accountsCollection && accountsCollection.data && accountsCollection.data.length > 0 && (
          <li className={styles.dropDownItem}>
            <a className={styles.dropDownLink} target="_blank" href="/">
              Switch Room
            </a>
          </li>
        )}
        {me.powerBiUrl && (
          <li className={styles.dropDownItem}>
            <a className={styles.dropDownLink} target="_blank" href={me.powerBiUrl}>
              Statistics
            </a>
          </li>
        )}
        <li data-name="buttonLogout" className={styles.dropDownItem} onClick={() => handleLogout()}>
          Logout
        </li>
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    accountsCollection: getAccountsCollection(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchAccountsCollection: () => dispatch(actions.fetchAccountsCollection()),
  removeAccountState: () => dispatch(actions.removeAccountState()),
  toggleConsultantStatus: (accountId, stylistStatus) =>
    dispatch(actions.toggleConsultantStatus(accountId, stylistStatus)),
});

connect(mapStateToProps, mapDispatchToProps);

DropDown.propTypes = propTypes;
DropDown.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DropDown));
