import React from "react";
import PropTypes from "prop-types";

import ClaimClientForm from "./Form";
import ClaimBulkForm from "./BulkForm";

import styles from "./index.module.sass";

const propTypes = {};
const defaultProps = {};

function Appearance() {
  return (
    <div className="contentWrapper">
      <div className="container">
        <div className={styles.wrap}>
          <div className={styles.wrapContent}>
            <h3 className={styles.wrapTitle}>Claim your client to ensure you’re preassigned as the primary contact.</h3>
            <ClaimClientForm />
          </div>

          <div className={styles.wrapContent}>
            <h3 className={styles.wrapTitle}>Bulk upload for claim your client.</h3>
            <a href="/bulk_upload_example.xlsx" target="_blank" className={styles.wrapLink}>
              Click here to download a template.
            </a>
            <ClaimBulkForm />
          </div>
        </div>
      </div>
    </div>
  );
}

Appearance.propTypes = propTypes;
Appearance.defaultProps = defaultProps;

export default Appearance;
