import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import useOnClickOutside from "hooks/useOnClickOutside";
import { hexLogo, shopperHomeImg } from "./shared/img";
import shopper from "./style.module.sass";

const propTypes = {};
const defaultProps = {};

function Home() {
  const ref = useRef();
  const [isModalOpen, setModalOpen] = useState(false);
  useOnClickOutside(ref, () => setModalOpen(false));

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      document.getElementById("video-bg").innerHTML =
        '<video class="video" playsinline width="100%" height="auto" preload="auto" loop autoPlay="autoplay" poster={shopperHomeImg}><source src="https://static.luxlock.com/shopperHome.mov" type="video/mp4"></source> </video>';
    }
  }, []);

  return (
    <>
      <div className="contentWrapper">
        <div id="video-bg" className="video-bg">
          <div className="mobileBg" />
        </div>
        <div className={shopper.promo}>
          <h1>guilty pleasure</h1>
          <p className={shopper.promoParagraph}>It's personal, not just shopping.</p>
          {isModalOpen && (
            <div className={shopper.promoLetter} ref={ref}>
              <img className={shopper.promoLetterImg} src={hexLogo} alt="logo" />
              <h3 className={shopper.promoLetterTitle}>Letter from the Founder</h3>
              <p className={shopper.promoLetterParagraph}>Hang tight, coming soon</p>
              {/* <br />
              <p className={shopper.promoLetterParagraph}>Passionately yours,</p>
              <p className={shopper.promoSignatire}>Casey</p>
              <p className={shopper.promoLetterParagraph}>Casey Golden, Founder & CEO</p> */}
            </div>
          )}
          <button className={shopper.promoBtn} onClick={() => setModalOpen(true)}>
            Letter from the Founder
          </button>
        </div>
      </div>
    </>
  );
}

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;

export default Home;
