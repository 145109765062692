import React from "react";
import PropTypes from "prop-types";
import { toast as toastify } from "react-toastify";
import ToastTemplate from "./ToastTemplate";

import { check, error } from "./img";
import styles from "./index.module.sass";

const propTypes = {};

const defaultProps = {};

function PushToast({ ...props }, config) {
  const { type } = props;
  if (type === "error") {
    return toastify.error(
      <div className={styles.wrapper}>
        {/* <img className={styles.imgIcon} src={error} alt="check" /> */}
        <ToastTemplate {...props} />
      </div>,
      { ...config },
    );
  }
  return toastify(
    <div className={styles.wrapper}>
      <img className={styles.imgIcon} src={check} alt="check" />
      <ToastTemplate {...props} />
    </div>,
    { ...config },
  );
}
PushToast.defaultProps = defaultProps;
PushToast.propTypes = propTypes;

export default PushToast;
