import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import cl from "classnames";

import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import ReactSelect from "components/shared/formDefault/ReactSelect";

// import styles from "./index.module.sass";

const propTypes = {
  references: PropTypes.shape({
    sizingInfo: PropTypes.shape({
      womenswear: PropTypes.shape({
        jean: PropTypes.arrayOf(),
      }),
    }),
  }),
};

const defaultProps = {
  references: {
    sizingInfo: {
      womenswear: {
        jean: [],
      },
    },
  },
};

function WomenswearMeasurements({ references }) {
  function getOptions(value) {
    const options =
      value &&
      value.map((item) => {
        return { value: item, label: item };
      });
    return options;
  }

  return (
    <>
      <Field
        label="Bust"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.bust"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.bust)}
      />
      <Field
        label="Waist"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.waist"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.waist)}
      />
      <Field
        label="Inseam"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.inseam"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.inseam)}
      />
      <Field
        label="Hips"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.hip"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.hip)}
      />
      <Field
        label="Rise"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.rise"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.rise)}
      />
      <Field
        label="Upper Bust"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.upperBust"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.upperBust)}
      />
      <Field
        label="Under Bust"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.underBust"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.underBust)}
      />
      <Field
        label="High Hip"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.highHip"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.highHip)}
      />
      <Field
        label="Sleeve Length"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.sleeveLength"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.sleeveLength)}
      />
      <Field
        label="Calf"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.calf"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.calf)}
      />
      <Field
        label="Shoulder"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.shoulder"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.shoulder)}
      />
      <Field
        label="Arm Length"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.armLength"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.armLength)}
      />
      {/* <Field
        label="Bicep"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.bicep"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.bicep)}
      /> */}
      <Field
        label="Wrist"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.wrist"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswearMeasurements.wrist)}
      />
    </>
  );
}

WomenswearMeasurements.defaultProps = defaultProps;
WomenswearMeasurements.propTypes = propTypes;

export default WomenswearMeasurements;
