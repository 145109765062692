import { createSelector } from "reselect";
import { identity } from "ramda";

export const initialState = {};

export function sessionSelector(state) {
  return state.session || initialState;
}

export const getSessionStore = createSelector(sessionSelector, identity);
export const getSessionUser = createSelector(getSessionStore, (session) => session.user);
export const getSessionMe = createSelector(getSessionStore, (session) => session.me);
export const getIsAuthorized = createSelector(getSessionStore, (session) => session.isAuthorized);
