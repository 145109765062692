import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import * as routes from "constants/routes";

import { useAuthenticatedStatus } from "utils/auth";
import { getFromUrl } from "utils/routes";

import { ChooseCloset } from "../index";
import DashboardRouter from "./dashboard";
import PrivateRoute from "./privateRoute";
import ShopperRouter from "./shopper";
import Auth from "../Auth";
import NotFoundPage from "../NotFoundPage";

function RootRouter(props) {
  const isAuthenticated = useAuthenticatedStatus();
  sessionStorage.setItem("redirectUrl", getFromUrl(props.location));

  return (
    <Switch>
      <PrivateRoute path={routes.CHOOSE_ACCOUNT_PATH} component={ChooseCloset} exact />
      <PrivateRoute path={routes.DASHBOARD_SHOPPER_HOME_PATH} component={ShopperRouter} />
      <PrivateRoute path={routes.DASHBOARD_SHOPPER_PROFILE_PATH} component={ShopperRouter} />
      <PrivateRoute path={routes.AUTH_INSTAGRAM_CONNECT_PATH} component={ShopperRouter} />
      <PrivateRoute path={routes.DASHBOARD_ACCOUNT_PATH} component={DashboardRouter} />

      <Route
        path={routes.DASHBOARD_AUTH_PATH}
        render={({ location, ...props }) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: routes.CHOOSE_ACCOUNT_PATH,
                state: { from: location },
              }}
            />
          ) : (
            <Auth {...props} />
          )
        }
      />
      {/* <Route path="/accounts" component={DashboardRouter} /> */}
      <Route component={NotFoundPage} />
    </Switch>
  );
}

export default withRouter(RootRouter);
