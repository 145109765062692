// import * as R from "ramda";
// import { without } from "ramda";
import { without } from "ramda";
import * as actionTypes from "constants/actionTypes";
import { initialState } from "selectors/shoppers";

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_SHOPPERS_FETCH_SUCCESS:
      return {
        ...state,
        ids: action.payload,
      };
    case actionTypes.ACCOUNT_SHOPPER_REMOVE_SUCCESS:
      return {
        ...state,
        ids: without(action.payload, state.ids),
      };
    case actionTypes.ACCOUNTS_REMOVE_STATE_SUCCESS:
      return {};
    default:
      return state;
  }
};
