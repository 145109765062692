// import { stringify } from 'qs';
import { stringify } from "query-string";
import { pipe, then, includes, merge } from "ramda";
import { getSessionData, setSessionData, removeSessionData } from "utils/sessionData";
import SessionApiAdapter from "../session";
import { camelizeKeys } from "utils/humps";
import { getIn, setIn } from "utils/ramda";
import refreshFetch from "./refreshFetch";
// import { internalServerErrorNotification } from '../utils/notifications';

function parseJSON(res) {
  return res.json();
}

function toJSON(res) {
  return pipe(parseJSON, then(camelizeKeys))(res);
}

function checkStatus(res) {
  const statusCode = res.status;
  const isOk = getIn("ok", res);

  const throwError = pipe(
    toJSON,
    then(setIn(statusCode, "statusCode")),
    then((error) => {
      throw error;
    }),
  );

  // if (res.status === 500) internalServerErrorNotification();

  if (isOk) return res;

  return throwError(res);
}

function checkType(res) {
  const contentType = res.headers.get("content-type");
  const isJSON = contentType && includes("application/json", contentType);

  if (isJSON) return toJSON(res);
  return res;
}

const refreshSessionData = () => {
  const { refreshToken } = getSessionData();
  return SessionApiAdapter.postRefreshToken({
    grant_type: "refresh_token",
    client_id: process.env.CLIENT_ID,
    client_secret: process.env.CLIENT_SECRET,
    refresh_token: refreshToken,
  })
    .then(({ data }) => {
      setSessionData(data);
    })
    .catch((error) => {
      removeSessionData();
      throw error;
    });
};

function responseHandler(res) {
  return pipe(then(checkStatus), then(checkType))(res);
}

function getQuerystring(params) {
  const queryString = stringify(params, { encode: false, arrayFormat: "brackets", skipNulls: true });
  return queryString ? `?${queryString}` : "";
}

function parseEndpoint(path, params) {
  return `${path}${getQuerystring(params)}`;
}

export function cfetchAuth(endpoint, request, params) {
  const { AUTH_URL } = process.env;

  const response = fetch(parseEndpoint(`${AUTH_URL}${endpoint}`, params), request);
  return responseHandler(response);
}

export function cfetchFormData(endpoint, request, params) {
  const { API_URL } = process.env;
  const { accessToken } = getSessionData();

  const optionsWithToken = merge(request, {
    ...request,
    headers: { Authorization: accessToken && `Bearer ${accessToken}` },
  });

  const response = fetch(parseEndpoint(`${API_URL}${endpoint}`, params), optionsWithToken);

  return responseHandler(response);
}

function cfetch(endpoint, request, params) {
  const { API_URL } = process.env;
  const { accessToken } = getSessionData();

  const optionsWithToken = merge(request, {
    ...request,
    headers: { Authorization: accessToken && `Bearer ${accessToken}`, "Content-type": "application/json" },
  });

  const response = fetch(parseEndpoint(`${API_URL}${endpoint}`, params), optionsWithToken);

  return responseHandler(response);
}

export function urlFetch(endpoint, request, params) {
  const { DASHBOARD_URL } = process.env;
  const { accessToken } = getSessionData();

  const optionsWithToken = merge(request, {
    ...request,
    headers: { Authorization: accessToken && `Bearer ${accessToken}` },
  });

  const response = fetch(parseEndpoint(`${DASHBOARD_URL}${endpoint}`, params), optionsWithToken);

  return responseHandler(response);
}

const refreshCfetch = refreshFetch({
  fetch: cfetch,
  refreshSessionData,
});

export default refreshCfetch;
