import React from "react";

import { DASHBOARD_AUTH_REGISTRATION_PATH, DASHBOARD_AUTH_RESTORE_PASSWORD_PATH } from "constants/routes";

import SignInForm from "./shared/Form";
import NavigateLink from "../shared/NavigateLink";
import styles from "./index.module.sass";

function SignIn() {
  return (
    <div className={styles.formWrapper}>
      <SignInForm />
      <NavigateLink to={DASHBOARD_AUTH_RESTORE_PASSWORD_PATH}>Forgot password</NavigateLink>
      <NavigateLink signUpButton to={DASHBOARD_AUTH_REGISTRATION_PATH}>
        Registration
      </NavigateLink>
    </div>
  );
}

export default SignIn;
