import { DASHBOARD_SHOPPER_HOME_PATH } from "constants/routes";
import { getIn } from "utils/ramda";

export function getPath(path, params) {
  let result = path;
  Object.keys(params).forEach((key) => {
    result = result.replace(`:${key}`, params[key]);
  });
  return result;
}

export function getFromUrl(location) {
  return getIn("state.from.pathname", location) || DASHBOARD_SHOPPER_HOME_PATH;
}
