import React, { useEffect } from "react";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";
import * as actions from "actions";
import { useLocation } from "react-router";
import * as requestTypes from "constants/requestTypes";
import { requestInProcess } from "selectors/request";

import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import Input from "components/shared/formDefault/Input";
import InputPassword from "components/shared/formDefault/InputPassword";
import Button from "components/shared/formDefault/Button";
import { validateRequired } from "components/shared/formDefault/validations";

import styles from "./index.module.sass";

const defaultProps = {};

const propTypes = {
  postCredentials: PropTypes.func.isRequired,
};

function UpdatePasswordForm({ postNewPassword, checkToken, isLoadingShoppers }) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const token = query.get("token");

  useEffect(() => {
    checkToken(token);
  }, []);

  function submitNewPassword(props) {
    return postNewPassword(props, token);
  }

  if (isLoadingShoppers) {
    return null;
  }

  return (
    <Form
      onSubmit={(props) => {
        return submitNewPassword(props);
      }}
      render={({ handleSubmit, hasSubmitErrors, submitError, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <div className={styles.fieldsCollection}>
            <Field
              component={FormGroupAdapter}
              inputComponent={InputPassword}
              name="password"
              help=""
              placeholder="Password"
              type="password"
              autoFocus
              validate={validateRequired()}
            />

            <Field
              component={FormGroupAdapter}
              inputComponent={InputPassword}
              name="passwordConfirmation"
              help=""
              placeholder="Password Confirmation"
              type="password"
              validate={validateRequired()}
            />

            {hasSubmitErrors && submitError && (
              <div className={styles.errorMessageContainer}>
                <span className={styles.errorMessage}>{submitError}</span>
              </div>
            )}
          </div>

          <Button type="submit" disable={submitting} auth>
            {submitting ? "Submitting..." : "Submit"}
          </Button>
        </form>
      )}
    />
  );
}

function mapStateToProps(state) {
  return {
    isLoadingShoppers: requestInProcess(state, { requestType: requestTypes.PASSWORD_TOKEN }),
  };
}

const mapDispatchToProps = (dispatch) => ({
  postNewPassword: (userData, token) => dispatch(actions.postNewPassword(userData, token)),
  checkToken: (token) => dispatch(actions.checkToken(token)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

UpdatePasswordForm.defaultProps = defaultProps;
UpdatePasswordForm.propTypes = propTypes;

export default enhance(UpdatePasswordForm);
