import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.sass";

const propTypes = {};

const defaultProps = {};

function FormBlock({ children }) {
  return <div className={styles.formBlock}>{children}</div>;
}

FormBlock.defaultProps = defaultProps;
FormBlock.propTypes = propTypes;

export default FormBlock;
