import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./index.module.sass";

function NoStaff() {
  return (
    <div className={styles.noStaffContainer}>
      <span className={styles.noStaffText}>
        No staff,{" "}
        <NavLink to="#" className={styles.noStaffLink}>
          add new User
        </NavLink>{" "}
      </span>
    </div>
  );
}

export default NoStaff;
