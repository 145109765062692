import { createSelector } from "reselect";
import { identity, prop, propOr, includes, isNil, complement, values } from "ramda";

import {
  entityGetter,
  entityKeysGetter,
  entityDetailGetter,
  entityListGetter,
  entityFieldGetter,
  entityActualFieldGetter,
} from "../../utils/entities";

export const initialState = {};

function entitiesSelector(state) {
  return state.entities || initialState;
}

function entityNameSelector(state, options) {
  return prop("entityName", options);
}

function entityIdSelector(state, options) {
  return prop("entityId", options);
}

function entityIdsSelector(state, options) {
  return prop("entityIds", options);
}

function entityFieldSelector(state, options) {
  return prop("entityField", options);
}

export const getEntities = createSelector(entitiesSelector, identity);

export const getEntity = createSelector([entityNameSelector, getEntities], entityGetter);

export const getEntityKeys = createSelector([entityNameSelector, getEntities], entityKeysGetter);

export const getEntityDetail = createSelector([entityNameSelector, entityIdSelector, getEntities], entityDetailGetter);

export const getEntityList = createSelector([entityNameSelector, entityIdsSelector, getEntities], entityListGetter);

export const getEntityFullList = createSelector([entityNameSelector, getEntityKeys, getEntities], entityListGetter);

export const getEntityValues = createSelector(getEntity, values);

// Fields
export const getEntityField = createSelector([entityFieldSelector, getEntityDetail], entityFieldGetter);
export const getEntityActualField = createSelector([entityFieldSelector, getEntityDetail], entityActualFieldGetter);
