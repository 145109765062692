import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.sass";

const propTypes = {
  isSimple: PropTypes.bool,
};

const defaultProps = {
  isSimple: false,
};

function SelectedItems({ items, handleDelete, isSimple }) {
  if (isSimple) {
    return (
      <div>
        {items.map((label, index) => (
          <div key={index} className={styles.selectedItemsWrapper}>
            <span>{label}</span>
            <div onClick={() => handleDelete(label)} className={styles.closeWrapper}>
              <div className={styles.close} />
            </div>
          </div>
        ))}
      </div>
    );
  }
  return (
    <div>
      {items.map((item, index) => (
        <>
          {item && (
            <div key={index} className={styles.selectedItemsWrapper}>
              <span>{item.label}</span>
              <div onClick={() => handleDelete(item.value)} className={styles.closeWrapper}>
                <div className={styles.close} />
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
}

SelectedItems.defaultProps = defaultProps;
SelectedItems.propTypes = propTypes;

export default SelectedItems;
