import { without, uniq, uniqBy } from "ramda";

import * as actionTypes from "constants/actionTypes";
import { initialState } from "selectors/products";

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOP_PRODUCTS_COLLECTION_FETCH_SUCCESS:
      return {
        ...state,
        ids: action.payload.data,
        productsShop: {
          ids: action.payload.data,
          meta: action.payload.meta,
          params: { ...state.productsShop.params, ...action.payload.params },
        },
      };

    case actionTypes.PRODUCTS_COLLECTION_RECOMMENDED_FETCH_SUCCESS:
      return {
        ...state,
        productsRecommended: {
          ids: [...action.payload],
        },
      };

    case actionTypes.STYLE_PRODUCTS_COLLECTION_FETCH_SUCCESS:
      return {
        ...state,
        productsStyle: {
          ids: action.payload.data,
          meta: action.payload.meta,
          params: { ...state.productsShop.params, ...action.payload.params },
        },
      };

    case actionTypes.STYLE_PRODUCTS_RESULT_COLLECTION_FETCH_SUCCESS:
      return {
        ...state,
        productsStyle: {
          ids: [...state.productsStyle.ids, ...action.payload],
        },
      };

    case actionTypes.STYLE_PRODUCTS_COLLECTION_LOAD_FETCH_SUCCESS:
      return {
        ...state,
        productsStyle: {
          ids: [...state.productsStyle.ids],
          meta: action.payload.meta,
          params: { ...state.productsShop.params, ...action.payload.params },
        },
      };

    case actionTypes.SHOP_PRODUCTS_COLLECTION_LOAD_FETCH_SUCCESS:
      return {
        ...state,
        productsShop: {
          ids: [...state.productsShop.ids, ...action.payload.data],
          meta: action.payload.meta,
          params: { ...state.productsShop.params, ...action.payload.params },
        },
      };

    case actionTypes.ACCOUNTS_ADD_PRODUCT_TO_EDITOR_SUCCESS:
      return {
        ...state,
        productsEditor: {
          ids: [...state.productsEditor.ids, action.payload],
        },
      };

    case actionTypes.ACCOUNTS_ADD_PRODUCT_OF_STORAGE_TO_EDITOR_SUCCESS:
      return {
        ...state,
        productsEditor: {
          ids: action.payload,
        },
      };

    case actionTypes.ACCOUNTS_ADD_PRODUCT_OF_STORAGE_TO_FAVORITES_SUCCESS:
      return {
        ...state,
        productsFavorites: {
          ids: action.payload,
        },
      };

    case actionTypes.ACCOUNTS_REMOVE_PRODUCT_TO_EDITOR_SUCCESS:
      return {
        ...state,
        productsEditor: {
          ids: without(action.payload, state.productsEditor.ids),
        },
      };

    case actionTypes.ACCOUNTS_ADD_PRODUCT_TO_FAVORITES_SUCCESS:
      return {
        ...state,
        productsFavorites: {
          ids: [...state.productsFavorites.ids, action.payload],
        },
      };

    case actionTypes.ACCOUNTS_REMOVE_PRODUCT_TO_FAVORITES_SUCCESS:
      return {
        ...state,
        productsFavorites: {
          ids: without(action.payload, state.productsFavorites.ids),
        },
      };

    case actionTypes.PRODUCT_FETCH_SUCCESS:
      return {
        ...state,
        productMessages: uniq([...state.productMessages, action.payload.data]),
      };

    case actionTypes.PRODUCTS_COLLECTION_FETCH_SUCCESS:
      return {
        ...state,
        ids: [...state.ids, ...action.payload],
      };

    case actionTypes.PRODUCTS_LOAD_COLLECTION_FETCH_SUCCESS:
      return {
        ...state,
        ids: [...state.ids, ...action.payload],
      };
    case actionTypes.ACCOUNTS_REMOVE_STATE_SUCCESS:
      return {};

    default:
      return state;
  }
};
