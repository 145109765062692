import React from "react";
import classNames from "classnames";

import BaseCheckbox from "../../formBase/Checkbox";
import styles from "./index.module.sass";

function RadioButton({ className, children, name, isError, checked, ...props }) {
  return (
    <label className={classNames(styles.checkboxLable)}>
      <BaseCheckbox name={name} className={styles.checkbox} {...props} />
      {/* <div active={props.checked} isError={isError} /> */}
      <div className={styles.label}>{children}</div>
    </label>
  );
}

export default RadioButton;
