import qs from "query-string";
import { getIn as finalGetIn, setIn as finalSetIn } from "final-form";
import * as R from "ramda";

import { camelizeKeys } from "./humps";

export const compact = R.filter(R.identity);

export const capitalize = R.compose(R.toUpper, R.head);
export const lowercaseTail = R.compose(R.toLower, R.tail);
export const toTitle = R.converge(R.concat, [capitalize, lowercaseTail]);

export const setIn = R.curry((value, key, state) => finalSetIn(state, key, value));
export const getIn = R.curry((complexKey, state) => finalGetIn(state, complexKey));

export const rSetCopyIn = R.curry((from, to, state) => {
  const value = getIn(from, state);
  return setIn(value, to, state);
});

export const rGetSearchParams = R.pipe(R.path(["location", "search"]), qs.parse, camelizeKeys);

export const toggleValueFromArray = (values, items = []) => {
  if (R.contains(values, items)) {
    return R.without(values, items);
  }
  return R.append(values, items);
};

export const argsToArray = R.unapply(R.identity);

export const updateEditableKeys = (key, name, collection) => {
  const fieldLens = R.lensPath([key, name]);

  return R.view(fieldLens, collection) ? R.set(fieldLens, false, collection) : R.set(fieldLens, true, collection);
};
