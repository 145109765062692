import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.sass";

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isSeparator: PropTypes.bool,
};

const defaultProps = {
  title: null,
  subtitle: null,
  isSeparator: true,
  children: [{}],
};

function SetupBlock({ title, subtitle, children, isSeparator }) {
  return (
    <div className={styles.SetupBlock}>
      <div className={classNames(styles.SetupBlockContent, isSeparator && styles.separator)}>
        {title && (
          <div className={styles.SetupBlockDescription}>
            <h2 className={styles.SetupBlockTitle}>{title}</h2>
            {subtitle && <span className={styles.SetupBlockSubtitle}>{subtitle}</span>}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

SetupBlock.defaultProps = defaultProps;
SetupBlock.propTypes = propTypes;

export default SetupBlock;
