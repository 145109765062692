import React from "react";

import styles from "./index.module.sass";
import UpdatePasswordForm from "./shared/Form";

function RestorePasswordPage() {
  return (
    <div className={styles.formWrapper}>
      <UpdatePasswordForm />
    </div>
  );
}

export default RestorePasswordPage;
