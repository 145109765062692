import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import cl from "classnames";
import * as actions from "actions";
import { pipe, map, includes, prop, reverse } from "ramda";
import { connect } from "react-redux";
import { getIn } from "utils/ramda";
import { PROFILE_DASHBOARD_ACCOUNT_SHOPPER_PATH, getPath } from "constants/routes";
import { getSessionUser } from "selectors/session";

import { defaultAvatar, envelope, plus, minus } from "../Contact/shared/Image";
import chat from "../chat.module.sass";

const propTypes = {
  shoppers: PropTypes.arrayOf(PropTypes.object),
  accountId: PropTypes.string,
  user: PropTypes.shape({
    bandwidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};
const defaultProps = {
  shoppers: [],
  accountId: "",
  user: {
    bandwidth: "",
  },
};

function ContactList(props) {
  const {
    accountId,
    shoppers,
    setIsNewShopperId,
    isNewShopperId,
    countMessages,
    removeCountMessage,
    fetchMyProfile,
    consultantBandWidth,
    postNewConsultantBandWidth,
    user,
    isGuest,
  } = props;

  const { bandwidth } = user;

  // FIX DUBLICATE !!!
  const initials = (value) => {
    const name = value ? value.match(/\b\w/g) : [];
    return (((name && name.shift()) || "") + ((name && name.pop()) || "")).toUpperCase();
  };

  // const bandwidth = getIn("bandwidth", consultantBandWidth);
  const handleCount = (param) => {
    if (param === "plus") {
      const newBandwidth = bandwidth < 15 ? bandwidth + 1 : bandwidth;
      postNewConsultantBandWidth(accountId, newBandwidth);
    } else {
      const newBandwidth = bandwidth > 0 ? bandwidth - 1 : bandwidth;
      postNewConsultantBandWidth(accountId, newBandwidth);
    }
  };

  useEffect(() => {
    fetchMyProfile(accountId);
  }, []);

  const hanleRemoveMessageCount = (id) => {
    // setNewMessageCount(newMessageCount.filter((item) => item.id !== id));
    removeCountMessage(countMessages.filter((item) => item.id !== id));
  };

  return (
    <div className={chat.contacts}>
      <ul className={chat.contactList}>
        {shoppers &&
          shoppers.map((shopper) => (
            <li key={shopper.id} className={chat.item}>
              {shopper && (
                <NavLink
                  className={chat.contactLink}
                  isActive={() => {
                    if (location.pathname.match(`/accounts/${accountId}/shoppers/${shopper.id}`)) return true;
                  }}
                  activeClassName={chat.contactLinkActive}
                  to={getPath(PROFILE_DASHBOARD_ACCOUNT_SHOPPER_PATH, { accountId, id: shopper.id })}
                  onClick={() => {
                    // FIXXXX!!
                    const isIncludes = pipe(map(prop("id")), includes(shopper.id))(countMessages);
                    if (isNewShopperId === shopper.id) return setIsNewShopperId("");
                    if (isIncludes) return hanleRemoveMessageCount(shopper.id);
                  }}
                >
                  <span className={chat.roundingTop} />
                  <div className={chat.contactAvatar}>
                    {shopper.isGuest ? (
                      <img src={defaultAvatar} data-name="chatAvatar" alt="avatar" />
                    ) : (
                      <>
                        {shopper.avatar ? (
                          <img src={shopper.avatar} data-name="chatAvatar" alt="avatar" />
                        ) : (
                          <span className={chat.contactInitials} data-name="chatAvatar">
                            {initials(shopper.firstName) + initials(shopper.lastName)}
                          </span>
                        )}
                      </>
                    )}
                    {isNewShopperId === shopper.id && <span className={chat.newContact}>New</span>}
                    {countMessages.map(
                      (shopperCount) => shopperCount.id === shopper.id && <span className={chat.contactCount} />,
                    )}
                  </div>
                  <span className="shopperName">{shopper.firstName}</span>
                  <span className={chat.roundingBottom} />
                </NavLink>
              )}
            </li>
          ))}
      </ul>
      <div className={chat.functions}>
        <div className={cl(`${chat.functionsItem}`)}>
          <p>CLIENT BANDWIDTH</p>
          <div className={chat.functionsCounter}>
            <img src={minus} onClick={() => handleCount("minus")} alt="minus" />
            {/* <span>{count === 0 ? shoppers.length : count}</span> */}
            <span>{bandwidth}</span>
            <img src={plus} onClick={() => handleCount("plus")} alt="plus" />
          </div>
        </div>
        {/* <div className={cl(`${chat.functionsItem}`)}>
          <p>PROACTIVE MESSAGE</p>
          <img src={envelope} alt="message" />
        </div> */}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: getSessionUser(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchMyProfile: (accountId) => dispatch(actions.fetchMyProfile(accountId)),
  postNewConsultantBandWidth: (accountId, id, isMessage) =>
    dispatch(actions.postNewConsultantBandWidth(accountId, id, isMessage)),
  removeCountMessage: (ids) => dispatch(actions.removeCountMessage(ids)),
});

ContactList.propTypes = propTypes;
ContactList.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
