import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.sass";

const propTypes = {
  role: PropTypes.string,
};

const defaultProps = {
  role: "---",
};

function StaffRole({ role }) {
  return <span>{role ? role : "---"}</span>;
}

StaffRole.defaultProps = defaultProps;
StaffRole.propTypes = propTypes;

export default StaffRole;
