import Cookies from "js-cookie";
import { history } from "stores/configureStore";
import { DASHBOARD_AUTH_PATH } from "constants/routes";

const SESSION_DATA = "current_session";

export function getSessionData() {
  // const sessionData = sessionStorage.getItem(SESSION_DATA);
  // return sessionData ? JSON.parse(sessionData) : {};
  const token = Cookies.get(SESSION_DATA);
  return token ? JSON.parse(token) : {};
}

export function setSessionData(data) {
  // sessionStorage.setItem(SESSION_DATA, JSON.stringify(data));
  Cookies.set(SESSION_DATA, JSON.stringify(data), { expires: 1 });
}

export function removeSessionData() {
  sessionStorage.removeItem("redirectUrl");
  Cookies.remove(SESSION_DATA);
}

export function logout() {
  removeSessionData();
  // window.location.reload();
  history.push(DASHBOARD_AUTH_PATH);
}
