import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./index.module.sass";

const propTypes = {
  tags: PropTypes.arrayOf(),
};

const defaultProps = {
  tags: [],
};

function TagsInput(props) {
  const { tags, handleDeleteHobbie } = props;
  const [newTags, setTags] = useState(tags);
  const removeTags = (indexToRemove) => {
    setTags([...newTags.filter((_, index) => index !== indexToRemove)]);
    handleDeleteHobbie([...newTags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (event) => {
    if (event.target.value !== "") {
      setTags([...newTags, event.target.value]);
      props.handleAddHobbie(event.target.value);
      event.target.value = "";
    }
  };

  return (
    <div className={styles.inputTagsWrapper}>
      <div className={styles.fieldWrapper}>
        <span className={styles.label}>{props.label}</span>
        <textarea
          type="text"
          onKeyUp={(event) => {
            event.key === "Enter" ? addTags(event) : null;
          }}
          placeholder={props.placeholder}
          className={styles.inputTags}
        />
      </div>
      <ul className={styles.listTags}>
        {newTags &&
          newTags.map((tag, index) => (
            <li key={index} className={styles.selectedItemsWrapper}>
              <span className="tag-title">{tag}</span>
              <div onClick={() => removeTags(index)} className={styles.closeWrapper}>
                <div className={styles.close} />
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

TagsInput.defaultProps = defaultProps;
TagsInput.propTypes = propTypes;

export default TagsInput;
