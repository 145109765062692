import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  DASHBOARD_ACCOUNT_STAFF_LIST_PATH,
  DASHBOARD_ACCOUNT_STAFF_NEW_PATH,
  DASHBOARD_ACCOUNT_STAFF_PATH,
} from "constants/routes";

import StaffListPage from "./StaffListPage";
import NewStaffForm from "./Forms/NewStaffForm";
import EditStaffForm from "./Forms/EditStaffForm";
import Tabs from "./Tabs";

function Staff() {
  return (
    <div className="contentWrapper">
      <Tabs />
      <div className="container">
        <Switch>
          <Route path={[DASHBOARD_ACCOUNT_STAFF_NEW_PATH]} component={NewStaffForm} exact />
          <Route path={[DASHBOARD_ACCOUNT_STAFF_PATH]} component={EditStaffForm} exact />
          <Route path={DASHBOARD_ACCOUNT_STAFF_LIST_PATH} component={StaffListPage} />
        </Switch>
      </div>
    </div>
  );
}

export default Staff;
