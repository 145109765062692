import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import cl from "classnames";
import { getIn } from "utils/ramda";
import * as actions from "actions";

import { getShopperDetail } from "selectors/shoppers";
import profile from "./profile.module.sass";
import { Body, Header } from "./shared";

const propTypes = {
  setShowNav: PropTypes.func.isRequired,
  user: PropTypes.shape({
    avatar: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    gender: PropTypes.object,
    id: PropTypes.string,
    isGuest: PropTypes.bool,
    jobTitle: PropTypes.string,
    lastName: PropTypes.string,
    location: PropTypes.object,
    maritalStatus: PropTypes.object,
    oauthRecord: PropTypes.object,
    profile: PropTypes.object,
    sizingInfo: PropTypes.object,
    stats: PropTypes.shape({ formattedAov: PropTypes.string, formattedLtv: PropTypes.string }),
    location: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
};
const defaultProps = {
  user: {
    avatar: null,
    email: null,
    firstName: null,
    gender: {},
    id: "",
    isGuest: false,
    jobTitle: null,
    lastName: null,
    location: {},
    maritalStatus: {},
    oauthRecord: {},
    profile: {},
    sizingInfo: {},
    stats: { formattedAov: "0.0", formattedLtv: "0.0" },
    location: {
      city: "",
      country: "",
    },
  },
};

function Profile(props) {
  const {
    setShowNav,
    user,
    match,
    fetchAccountShopper,
    sendInstagramLink,
    setIsProfilePageg,
    fetchInstagramFeed,
  } = props;
  const { city } = user.location;
  const { accountId, id } = match.params;

  const { DASHBOARD_URL } = process.env;

  const getInstagramLink = () => {
    if (process.env.RAILS_ENV === "production") {
      return `Do you want to share your Instagram with me, so I can get a better idea of your style? It’s just between us while we shop. <a class="linkInst" href="https://www.instagram.com/" target="_blank">instagram.com</a>`;
    }

    return `Do you want to share your Instagram with me, so I can get a better idea of your style? It’s just between us while we shop. Follow  <a class="linkInst" href="${DASHBOARD_URL}/shopper/profile" target="_blank">the link</a> to get started.`;
  };

  useEffect(() => {
    setShowNav(true);
    setIsProfilePageg(true);
    fetchAccountShopper(accountId, id);
    fetchInstagramFeed(id);
    return () => (setShowNav(false), setIsProfilePageg(false));
  }, []);

  const onSendInstagramMesage = () => {
    sendInstagramLink(accountId, id, getInstagramLink());
  };

  return (
    <>
      <div className={cl(`container ${profile.background} ${profile.contentRight}`)}>
        <Header user={user} accountId={accountId} />
        <Body onSendInstagramMesage={onSendInstagramMesage} user={user} accountId={accountId} />
      </div>
    </>
  );
}

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

function mapStateToProps(state, props) {
  const id = getIn("match.params.id", props);
  return {
    user: getShopperDetail(state, id),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchAccountShopper: (accountId, id) => dispatch(actions.fetchAccountShopper(accountId, id)),
  sendInstagramLink: (accountId, id, instagramLink) =>
    dispatch(actions.sendInstagramLink(accountId, id, instagramLink)),
  fetchInstagramFeed: (id) => dispatch(actions.fetchInstagramFeed(id)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    // componentDidUpdate(nextProps) {
    //   if (this.props.match.params.id !== nextProps.match.params.id) {
    //     const { fetchAccountChatMessages, match, addMessageToChat } = this.props;
    //     const { accountId, id } = match.params;

    //     fetchAccountChatMessages(accountId, id);
    //     createSocket({ accountId, contactId: id }, addMessageToChat);
    //   }
    // },
    componentDidMount() {},
  }),
);

export default enhance(Profile);
