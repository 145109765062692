import * as actionTypes from "constants/actionTypes";
import { initialState } from "selectors/shopperProfile";

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SHOPPER_PROFILE_FETCH_SUCCESS:
      return {
        data: payload,
      };
    case actionTypes.SHOPPER_PROFILE_PHOTOS_FETCH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          photos: payload,
        },
      };

    case actionTypes.SHOPPER_PROFILE_UPDATE_PHOTOS_FETCH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          photos: payload.data,
        },
      };

    case actionTypes.SHOPPER_PROFILE_REMOVE_PHOTOS_FETCH_SUCCESS:
      const newPhotos = state.data.photos.filter((item) => item.id !== payload);
      return {
        ...state,
        data: {
          ...state.data,
          photos: newPhotos,
        },
      };
    case actionTypes.SHOPPER_INSTAGRAM_FETCH_SUCCESS:
      return {
        instagramFeed: payload,
      };
    case actionTypes.ACCOUNTS_REMOVE_STATE_SUCCESS:
      return {};

    default:
      return state;
  }
};
