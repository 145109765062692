import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "actions";
import { getSessionUser } from "selectors/session";

import Button from "components/shared/formDefault/Button";
import styles from "./index.module.sass";

const propTypes = {
  submitting: PropTypes.bool,
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
};

const defaultProps = {
  submitting: false,
  user: {
    role: "",
  },
};

function StaffRole({ accountId, staffId, isActive, submitting, accountStaffDeactivate, accountStaffActivate, user }) {
  const { role } = user;
  return (
    <div className={styles.btnGroup}>
      {staffId !== "setup" && role === "admin" && (
        <Button type="reset" onClick={() => (isActive ? accountStaffDeactivate() : accountStaffActivate())}>
          {isActive ? "deactivate" : "reactivate"}
        </Button>
      )}

      <Button type="submit" disable={submitting}>
        {submitting ? "saving..." : "save"}
      </Button>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: getSessionUser(state),
  };
}

const mapDispatchToProps = (dispatch, { accountId, staffId }) => {
  return {
    accountStaffDeactivate: () => dispatch(actions.accountStaffDeactivate(accountId, staffId)),
    accountStaffActivate: () => dispatch(actions.accountStaffActivate(accountId, staffId)),
  };
};

StaffRole.defaultProps = defaultProps;
StaffRole.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StaffRole));
