import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  baseClass: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  baseClass: undefined,
  className: undefined,
};

function RadioButton({ onChange, name, className, baseClass, ...props }) {
  return <input name={name} onChange={onChange} type="radio" className={className} {...props} />;
}

RadioButton.propTypes = propTypes;
RadioButton.defaultProps = defaultProps;

export default RadioButton;
