import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./index.module.sass";

const propTypes = {
  isSeparate: PropTypes.bool,
};

const defaultProps = {
  isSeparate: true,
};

function FieldsCollection({ children, title, isSeparate }) {
  return (
    <div className={classnames(styles.fieldsCollection, !isSeparate && styles.noSeparate)}>
      {title && <h2 className={styles.fieldsCollectionTitle}>{title}</h2>}
      <div>{children}</div>
    </div>
  );
}

FieldsCollection.defaultProps = defaultProps;
FieldsCollection.propTypes = propTypes;

export default FieldsCollection;
