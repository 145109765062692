import * as React from "react";
import ReactSelect from "components/shared/formBase/ReactSelect/SimpleValueAdapter";

import "./rsStyles.sass";

function ClassicSelect({ isError, ...props }) {
  return <ReactSelect {...props} className={isError && "isError"} />;
}

export default ClassicSelect;
