import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import cl from "classnames";

import useFormGroupAdapter from "hooks/useFormGroupAdapter";
import styles from "./index.module.sass";
import InfoBlock from "./shared/InfoBlock";

function Adapter(props) {
  const [groupProps] = useFormGroupAdapter(props);
  const { error, invalid } = groupProps;
  const { input, label, titleInfo, disabledPhony } = props;

  return (
    <>
      {label && <div className={styles.label}>{label}</div>}
      <div
        style={titleInfo && { width: "95%" }}
        className={cl(styles.phoneWrapper, invalid && error ? styles.isError : "")}
      >
        <div className={styles.phoneGroup}>
          <PhoneInput international placeholder="Enter phone number" {...input} />
          {input.value !== "" && !isValidPhoneNumber(input.value) && (
            <span className={styles.errorMessage}>Invalid phone number</span>
          )}
          {titleInfo && <InfoBlock title={titleInfo} />}
        </div>
      </div>
    </>
  );
}

export default Adapter;
