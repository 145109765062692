import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { getIn } from "utils/ramda";
import { REFERENCES_PATH } from "constants/routes";
import fetch from "apiAdapters/config/fetch";
import { map, find, propEq, filter, contains, isEmpty } from "ramda";

import * as actions from "actions";
import { getStaffDetail } from "selectors/staffs";
import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import Input from "components/shared/formDefault/Input";
import InputFile from "components/shared/formDefault/InputFile";
import ReactSelect from "components/shared/formDefault/ReactSelect";
import RadioGroup from "components/shared/formDefault/RadioGroup";
import ErrorMessage from "components/shared/formDefault/ErrorMessage";

import {
  composeValidators,
  validateRequired,
  validateImage,
  validateEmail,
} from "components/shared/formDefault/validations";

import styles from "../index.module.sass";
import FormBlock from "../shared/FormBlock";
import FieldsCollection from "../shared/FieldsCollection";
import BtnGroup from "../shared/BtnGroup";
import SelectedItems from "../shared/SelectedItems";
import { languagesOptions, levelsOptions } from "../shared/mockData";

const propTypes = {
  fetchAccountStaff: PropTypes.func.isRequired,
  staff: PropTypes.object,
};

const defaultProps = {
  staff: {},
};

function EditStaffForm({ fetchAccountStaff, onSubmitStaffData, match, staff }) {
  const { accountId, staffId } = match.params;
  const { isActive } = staff;
  useEffect(() => {
    fetchAccountStaff(accountId);
    getReferences();
  }, []);

  const [references, setReferences] = useState({});

  const getReferences = async () => {
    const response = await fetch(REFERENCES_PATH);
    setReferences(response.data);
  };

  return (
    <Form
      initialValues={staff}
      mutators={{
        clearAvataField: ([callBack], state, { changeValue }) => {
          changeValue(state, "contact.avatar", () => {
            callBack();
            return null;
          });
        },
        addLanguage: ([language], state, { changeValue }) => {
          changeValue(state, "languages", () => {
            const languages = state.formState.values.languages || [];
            return [...languages, language];
          });
        },
        deleteLanguage: ([languageId], state, { changeValue }) => {
          changeValue(state, "languages", () => {
            const languagesIds = state.formState.values.languages || [];
            return filter((id) => id !== languageId, languagesIds);
          });
        },
        addDomainName: ([domainName], state, { changeValue }) => {
          const domainNames = state.formState.values.contact.domainNames || [];
          changeValue(state, "contact.domainName", () => null);
          if (!contains(domainName, domainNames) && !isEmpty(domainName)) {
            changeValue(state, "contact.domainNames", () => {
              return [...domainNames, domainName];
            });
          }
        },
        deleteDomainName: ([domainName], state, { changeValue }) => {
          changeValue(state, "contact.domainNames", () => {
            const domainNames = state.formState.values.contact.domainNames || [];
            return filter((domainNameOwn) => domainNameOwn !== domainName, domainNames);
          });
        },
      }}
      onSubmit={(props) => {
        const { id, employeeId, isOnline, isActive, role, contact, languages, timezone, alias } = props;
        const { avatar } = contact;
        return onSubmitStaffData(accountId, staffId, props);
      }}
      render={({ handleSubmit, hasSubmitErrors, submitError, submitting, form, form: { mutators }, values }) => (
        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <div className={styles.formBlocks}>
            <FormBlock>
              <FieldsCollection title="PROFILE PHOTO">
                <Field
                  component={FormGroupAdapter}
                  inputComponent={InputFile}
                  name="contact.avatar"
                  type="file"
                  form={form}
                  handleClear={mutators.clearAvataField}
                  validate={validateImage()}
                />
              </FieldsCollection>
              <FieldsCollection title="BASIC INFO">
                <Field
                  label="First Name"
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="contact.firstName"
                  placeholder="First Name"
                  type="text"
                  disabled
                  validate={composeValidators(validateRequired())}
                />

                <Field
                  label="Last Name"
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="contact.lastName"
                  placeholder="Last Name"
                  type="text"
                  disabled
                  validate={composeValidators(validateRequired())}
                />

                <Field
                  label="Email"
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="contact.email"
                  placeholder="Email"
                  type="email"
                  disabled
                  validate={composeValidators(validateRequired(), validateEmail())}
                />

                <Field
                  label="Alias"
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="alias"
                  placeholder="Alias"
                  type="text"
                  // validate={composeValidators(validateRequired())}
                />

                <Field
                  label="Employee ID"
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="employeeId"
                  placeholder="Employee id"
                  type="text"
                  validate={composeValidators(validateRequired())}
                />

                <Field
                  label="Store Number"
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="storeNumber"
                  placeholder="Store Number"
                  type="text"
                  // validate={composeValidators(validateRequired())}
                />

                <Field
                  label="Store Location"
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="storeLocation"
                  placeholder="Store Location"
                  type="text"
                  // validate={composeValidators(validateRequired())}
                />
                {/* <Field
                  label="Store Location"
                  component={FormGroupAdapter}
                  inputComponent={ReactSelect}
                  name="contact.storeLocation"
                  placeholder="Select Store Location"
                  options={testOptions}
                  type="select"
                  // validate={validateRequired()}
                /> */}
              </FieldsCollection>
            </FormBlock>

            <FormBlock>
              <FieldsCollection>
                <Field
                  label="Timezone"
                  component={FormGroupAdapter}
                  inputComponent={ReactSelect}
                  name="timezone"
                  placeholder="Timezone"
                  type="select"
                  options={references && references.timezones}
                  // validate={composeValidators(validateRequired())}
                />
                <Field
                  label="Level"
                  component={FormGroupAdapter}
                  inputComponent={ReactSelect}
                  name="level"
                  placeholder="Level"
                  type="select"
                  options={levelsOptions}
                  // validate={composeValidators(validateRequired())}
                />

                {/* <Field
                  label="Domain Names"
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="domainName"
                  placeholder="Enter domain name"
                  type="text"
                  onKeyPress={mutators.addDomainName}
                /> */}
                {/* <FormSpy subscription={{ domainNames: true }}>
                  {(props) => {
                    const {
                      values: { contact },
                    } = props.form.getState();

                    return (
                      <SelectedItems
                        items={(contact && contact.domainNames) || []}
                        handleDelete={mutators.deleteDomainName}
                        isSimple
                      />
                    );
                  }}
                </FormSpy> */}

                <div>
                  <Field
                    label="Languages"
                    component={FormGroupAdapter}
                    inputComponent={ReactSelect}
                    name="languages"
                    placeholder="Languages"
                    type="select"
                    onChange={mutators.addLanguage}
                    options={filter(
                      (testOption) => !contains(testOption.value, (values && values.languages) || []),
                      languagesOptions,
                    )}
                    // validate={composeValidators(validateRequired())}
                  />

                  <FormSpy subscription={{ languages: true }}>
                    {(props) => {
                      const {
                        values: { languages },
                      } = props.form.getState();
                      return (
                        <SelectedItems
                          items={map(
                            (selectedItemId) => find(propEq("value", selectedItemId), languagesOptions),
                            languages || [],
                          )}
                          handleDelete={mutators.deleteLanguage}
                        />
                      );
                    }}
                  </FormSpy>
                </div>
              </FieldsCollection>

              <FieldsCollection title="PERMISSIONS" isSeparate={false}>
                <RadioGroup
                  options={[
                    { id: 1, title: "Admin", value: "admin", name: "role" },
                    { id: 2, title: "Manager", value: "manager", name: "role" },
                    { id: 3, title: "Agent", value: "agent", name: "role" },
                  ]}
                />
              </FieldsCollection>
            </FormBlock>
          </div>
          {hasSubmitErrors && submitError && (
            <div className={styles.errorMessageContainer}>
              <ErrorMessage>{submitError}</ErrorMessage>
            </div>
          )}
          <BtnGroup accountId={accountId} staffId={staffId} isActive={isActive} submitting={submitting} />
        </form>
      )}
    />
  );
}

function mapStateToProps(state, props) {
  const staffId = getIn("match.params.staffId", props);
  return {
    staff: getStaffDetail(state, staffId),
  };
}

const mapDispatchToProps = (dispatch) => ({
  onSubmitStaffData: (accountId, staffId, contact) => dispatch(actions.editStaffData(accountId, staffId, contact)),
  fetchAccountStaff: (accountId) => dispatch(actions.fetchAccountStaff(accountId)),
});

EditStaffForm.defaultProps = defaultProps;
EditStaffForm.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditStaffForm));
