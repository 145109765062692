import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import cl from "classnames";

import Input from "components/shared/formDefault/Input";
import Mask from "components/shared/formDefault/Mask";
import Switch from "components/shared/formDefault/Switch";
import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import ReactSelect from "components/shared/formDefault/ReactSelect";

import { validateDate } from "components/shared/formDefault/validations";

import styles from "./index.module.sass";

const propTypes = {
  references: PropTypes.shape({
    sizingInfo: PropTypes.shape({
      womenswear: PropTypes.shape({
        jean: PropTypes.arrayOf(),
      }),
    }),
  }),
};

const defaultProps = {
  references: {
    sizingInfo: {
      womenswear: {
        jean: [],
      },
    },
  },
};

function PersonalInfo({ references }) {
  return (
    <>
      <Switch
        title="Have your ever shopped with a personal stylist?"
        radioToggle
        options={[
          { title: "No", value: "false", name: "profile.personalStylistBefore" },
          { title: "Yes", value: "true", name: "profile.personalStylistBefore" },
        ]}
      />
      <Field
        label="Marital Status"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="maritalStatus"
        placeholder="select"
        className="inputFlexFill"
        type="select"
        isInfo
        titleInfo="Just curious if you’ll be shopping for a partner or have important dates to remember. This helps our recommendation engine make “gift” recommendations instead of making changes to your personal recommendation engine”. Just keeping us relevant."
        options={references.contact && references.contact.maritalStatuses}
      />
      <Switch
        title="Have you ever spent more than $150.00 on jeans?"
        radioToggle
        options={[
          { id: 1, title: "No", value: "false", name: "profile.jeansGoal" },
          { id: 2, title: "Yes", value: "true", name: "profile.jeansGoal" },
        ]}
      />
      <Field
        label="Anniversary date"
        component={FormGroupAdapter}
        inputComponent={Mask}
        name="anniversaryDate"
        typeOfMask="date"
        className="inputFlexFill"
        placeholder="mm/dd/yy"
        type="date"
        isInfo
        titleInfo="You don’t have to be married for an anniversary shopping date reminder."
        parse={(value) => (value === "" ? null : value)}
        validate={validateDate()}
      />
      <div className={styles.textareaWrapper}>
        <span>My Style</span>
        <Field
          component="textarea"
          placeholder="Notes"
          name="profile.styleDescription"
          placeholder="Share your style with your stylist so you don’t have to repeat yourself. "
          className="textAria"
          parse={(value) => (value === "" ? null : value)}
        />
      </div>
    </>
  );
}

PersonalInfo.defaultProps = defaultProps;
PersonalInfo.propTypes = propTypes;

export default PersonalInfo;
