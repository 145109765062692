import React from "react";
import { history } from "stores/configureStore";

import { getPath, DASHBOARD_ACCOUNT_STAFF_LIST_PATH } from "constants/routes";
import TabItem from "../TabItem";

const propTypes = {};

const defaultProps = {};

function BackButton(props) {
  const { match } = props;
  const { accountId } = match.params;
  return (
    <TabItem title="Back" iconClassName="iconBack" url={getPath(DASHBOARD_ACCOUNT_STAFF_LIST_PATH, { accountId })} />
  );
}

BackButton.defaultProps = defaultProps;
BackButton.propTypes = propTypes;

export default BackButton;
