import {
  AUTH_TOKEN_PATH,
  DASHBOARD_ACCOUNT_CONSULTANT_PATH,
  DASHBOARD_AUTH_REGISTRATION_PATH,
  ME_PATH,
  getPath,
} from "constants/routes.js";

import { postRequest, getRequest } from "../config/requests";
import cfetch, { cfetchAuth } from "../config/fetch";

// const { AUTH_URL } = process.env;
const clientId = process.env.CLIENT_ID;
const clientSecret = process.env.CLIENT_SECRET;

export default class SessionApiAdapter {
  static postSignInCredentials(body) {
    return cfetchAuth(AUTH_TOKEN_PATH, postRequest({ body }));
  }

  static postSignUpCredentials(body) {
    return cfetch(DASHBOARD_AUTH_REGISTRATION_PATH, postRequest({ body }));
  }

  static postRefreshToken(body) {
    return cfetchAuth(AUTH_TOKEN_PATH, postRequest({ body }));
  }

  static getMe() {
    return cfetch(ME_PATH, getRequest());
  }

  static getMyProfile(accountId) {
    return cfetch(getPath(DASHBOARD_ACCOUNT_CONSULTANT_PATH, { accountId }), getRequest());
  }

  static postNewPassword(userData, token) {
    const { password, passwordConfirmation } = userData;
    return cfetch(
      `/auth/password/update/${token}`,
      postRequest({
        body: {
          password,
          password_confirmation: passwordConfirmation,
          client_id: clientId,
          client_secret: clientSecret,
        },
      }),
    );
  }

  static postCheckToken(token) {
    return cfetch(
      `/auth/password/check/${token}`,
      postRequest({
        body: {},
      }),
    );
  }

  static postEmailToRestorePassword(userData) {
    const { email } = userData.userSession;
    return cfetch(
      // getPath(DASHBOARD_AUTH_POST_UPDATE_PASSWORD_PATH, token),
      "/auth/password/reset",
      postRequest({
        body: {
          username: email,
          client_id: clientId,
          client_secret: clientSecret,
        },
      }),
    );
  }
}
