import React from "react";
import PropTypes from "prop-types";

// import useClassNames from 'hooks/useClassNames';

const propTypes = {
  baseClass: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
};

const defaultProps = {
  baseClass: undefined,
  className: undefined,
  type: "text",
};

function Input({ type, onChange, name, className, baseClass, refComponent, ...props }) {
  return <input name={name} onChange={onChange} type={type} {...props} className={className} ref={refComponent} />;
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
