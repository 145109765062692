import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "actions";

const propTypes = {};

const defaultProps = {};

// WIP !!!!!!!
function InstagramConnect(props) {
  const redirectUrl = localStorage.getItem("startingURL");
  const { fetchInstagramConnect } = props;
  useEffect(() => {
    fetchInstagramConnect(props.location.search, redirectUrl);
  }, []);

  return <span>Test</span>;
}

const mapDispatchToProps = (dispatch) => ({
  fetchInstagramConnect: (queryString, redirectUrl) =>
    dispatch(actions.fetchInstagramConnect(queryString, redirectUrl)),
});

InstagramConnect.defaultProps = defaultProps;
InstagramConnect.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(withRouter(InstagramConnect));
