import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import cl from "classnames";

import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import ReactSelect from "components/shared/formDefault/ReactSelect";

// import styles from "./index.module.sass";

const propTypes = {
  references: PropTypes.shape({
    sizingInfo: PropTypes.shape({
      womenswear: PropTypes.shape({
        jean: PropTypes.arrayOf(),
      }),
    }),
  }),
};

const defaultProps = {
  references: {
    sizingInfo: {
      womenswear: {
        jean: [],
      },
    },
  },
};

function Womenswear({ references }) {
  function getOptions(value) {
    const options =
      value &&
      value.map((item) => {
        return { value: item, label: item };
      });
    return options;
  }

  return (
    <>
      <Field
        label="Jeans"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.jean"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.jean)}
      />
      <Field
        label="Dress Size"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.dressSize"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.dressSize)}
      />
      <Field
        label="US Shoe"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.usShoe"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.usShoe)}
      />
      <Field
        label="EU Shoe"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.euShoe"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.euShoe)}
      />
      <Field
        label="Shoe Width"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.shoeWidth"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.shoeWidth)}
      />
      <Field
        label="Shirt Size"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.shirtSize"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.shirtSize)}
      />
      <Field
        label="Alpha Tops"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.alphaTops"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.alphaTops)}
      />
      <Field
        label="Bottoms"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.bottoms"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.bottoms)}
      />
      <Field
        label="Alpha Bottoms"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.alphaBottoms"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.alphaBottoms)}
      />
      <Field
        label="Belt"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.belt"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.belt)}
      />
      <Field
        label="Bra"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.bra"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.bra)}
      />
      <Field
        label="Cup"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.cup"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.cup)}
      />
      <Field
        label="Undies"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.undies"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.undies)}
      />
      <Field
        label="Ring"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.ring"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.ring)}
      />
      <Field
        label="Hat"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.womenswear.hat"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.womenswear.hat)}
      />
    </>
  );
}

Womenswear.defaultProps = defaultProps;
Womenswear.propTypes = propTypes;

export default Womenswear;
