export const SIGN_OUT_DELETE = "SIGN_OUT_DELETE";
export const PASSWORD_TOKEN = "PASSWORD_TOKEN";

export const PRODUCT_FETCH = "PRODUCT_FETCH";

export const STAFF_FETCH = "STAFF_FETCH";

export const SHOP_PRODUCTS_COLLECTION_FETCH = "SHOP_PRODUCTS_COLLECTION_FETCH";

export const ACCOUNTS_APPEARANCE_FETCH = "ACCOUNTS_APPEARANCE_FETCH";

export const PRODUCTS_COLLECTION_RECOMMENDED_FETCH = "PRODUCTS_COLLECTION_RECOMMENDED_FETCH";
export const PRODUCTS_COLLECTION_WITH_FILTTER_FETCH = "PRODUCTS_COLLECTION_WITH_FILTTER_FETCH";
export const STYLE_PRODUCTS_COLLECTION_FETCH = "STYLE_PRODUCTS_COLLECTION_FETCH";

export const ACCOUNTS_USER_FETCH = "ACCOUNTS_USER_FETCH";
export const ACCOUNT_CHAT_MESSAGES_FETCH = "ACCOUNT_CHAT_MESSAGES_FETCH";
export const ACCOUNT_CHAT_MESSAGES_LOAD_FETCH = "ACCOUNT_CHAT_MESSAGES_LOAD_FETCH";
export const ACCOUNTS_INSTAGRAM_FEED_FETCH = "ACCOUNTS_INSTAGRAM_FEED_FETCH";
export const ACCOUNT_EDITOR_PRODUCTS_POST = "ACCOUNT_EDITOR_PRODUCTS_POST";
export const ACCOUNT_MESSAGE_IN_CHAT_POST = "ACCOUNT_MESSAGE_IN_CHAT_POST";
export const ACCOUNTS_ADD_PRODUCT_TO_EDITOR = "ACCOUNTS_ADD_PRODUCT_TO_EDITOR";

export const ACCOUNTS_SHOPPER_CURRENT_EVENTS = "ACCOUNTS_SHOPPER_CURRENT_EVENTS";
export const ACCOUNTS_CONSULTANT = "ACCOUNTS_CONSULTANT";
export const ACCOUNTS_CONSULTANT_DANDWIDTH_POST = "ACCOUNTS_CONSULTANT_DANDWIDTH_POST";

export const ACCOUNT_SHOPPERS_FETCH = "ACCOUNT_SHOPPERS_FETCH";
export const ACCOUNTS_FETCH = "ACCOUNTS_FETCH";

export const LOCATIONS_FETCH = "LOCATIONS_FETCH";

export const ACCOUNT_STAFF_DATA_POST = "ACCOUNT_STAFF_DATA_POST";
export const ACCOUNTS_STAFF_UPDATE = "ACCOUNTS_STAFF_UPDATE";

export const INVITE_CLIENT_POST = "INVITE_CLIENT_POST";
export const BULK_UPLOAD_CLIENT_POST = "BULK_UPLOAD_CLIENT_POST";

export const SUBMIT_SEARCH_DATA = "SUBMIT_SEARCH_DATA";

// session

export const POST_SIGN_IN_CREDENTIALS = "POST_SIGN_IN_CREDENTIALS";
export const POST_SIGN_UP_CREDENTIALS = "POST_SIGN_UP_CREDENTIALS";
export const POST_UPDATE_PASSWORD = "POST_UPDATE_PASSWORD";
export const POST_EMAIL_TO_RESTORE_PASSWORD = "POST_EMAIL_TO_RESTORE_PASSWORD";

export const MY_PROFILE_FETCH = "MY_PROFILE_FETCH";

// me
export const ME_FETCH = "ME_FETCH";

// shopper

export const SHOPPER_PROFILE_POST = "SHOPPER_PROFILE_POST";
export const SHOPPER_PROFILE_FETCH = "SHOPPER_PROFILE_FETCH";
export const SHOPPER_PROFILE_PHOTOS_FETCH = "SHOPPER_PROFILE_PHOTOS_FETCH";
export const SHOPPER_INSTAGRAM_FETCH = "SHOPPER_INSTAGRAM_FETCH";
