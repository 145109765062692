import React from "react";
import { Redirect, Route } from "react-router-dom";
import { DASHBOARD_AUTH_PATH } from "constants/routes";
import { useAuthenticatedStatus } from "utils/auth";

function PrivateRoute({ component: Componnet, ...rest }) {
  const isAuthenticated = useAuthenticatedStatus();

  return (
    <Route
      {...rest}
      render={({ location, ...props }) =>
        isAuthenticated ? (
          <Componnet {...props} />
        ) : (
          <Redirect
            to={{
              pathname: DASHBOARD_AUTH_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
