import { shoppersNormalizr, shopperNormalizr } from "schemas";
import { throwSubmissionError } from "utils/form";
import { pushToast } from "components";
import { logout } from "utils/sessionData";
import { AccountsApiAdapter } from "../../apiAdapters";
import * as requestTypes from "../../constants/requestTypes";
import * as types from "../../constants/actionTypes";

import { mergeEntities } from "../entities";
import setRequestInProcess from "../request";

export function fetchAccountsCollection() {
  const requestType = requestTypes.ACCOUNTS_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    AccountsApiAdapter.getAccountsApiAdapter().then((res) => {
      dispatch(fetchAccountsCollectionSuccess(res));
      dispatch(setRequestInProcess(false, requestType));
    });
    // .catch((error) => {
    //   if (error.response) {
    //     openNotification('error', error.response.data.message);
    //   }
    // });
  };
}

export function fetchAccountShoppers(accountId) {
  const requestType = requestTypes.ACCOUNT_SHOPPERS_FETCH;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    AccountsApiAdapter.getAccountShoppersApiAdapter(accountId).then((res) => {
      if (res.data) {
        const { entities, result } = shoppersNormalizr(res.data);
        dispatch(mergeEntities(entities));
        dispatch(fetchAccountShoppersSuccess(result));
      }
      dispatch(setRequestInProcess(false, requestType));
    });
    // .catch((error) => {
    //   if (error.response) {
    //     openNotification('error', error.response.data.message);
    //   }
    // });
  };
}

export function fetchAccountShopper(accountId, id) {
  const requestType = requestTypes.ACCOUNT_SHOPPERS_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    AccountsApiAdapter.getAccountShopperApiAdapter(accountId, id).then((res) => {
      const { entities } = shopperNormalizr(res.data);
      dispatch(mergeEntities(entities));
      dispatch(setRequestInProcess(false, requestType));
    });
    // .catch((error) => {
    //   if (error.response) {
    //     openNotification('error', error.response.data.message);
    //   }
    // });
  };
}

export function fetchAccountChatMessages(accountId, id) {
  const requestType = requestTypes.ACCOUNT_CHAT_MESSAGES_FETCH;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    AccountsApiAdapter.getAccountChatMessages(accountId, id).then((res) => {
      dispatch(fetchAccountChatMessagesSuccess(res));
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchAccountChatMessagesLoad(accountId, id) {
  const requestType = requestTypes.ACCOUNT_CHAT_MESSAGES_LOAD_FETCH;
  return (dispatch, getState) => {
    const page = getState().account.messages.meta.nextPage || null;
    if (page) {
      dispatch(setRequestInProcess(true, requestType));
      AccountsApiAdapter.getAccountChatMessagesLoad(accountId, id, page).then((res) => {
        dispatch(fetchAccountChatMessagesLoadSuccess(res));
        dispatch(setRequestInProcess(false, requestType));
      });
    }
  };
}

export function sendInstagramLink(accountId, id, instagramLink) {
  const requestType = requestTypes.ACCOUNT_EDITOR_PRODUCTS_POST;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    AccountsApiAdapter.postInstagramLinkMessage(accountId, id, instagramLink).then(() => {
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function sendEditorProductCollection(accountId, id, productIds) {
  const requestType = requestTypes.ACCOUNT_EDITOR_PRODUCTS_POST;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    AccountsApiAdapter.postAccountEditorProductsCollection(accountId, id, productIds).then(() => {
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function postMessageInChat(accountId, id, message) {
  const requestType = requestTypes.ACCOUNT_MESSAGE_IN_CHAT_POST;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    AccountsApiAdapter.postMessageInChat(accountId, id, message).then(() => {
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function postNewConsultantBandWidth(accountId, newBandwidth) {
  const requestType = requestTypes.ACCOUNTS_CONSULTANT_DANDWIDTH_POST;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    AccountsApiAdapter.postNewConsultantBandWidth(accountId, newBandwidth).then((res) => {
      dispatch(fetchAccountUserBandWidthSuccess(res));
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchAccountConsultant(accountId) {
  const requestType = requestTypes.ACCOUNTS_CONSULTANT;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    AccountsApiAdapter.getAccountConsultant(accountId).then((res) => {
      dispatch(fetchAccountConsultantSuccess(res));
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchShopperCurrentEvents(accountId, id) {
  const requestType = requestTypes.ACCOUNTS_SHOPPER_CURRENT_EVENTS;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    AccountsApiAdapter.getShopperCurrentEvents(accountId, id).then((res) => {
      dispatch(fetchShopperCurrentEventsSuccess(res));
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchAppearance(accountId) {
  const requestType = requestTypes.ACCOUNTS_APPEARANCE_FETCH;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    AccountsApiAdapter.getAppearance(accountId).then((res) => {
      dispatch(fetchAppearanceSuccess(res.data));
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchAccountInstagramFeed(accountId) {
  const requestType = requestTypes.ACCOUNTS_INSTAGRAM_FEED_FETCH;
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    AccountsApiAdapter.getAccountInstagramFeed(accountId).then((res) => {
      dispatch(fetchAccountInstagramFeedSuccess(res));
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export const postAppearanceData = (accountId, body) => async (dispatch) => {
  const requestType = requestTypes.SHOPPER_PROFILE_POST;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await AccountsApiAdapter.postAppearance(accountId, body);
    pushToast({
      text: "Updated",
    });
    // // setSessionData(data);
    // // dispatch(fetchMyProfile());
    // // history.push(CHOOSE_ACCOUNT_PATH);
    dispatch(fetchAppearanceSuccess(data));
  } catch (error) {
    // console.log("Error", error);
    pushToast({
      type: "error",
      text: "Error",
    });
    if (error) {
      return throwSubmissionError(error);
    }
    return throwSubmissionError({ errors: { message: "Something went wrong" } });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const postInviteClient = (accountId, staffId, body) => async (dispatch) => {
  const requestType = requestTypes.INVITE_CLIENT_POST;
  dispatch(setRequestInProcess(true, requestType));

  try {
    await AccountsApiAdapter.postInviteClient(accountId, staffId, body);
    window.location.reload();
    pushToast({
      type: "success",
      text: "Success",
    });
  } catch (res) {
    if (res) {
      if (res.errors && res.errors.title) {
        pushToast({
          type: "error",
          text: res.errors.title,
        });
      }

      return throwSubmissionError(res);
    }

    pushToast({
      type: "error",
      text: "Something went wrong",
    });

    return throwSubmissionError({ errors: { message: "Something went wrong" } });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const postBulkUploadClients = (accountId, staffId, body) => async (dispatch) => {
  const requestType = requestTypes.BULK_UPLOAD_CLIENT_POST;
  dispatch(setRequestInProcess(true, requestType));

  try {
    await AccountsApiAdapter.postBulkUploadClients(accountId, staffId, body);
    window.location.reload();
    pushToast({
      type: "success",
      text: "Success",
    });
  } catch (res) {
    if (res) {
      if (res.errors && res.errors.title) {
        pushToast({
          type: "error",
          text: res.errors.title,
        });
      }

      return throwSubmissionError(res);
    }

    pushToast({
      type: "error",
      text: "Something went wrong",
    });

    return throwSubmissionError({ errors: { message: "Something went wrong" } });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export function toggleConsultantStatus(accountId, stylistStatus) {
  const requestType = requestTypes.ACCOUNTS_USER_FETCH;
  return async (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data } = await AccountsApiAdapter.postConsultantStatus(accountId, stylistStatus);
      dispatch(fetchAccountUserSuccess({ data }));
      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      pushToast({
        type: "error",
        text: "You have an active chat",
      });
    }
  };
}

export function newCountMessage(payload) {
  return {
    type: types.ACCOUNTS_COUNT_MESSAGE_SUCCESS,
    payload,
  };
}

export function removeCountMessage(payload) {
  return {
    type: types.ACCOUNTS_REMOVE_COUNT_MESSAGE_SUCCESS,
    payload,
  };
}

export function removeAccountState(payload) {
  logout();
  return {
    type: types.ACCOUNTS_REMOVE_STATE_SUCCESS,
    payload,
  };
}

export function fetchAppearanceSuccess(payload) {
  return {
    type: types.ACCOUNT_APPEARANCE_FETCH_SUCCESS,
    payload,
  };
}

export function fetchAccountConsultantSuccess(payload) {
  return {
    type: types.ACCOUNTS_CONSULTANT_SUCCESS,
    payload,
  };
}

export function fetchAccountUserSuccess(payload) {
  return {
    type: types.ACCOUNTS_USER_SUCCESS,
    payload,
  };
}

export function fetchAccountUserBandWidthSuccess(payload) {
  return {
    type: types.ACCOUNTS_USER_SUCCESS_BANDWIDTH,
    payload,
  };
}

export function fetchAccountInstagramFeedSuccess(payload) {
  return {
    type: types.ACCOUNTS_INSTAGRAM_FEED_FETCH_SUCCESS,
    payload,
  };
}

export function addProductToEditorSucceses(payload) {
  return {
    type: types.ACCOUNTS_ADD_PRODUCT_TO_EDITOR_SUCCESS,
    payload,
  };
}

export function addProductToFavoritesSucceses(payload) {
  return {
    type: types.ACCOUNTS_ADD_PRODUCT_TO_FAVORITES_SUCCESS,
    payload,
  };
}

export function addProductOfStorageToEditorSucceses(payload) {
  return {
    type: types.ACCOUNTS_ADD_PRODUCT_OF_STORAGE_TO_EDITOR_SUCCESS,
    payload,
  };
}

export function addProductOfStorageToFavoritesSucceses(payload) {
  return {
    type: types.ACCOUNTS_ADD_PRODUCT_OF_STORAGE_TO_FAVORITES_SUCCESS,
    payload,
  };
}

export function removeProductToEditorSucceses(payload) {
  return {
    type: types.ACCOUNTS_REMOVE_PRODUCT_TO_EDITOR_SUCCESS,
    payload,
  };
}

export function removeProductToFavoritesSucceses(payload) {
  return {
    type: types.ACCOUNTS_REMOVE_PRODUCT_TO_FAVORITES_SUCCESS,
    payload,
  };
}

// export function removeProductTest(payload) {
//   return {
//     type: types.ACCOUNTS_REMOVE_PRODUCT_TO_EDITOR_SUCCESS,
//     payload,
//   };
// }

function fetchAccountsCollectionSuccess(payload) {
  return {
    type: types.ACCOUNTS_FETCH_SUCCESS,
    payload,
  };
}

function fetchAccountShoppersSuccess(payload) {
  return {
    type: types.ACCOUNT_SHOPPERS_FETCH_SUCCESS,
    payload,
  };
}

export function removeStoreUnassignmentShopper(payload) {
  return {
    type: types.ACCOUNT_SHOPPER_REMOVE_SUCCESS,
    payload,
  };
}

function fetchAccountChatMessagesSuccess(payload) {
  return {
    type: types.ACCOUNT_CHAT_MESSAGES_FETCH_SUCCESS,
    payload,
  };
}

export function fetchAccountChatMessagesLoadSuccess(payload) {
  return {
    type: types.ACCOUNT_CHAT_MESSAGES_LOAD_FETCH_SUCCESS,
    payload,
  };
}

export function addMessageToChat(payload) {
  return {
    type: types.ADD_MESSAGE_TO_CHAT_SUCCESS,
    payload,
  };
}

export function addActivityEvent(payload) {
  return {
    type: types.ADD_ACTIVITY_EVENT_SUCCESS,
    payload,
  };
}

export function fetchShopperCurrentEventsSuccess(payload) {
  return {
    type: types.ACCOUNTS_SHOPPER_CURRENT_EVENTS_SUCCESS,
    payload,
  };
}

export function fetchConsultantStatus(payload) {
  return {
    type: types.ACCOUNTS_CONSULTANT_STATUS_SUCCESS,
    payload,
  };
}
