import React from "react";
import PropTypes from "prop-types";

import AppearanceForm from "./Form";
import setting from "../../setting.module.sass";
import styles from "./index.module.sass";

const propTypes = {};
const defaultProps = {};

function Appearance(props) {
  return (
    <div className={setting.contentLeft}>
      <div className={styles.formWrapper}>
        <AppearanceForm />
      </div>
    </div>
  );
}

Appearance.propTypes = propTypes;
Appearance.defaultProps = defaultProps;

export default Appearance;
