import React, { useState } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import * as actions from "actions";
import Slider from "react-slick";

import { getProductsDetail } from "selectors/products";
import ProductCard from "./ProductCard";
import product from "./product.module.sass";

const propTypes = {
  accountId: PropTypes.string,
  id: PropTypes.string,
  productsCollection: PropTypes.arrayOf(
    PropTypes.shape({
      meta: PropTypes.shape({
        isLastPage: PropTypes.bool,
        nextPage: PropTypes.number,
      }),
      ids: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
  handleLoadMore: PropTypes.func,
  withoutPrice: PropTypes.bool,
  // onRemoveProductToEditor: PropTypes.func.isRequired,
};
const defaultProps = {
  accountId: "",
  id: "",
  productsCollection: [
    {
      meta: {
        isLastPage: false,
        nextPage: null,
      },
      ids: [],
    },
  ],
  handleLoadMore: undefined,
  withoutPrice: false,
};

// WIP!!!!

function Products(props) {
  const {
    productsCollection,
    accountId,
    id,
    productIds,
    products,
    fetchShopProductsLoadCollection,
    fetchStyleProductsLoadCollection,
    meta,
    withoutPrice,
    namePage,
  } = props;
  const { ids } = productsCollection;
  const nextPage = meta ? meta.nextPage : null;
  const isLastPage = meta ? meta.isLastPage : true;

  const handleLoadMore = (accountId, nextPage) => {
    if (namePage) {
      fetchStyleProductsLoadCollection(accountId, id, namePage, nextPage);
    } else {
      fetchShopProductsLoadCollection(accountId, nextPage);
    }
  };

  function loader() {
    return (
      <div key={accountId} className={product.loader}>
        {productsCollection && productsCollection.length > 0 ? "Loading ... Please wait..." : "No products found"}
      </div>
    );
  }

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        element="ul"
        className={product.list}
        loadMore={() => nextPage && handleLoadMore(accountId, nextPage)}
        hasMore={!isLastPage}
        loader={loader()}
      >
        {productsCollection.length > 0 &&
          productsCollection.map((product, index) => (
            <ProductCard
              key={index}
              index={index}
              product={product}
              accountId={accountId}
              shopperId={id}
              withoutPrice={withoutPrice}
              // onAddProductToEditor={onAddProductToEditor}
              // onRemoveProductToEditor={onRemoveProductToEditor}
            />
          ))}
      </InfiniteScroll>
    </>
  );
}

function mapStateToProps(state, props) {
  return {
    products: getProductsDetail(state, props.productIds),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchShopProductsLoadCollection: (accountId, nextPage) =>
    dispatch(actions.fetchShopProductsLoadCollection(accountId, nextPage)),
  fetchStyleProductsLoadCollection: (accountId, id, name, nextPage) =>
    dispatch(actions.fetchStyleProductsLoadCollection(accountId, id, name, nextPage)),
});

Products.propTypes = propTypes;
Products.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Products);
