import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import * as routes from "constants/routes";

import Profile from "../Profile";
import Style from "../Style";
import Shop from "../Shop";

const propTypes = {};

function Router(props) {
  const { setShowNav, productIds, setIsProfilePageg } = props;

  return (
    <Switch>
      <Route
        path={routes.PROFILE_DASHBOARD_ACCOUNT_SHOPPER_PATH}
        render={(p) => {
          // FIXME.
          const key = p.match.params.id;
          return <Profile setShowNav={setShowNav} setIsProfilePageg={setIsProfilePageg} key={key} {...p} />;
        }}
      />
      <Route path={routes.STYLE_DASHBOARD_ACCOUNT_SHOPPER_PATH} render={() => <Style setShowNav={setShowNav} />} />
      <Route
        path={routes.SHOP_DASHBOARD_ACCOUNT_SHOPPER_PATH}
        render={() => <Shop setShowNav={setShowNav} productIds={productIds} />}
      />
    </Switch>
  );
}

Router.propTypes = propTypes;

export default Router;
