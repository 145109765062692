import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as actions from "actions";
import { connect } from "react-redux";
import cl from "classnames";
import moment from "moment";
import { requestInProcess } from "selectors/request";
import * as requestTypes from "constants/requestTypes";

import { getInstagramFeed, getConsultant } from "selectors/accounts";
import dashboard from "../dashboard.module.sass";
import { luxIcon, postImg, loading } from "../Image";

const propTypes = {};
const defaultProps = {};

function Item({ instagramFeed, username }) {
  useEffect(() => {
    // getInstagramAvatar(username);
  }, []);

  // const [icon, setIcon] = useState(luxIcon);

  // const getInstagramAvatar = async (name) => {
  //   const apiCall = await fetch(`https://www.instagram.com/${name}/?__a=1`);
  //   const response = await apiCall.json();

  //   setIcon(response.graphql.user.profile_pic_url_hd);
  // };

  return (
    <>
      {instagramFeed.map((item) => (
        <>
          <li key={item.id} className={dashboard.postListingouterItem}>
            <img className={dashboard.imgIcon} src={luxIcon} alt="icon" />
            <a href="#">
              <img className={dashboard.imgCont} src={item.thumbnailUrl ? item.thumbnailUrl : item.mediaUrl} />
              <span className={dashboard.textHover}>
                @{item.username}
                <br />
                {moment(item.timestamp).format("DD MMM")}
              </span>
            </a>
          </li>
        </>
      ))}
    </>
  );
}

Item.propTypes = propTypes;
Item.defaultProps = defaultProps;

export default Item;
