import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Route, withRouter } from "react-router-dom";
import * as routes from "constants/routes";
import cl from "classnames";

import { Abandoned, Browse, Closet, Returns, Wishlist } from "./shared";
import { Products, FilterPanel, Slider } from "../shared";
import NavTabs from "./NavTabs";
import style from "./style.module.sass";

const propTypes = {
  setShowNav: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.string,
    }),
  }),
};

const defaultProps = {
  match: {
    params: {
      accountId: "",
    },
  },
};

function Style(props) {
  const { setShowNav, match } = props;
  const { accountId, id } = match.params;
  useEffect(() => {
    setShowNav(true);
    return () => setShowNav(false);
  }, []);

  return (
    <>
      <div className={cl(`container ${style.contentRight}`)}>
        <Slider accountId={accountId} id={id} />
        <NavTabs />
        <Switch>
          <Route
            path={routes.DASHBOARD_ACCOUNT_SHOPPER_STYLE_ABANDONED_PATH}
            render={() => <Abandoned accountId={accountId} id={id} namePage={"abandoned"} />}
            exact
          />

          <Route
            path={routes.DASHBOARD_ACCOUNT_SHOPPER_STYLE_CLOSET_PATH}
            render={() => <Closet accountId={accountId} id={id} namePage={"closet"} />}
            exact
          />
          <Route
            path={routes.DASHBOARD_ACCOUNT_SHOPPER_STYLE_WISHLIST_PATH}
            render={() => <Wishlist accountId={accountId} id={id} namePage="wished" />}
            exact
          />
          <Route
            path={routes.DASHBOARD_ACCOUNT_SHOPPER_STYLE_BROWSE_PATH}
            render={() => <Browse accountId={accountId} id={id} namePage="browse" />}
            exact
          />
          <Route
            path={routes.DASHBOARD_ACCOUNT_SHOPPER_STYLE_RETURNS_PATH}
            render={() => <Returns accountId={accountId} id={id} namePage="refund" />}
            exact
          />
        </Switch>
      </div>
    </>
  );
}

Style.propTypes = propTypes;
Style.defaultProps = defaultProps;

export default withRouter(Style);
