import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import cl from "classnames";
import * as actions from "actions";
import { connect } from "react-redux";

import { getParams } from "selectors/products";
import useOnClickOutside from "hooks/useOnClickOutside";
import { search } from "../Image";
import style from "../../Style/style.module.sass";
// import useAsyncAction from 'hooks/useAsyncAction';

const propTypes = {
  accountId: PropTypes.string.isRequired,
  handleChangeData: PropTypes.func.isRequired,
};
const defaultProps = {};

// WIP!!!!

function FilterPanel(props) {
  const { handleChangeData, accountId, id, params, namePage } = props;

  const ref = useRef();
  const [isFilter, setIsFilter] = useState("Newest");
  const [isModalOpen, setModalOpen] = useState(false);
  const handleClick = () => {
    isModalOpen ? setModalOpen(false) : setModalOpen(true);
  };
  useOnClickOutside(ref, () => setModalOpen(false));

  const [isText, setIsTest] = useState({});

  const handleChangeSearchData = (text) => {
    setIsTest({ ...isText, q: text });
    handleChangeData(accountId, id, { ...isText, q: text }, namePage);
  };

  const handleChangeFilterData = (text, title) => {
    setIsTest({ ...isText, filter: text });
    handleChangeData(accountId, id, { ...isText, filter: text }, namePage);
    setIsFilter(title);
  };

  const filterCollection = [
    {
      id: 0,
      title: "Newest",
      value: "defaut",
    },
    {
      id: 1,
      title: "Lowest Price",
      value: "price.asc",
    },
    {
      id: 2,
      title: "Highest Price",
      value: "price.desc",
    },
    {
      id: 3,
      title: "Favorites",
      value: "favorite",
    },
  ];

  return (
    <>
      <div className={style.filterPanel}>
        <div className={style.filterPanelSearch}>
          <input
            className={style.searchInput}
            placeholder="search"
            onChange={(e) => handleChangeSearchData(e.target.value)}
          />
          <button className={style.searchButton}>
            <img src={search} alt="search" />
          </button>
        </div>
        <div className={style.filterPanelSort}>
          <div className={style.sortDropdown}>
            <h3>sort by</h3>
            <div ref={ref} className={style.dropdown} onClick={handleClick}>
              {isFilter}
              {isModalOpen && (
                <ul className={style.dropdownList}>
                  {filterCollection.map(({ id, title, value }) => (
                    <li key={id} onClick={() => handleChangeFilterData(value, title)} className={style.dropdownItem}>
                      {title}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          {/* <button className={cl(`btn ${style.filterBtn}`)}>
            Filters <span>(2)</span>
          </button> */}
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    params: getParams(state),
  };
}

FilterPanel.propTypes = propTypes;
FilterPanel.defaultProps = defaultProps;

export default connect(mapStateToProps, null)(FilterPanel);
