import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import Product from "./Product";

const propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  products: [],
};

function SliderGallery(props) {
  const { products } = props;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  return (
    <>
      <Slider {...settings}>
        {products.map((product) => (
          <Product key={product.id} id={product.id} />
        ))}
      </Slider>
    </>
  );
}

SliderGallery.propTypes = propTypes;
SliderGallery.defaultProps = defaultProps;

export default SliderGallery;
