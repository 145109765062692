import React from "react";
import PropTypes from "prop-types";

import Button from "../Button";
import BaseInputCSV from "../../formBase/InputCSV";
import styles from "./index.module.sass";

const propTypes = {
  isError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleClear: PropTypes.func,
  placeholder: PropTypes.string,
};

const defaultProps = {
  isError: false,
  value: undefined,
  placeholder: "Click here to upload",
};

function InputCSV({ isError, onChange, handleClear, placeholder, ...props }) {
  const { value } = props;

  return (
    <div className={styles.actionGroup}>
      <label htmlFor={props.name} className={styles.fileInputWrapper}>
        <BaseInputCSV onChange={onChange} {...props} className={styles.hideFileInput} />
        <span className={styles.inputGroup}>{value.name || placeholder}</span>
      </label>
      {value && (
        <div className={styles.resetButtonWrapper}>
          <Button type="reset" onClick={handleClear}>
            remove
          </Button>
        </div>
      )}
    </div>
  );
}

InputCSV.defaultProps = defaultProps;
InputCSV.propTypes = propTypes;

export default InputCSV;
