import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { getPath, DASHBOARD_ACCOUNT_STAFF_LIST_PATH, DASHBOARD_ACCOUNT_STAFF_NEW_PATH } from "constants/routes";
import TabItem from "../TabItem";

const propTypes = {};

const defaultProps = {};

function TabItems({
  location: { pathname },
  match: {
    params: { accountId },
  },
}) {
  const tabsOptions = [
    // {
    //   id: 1,
    //   title: "Performance",
    //   url: "#",
    //   icon: null,
    //   description: "Search your brands stylists",
    //   iconClassName: "iconAnalytics",
    // },
    {
      id: 2,
      title: "User lookup",
      url: getPath(DASHBOARD_ACCOUNT_STAFF_LIST_PATH, { accountId }),
      description: "Search your brands stylists",
      iconClassName: "iconSearch",
    },
    {
      id: 3,
      title: "Add User",
      url: getPath(DASHBOARD_ACCOUNT_STAFF_NEW_PATH, { accountId }),
      description: "Search your brands stylists",
      iconClassName: "iconSearch",
    },
  ];

  return (
    <>
      {tabsOptions.map((tabsOption) => (
        <TabItem key={tabsOption.id} isActive={pathname === tabsOption.url} {...tabsOption} />
      ))}
    </>
  );
}

TabItems.defaultProps = defaultProps;
TabItems.propTypes = propTypes;

export default withRouter(TabItems);
