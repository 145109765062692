import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import { without, find, propEq, filter } from "ramda";
import cl from "classnames";

import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import ReactSelect from "components/shared/formDefault/ReactSelect";
import Input from "components/shared/formDefault/Input";
import Mask from "components/shared/formDefault/Mask";

import iconAdd from "../../Image/iconAdd.svg";
import iconClear from "../../Image/iconClear.svg";
import { validateDate } from "components/shared/formDefault/validations";

import styles from "./index.module.sass";

const propTypes = {
  references: PropTypes.shape({
    sizingInfo: PropTypes.shape({
      womenswear: PropTypes.shape({
        jean: PropTypes.arrayOf(),
      }),
    }),
  }),
  childrens: PropTypes.arrayOf(),
};

const defaultProps = {
  references: {
    sizingInfo: {
      womenswear: {
        jean: [],
      },
    },
  },
  childrens: [],
};

function Childrenswear({ references, childrens, handleAddChildren, handleDeleteChildren }) {
  function getOptions(value) {
    const options =
      value &&
      value.map((item) => {
        return { value: item, label: item };
      });
    return options;
  }
  const [newChildren, setChildren] = useState(childrens);

  function onFormSubmitHandler() {
    setChildren([...newChildren, { delete: false }]);
  }

  // function onDeleteHandler(id, index) {
  //   const deleteTask = [...newChildren];
  //   if (id) {
  //     const test = find(propEq("id", id), newChildren);
  //     // const newArray = without(id, [newChildren]);
  //     // console.log("newArray", newArray);
  //     console.log("id", id);
  //     const res = newChildren.filter((item) => item.id !== id);
  //     console.log("res", res);
  //     // setChildren(res);
  //     setChildren([...res, { ...test, delete: true }]);
  //     // setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  //   } else {
  //     console.log("!!!");
  //     // deleteTask.splice(index, 1);
  //     // setChildren(deleteTask);
  //   }
  // }

  // console.log("newChildren", newChildren);

  const removeTags = (indexToRemove) => {
    const updateChildren = [...childrens.filter((_, index) => index !== indexToRemove)];
    setChildren(updateChildren);
    handleDeleteChildren(updateChildren);
  };
  // const addTags = () => {
  //   setChildren([...newChildren, { delete: false }]);
  //   // handleAddChildren(event.target.value);
  // };

  return (
    <>
      {newChildren &&
        newChildren.map((item, index) => (
          <>
            {!item.delete && (
              <div className={styles.wrapper}>
                <img className={styles.iconClear} src={iconClear} onClick={() => removeTags(index)} />
                <div className={styles.input}>
                  <Field
                    label="Name"
                    component={FormGroupAdapter}
                    inputComponent={Input}
                    name={`sizingInfo.children.${index}.name`}
                    placeholder="First Name"
                    type="text"
                  />
                </div>
                <div className={styles.select}>
                  <Field
                    label="Preference"
                    component={FormGroupAdapter}
                    inputComponent={ReactSelect}
                    name={`sizingInfo.children.${index}.preference`}
                    placeholder="select"
                    type="select"
                    options={references.profile && references.profile.wearPreferences}
                  />
                </div>
                <div className={styles.input}>
                  <Field
                    label="Birthday"
                    component={FormGroupAdapter}
                    inputComponent={Mask}
                    name={`sizingInfo.children.${index}.birthday`}
                    placeholder="mm/dd/yy"
                    value="11/21/1111"
                    typeOfMask="date"
                    validate={validateDate()}
                  />
                </div>
                <div className={styles.select}>
                  <Field
                    label="Size"
                    component={FormGroupAdapter}
                    inputComponent={ReactSelect}
                    name={`sizingInfo.children.${index}.size`}
                    placeholder=""
                    type="select"
                    options={getOptions(references.sizingInfo && references.sizingInfo.childSizes.menswear)}
                  />
                </div>
              </div>
            )}
          </>
        ))}
      <img src={iconAdd} onClick={() => onFormSubmitHandler()} />
    </>
  );
}

Childrenswear.defaultProps = defaultProps;
Childrenswear.propTypes = propTypes;

export default Childrenswear;
