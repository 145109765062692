import React, { useState } from "react";
import PropTypes from "prop-types";

import cl from "classnames";

import { NavTab, Item, NavTabMeasurements } from "./shared";
import { Womenswear, Menswear, Childrenswear, Pets, SizingData, Men, Women } from "./blocks";

import style from "./index.module.sass";

const propTypes = {
  jobTitle: PropTypes.string,
  maritalStatus: PropTypes.object,
  profile: PropTypes.shape({
    wearPreference: PropTypes.object,
    mobileDeviceColor: PropTypes.object,
    dressCode: PropTypes.object,
    hobbies: PropTypes.arrayOf(PropTypes.string),
  }),
  sizingInfo: PropTypes.shape({
    hairColor: PropTypes.object,
    height: PropTypes.object,
    fitPreference: PropTypes.object,
    bodyShape: PropTypes.object,
    bodyLength: PropTypes.object,
    bodyWaist: PropTypes.object,
    womenswear: PropTypes.object,
    menswear: PropTypes.object,
    children: PropTypes.arrayOf(PropTypes.object),
    pets: PropTypes.arrayOf(PropTypes.object),
  }),
  deviceInfo: PropTypes.shape({
    browser: PropTypes.string,
    device: PropTypes.string,
    platform: PropTypes.string,
  }),
};
const defaultProps = {
  jobTitle: "—",
  maritalStatus: {label: "—", value: null},
  profile: {
    wearPreference: {label: "—", value: null},
    mobileDeviceColor: {label: "—", value: null},
    dressCode: {label: "—", value: null},
    hobbies: [],
  },
  sizingInfo: {
    hairColor: {label: "—", value: null},
    height: {},
    fitPreference: {label: "—", value: null},
    bodyShape: {label: "—", value: null},
    bodyLength: {label: "—", value: null},
    bodyWaist: {label: "—", value: null},
    womenswear: {},
    menswear: {},
    children: [{}],
    pets: [{}],
  },
  deviceInfo: {
    browser: "—",
    device: "—",
    platform: "—",
  },
};

function Info({ profile, jobTitle, maritalStatus, deviceInfo, sizingInfo }) {
  const [isOpen, setIsOpen] = useState("women");
  const [tabMeasurements, setTabMeasurements] = useState("womenMeasurements");
  const {
    hairColor,
    height,
    fitPreference,
    bodyShape,
    bodyLength,
    bodyWaist,
    womenswear,
    menswear,
    children,
    pets,
  } = sizingInfo;
  const { browser, device, platform } = deviceInfo;
  const { hobbies, dressCode, mobileDeviceColor, wearPreference } = profile;
  return (
    <>
      {deviceInfo && (
        <div className={cl(style.bodyWrapper, style.info)}>
          <h3 className={style.title}>PERSONAL INFO</h3>
          <ul className={style.personalList}>
            <Item label="Browser" value={browser} styleColumn />
            <Item label="Shopper's Device type" value={device} styleColumn />
            <Item label="Operating System" value={platform} styleColumn />
            <Item label="Preference" value={wearPreference && wearPreference.label} styleColumn />
            <Item label="Marital Status" value={maritalStatus && maritalStatus.label} styleColumn />
            <Item label="Hair color" value={hairColor && hairColor.label} styleColumn />
            <Item label="Dress code" value={dressCode && dressCode.label} styleColumn />
            <Item label="Hardware" value={mobileDeviceColor && mobileDeviceColor.label} styleColumn />
            <Item label="Job Title" value={jobTitle} styleColumn />
            <li className={style.infoItem}>
              <label>Est. Income</label>
              <span>—</span>
            </li>
            <li className={style.infoItem}>
              <label>Hobbies</label>
              <div className={style.hobbiesList}>
                {hobbies && hobbies.length > 0 ? (
                  hobbies.map((item, i) => (
                    <span key={i}>
                      {item}
                      {i != hobbies.length - 1 ? ", " : ""}
                    </span>
                  ))
                ) : (
                  <span>—</span>
                )}
              </div>
            </li>
          </ul>
        </div>
      )}
      <div className={cl(style.bodyWrapper, style.info)}>
        <h3 className={style.title}>SIZING data</h3>
        <SizingData
          height={height}
          fitPreference={fitPreference && fitPreference.label}
          bodyShape={bodyShape && bodyShape.label}
          bodyLength={bodyLength && bodyLength.label}
          bodyWaist={bodyWaist && bodyWaist.label}
        />
      </div>
      <div className={cl(style.bodyWrapper, style.info)}>
        <h3 className={style.title}>SIZING details</h3>
        <NavTab isOpen={isOpen} setIsOpen={setIsOpen} />
        {isOpen === "women" && <Womenswear womenswear={womenswear} />}
        {isOpen === "men" && <Menswear menswear={menswear} />}
        {isOpen === "children" && <Childrenswear children={children} />}
        {isOpen === "pet" && <Pets pets={pets} />}
      </div>
      <div className={cl(style.bodyWrapper, style.info)}>
        <h3 className={style.title}>Measurements</h3>
        <NavTabMeasurements tabMeasurements={tabMeasurements} setTabMeasurements={setTabMeasurements} />
        {tabMeasurements === "womenMeasurements" && <Women womenswear={womenswear} />}
        {tabMeasurements === "menMeasurements" && <Men menswear={menswear} />}
      </div>
    </>
  );
}

Info.propTypes = propTypes;
Info.defaultProps = defaultProps;

export default Info;
