import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { connect } from "react-redux";

import { requestInProcess } from "selectors/request";
import * as requestTypes from "constants/requestTypes";
import { getSessionUser } from "selectors/session";
import { getCurrentAccount } from "selectors/accounts";
import useOnClickOutside from "hooks/useOnClickOutside";
import {
  DASHBOARD_ACCOUNT_STAFF_LIST_PATH,
  DASHBOARD_SHOPPER_HOME_PATH,
  DASHBOARD_SHOPPER_PROFILE_PATH,
  DASHBOARD_ACCOUNT_PATH,
  DASHBOARD_ACCOUNT_SHOPPERS_PATH,
  DASHBOARD_ACCOUNT_ANALYTICS_PATH,
  DASHBOARD_ACCOUNT_STORE_PATH,
  DASHBOARD_ACCOUNT_REPORTS_PATH,
  DASHBOARD_ACCOUNT_SETTINGS_SETUP_PATH,
  DASHBOARD_ACCOUNT_SETTINGS_SETUP_EMBED_PATH,
  DASHBOARD_ACCOUNT_SETTINGS_SETUP_APPEARANCE_PATH,
  getPath,
} from "constants/routes";
import sidebar from "./sideBar.module.sass";

const propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  currentAccount: PropTypes.shape({
    powerBiUrl: PropTypes.string,
  }),
};
const defaultProps = {
  id: undefined,
  accountId: undefined,
  user: {
    role: "",
  },
  currentAccount: {},
};

function SideBar({ accountId, id, isStylist, user, isLoadingProfile, currentAccount }) {
  const { role } = user;
  const [isOpen, setIsOpen] = useState(false);

  const toggleItemClasses = cx({
    [`toggleItem`]: true,
    [`toggleItemActive`]: isOpen,
  });

  const wrapClasses = cx({
    [`${sidebar.wrapper}`]: true,
    [`${sidebar.wrapperActive}`]: isOpen,
  });

  const handleClick = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };

  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div ref={ref}>
      <div onClick={handleClick} className="toggleMenu toggleMenuLeft">
        <span className={toggleItemClasses} />
      </div>
      <aside className={wrapClasses}>
        {!isLoadingProfile && (
          <>
            {isStylist ? (
              <ul className={sidebar.nav}>
                <li onClick={() => setIsOpen(false)} className={sidebar.navItem}>
                  <NavLink
                    exact
                    className={sidebar.navLink}
                    activeClassName={sidebar.navLinkActive}
                    to={getPath(DASHBOARD_ACCOUNT_PATH, { accountId })}
                  >
                    <i className="iconSideBar iconHome" />
                    <span>home</span>
                  </NavLink>
                </li>
                <li onClick={() => setIsOpen(false)} className={sidebar.navItem}>
                  <NavLink
                    className={sidebar.navLink}
                    activeClassName={sidebar.navLinkActive}
                    to={getPath(DASHBOARD_ACCOUNT_SHOPPERS_PATH, { accountId, id })}
                  >
                    <i className="iconSideBar iconChat" />
                    <span>Chat</span>
                  </NavLink>
                </li>
                {role !== "agent" && (
                  <>
                    <li onClick={() => setIsOpen(false)} className={sidebar.navItem}>
                      <NavLink
                        exact
                        className={sidebar.navLink}
                        activeClassName={sidebar.navLinkActive}
                        to={getPath(DASHBOARD_ACCOUNT_SETTINGS_SETUP_PATH, { accountId })}
                        isActive={(match, { pathname }) => {
                          if (pathname === getPath(DASHBOARD_ACCOUNT_SETTINGS_SETUP_EMBED_PATH, { accountId }))
                            return true;

                          if (pathname === getPath(DASHBOARD_ACCOUNT_SETTINGS_SETUP_APPEARANCE_PATH, { accountId }))
                            return true;
                        }}
                      >
                        <i className="iconSideBar iconSetting" />
                        <span>Setting</span>
                      </NavLink>
                    </li>
                    <li onClick={() => setIsOpen(false)} className={sidebar.navItem}>
                      <NavLink
                        className={sidebar.navLink}
                        activeClassName={sidebar.navLinkActive}
                        to={getPath(DASHBOARD_ACCOUNT_STAFF_LIST_PATH, { accountId })}
                      >
                        <i className="iconSideBar iconStaff" />
                        <span>Staff</span>
                      </NavLink>
                    </li>
                    {currentAccount.powerBiUrl && currentAccount.powerBiUrl.length > 0 && (
                      <li className={sidebar.navItem}>
                        <a
                          className={sidebar.navLink}
                          href={currentAccount.powerBiUrl}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <i className="iconSideBar iconAnalytics" />
                          <span>Analitics</span>
                        </a>
                      </li>
                    )}
                    <li onClick={() => setIsOpen(false)} className={cx(sidebar.navItem, sidebar.navItemBottom)}>
                      <NavLink
                        exact
                        className={sidebar.navLink}
                        activeClassName={sidebar.navLinkActive}
                        to={getPath(DASHBOARD_ACCOUNT_REPORTS_PATH, { accountId })}
                      >
                        <i className="iconSideBar iconReports" />
                        <span>Reports</span>
                      </NavLink>
                    </li>
                  </>
                )}
                <li onClick={() => setIsOpen(false)} className={sidebar.navItem}>
                  <NavLink
                    exact
                    className={sidebar.navLink}
                    activeClassName={sidebar.navLinkActive}
                    to={getPath(DASHBOARD_ACCOUNT_STORE_PATH, { accountId })}
                  >
                    <i className="iconSideBar iconStore" />
                    <span>Store</span>
                  </NavLink>
                </li>
              </ul>
            ) : (
              <ul className={sidebar.nav}>
                <li onClick={() => setIsOpen(false)} className={sidebar.navItem}>
                  <NavLink
                    exact
                    className={sidebar.navLink}
                    activeClassName={sidebar.navLinkActive}
                    to={DASHBOARD_SHOPPER_HOME_PATH}
                  >
                    <i className="iconSideBar iconHome" />
                    <span>home</span>
                  </NavLink>
                </li>
                <li onClick={() => setIsOpen(false)} className={sidebar.navItem}>
                  <NavLink
                    exact
                    className={cx(`${sidebar.navLink} icon`)}
                    activeClassName={cx(`${sidebar.navLinkActive} iconActiveLink`)}
                    to={DASHBOARD_SHOPPER_PROFILE_PATH}
                  >
                    <svg
                      width="20px"
                      height="20px"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="30px"
                      y="30px"
                      viewBox="0 0 512 512"
                    >
                      <g>
                        <g>
                          <path
                            id="facebook"
                            fill="#606060"
                            d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148
			C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962
			c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216
			h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40
			c59.551,0,108,48.448,108,108S315.551,256,256,256z"
                          />
                        </g>
                      </g>
                    </svg>
                    <span>Profile</span>
                  </NavLink>
                </li>
                <li onClick={() => setIsOpen(false)} className={cx(sidebar.navItem)}>
                  <a className={cx(`${sidebar.navLink} icon`)}>
                    <i className="iconSideBar iconShop" />
                    <span>Closet</span>
                    <span className={sidebar.disabledText}>Coming soon</span>
                  </a>
                </li>
              </ul>
            )}
          </>
        )}
      </aside>
    </div>
  );
}

function mapStateToProps(state, props) {
  const { accountId } = props;

  return {
    currentAccount: getCurrentAccount(state, { accountId }),
    user: getSessionUser(state),
    isLoadingProfile: requestInProcess(state, { requestType: requestTypes.MY_PROFILE_FETCH }),
  };
}

SideBar.propTypes = propTypes;
SideBar.defaultProps = defaultProps;

export default connect(mapStateToProps, null)(SideBar);
