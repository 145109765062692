import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import cl from "classnames";

import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import ReactSelect from "components/shared/formDefault/ReactSelect";

// import styles from "./index.module.sass";

const propTypes = {
  references: PropTypes.shape({
    sizingInfo: PropTypes.shape({
      womenswear: PropTypes.shape({
        jean: PropTypes.arrayOf(),
      }),
    }),
  }),
};

const defaultProps = {
  references: {
    sizingInfo: {
      womenswear: {
        jean: [],
      },
    },
  },
};

function MenswearMeasurements({ references }) {
  function getOptions(value) {
    const options =
      value &&
      value.map((item) => {
        return { value: item, label: item };
      });
    return options;
  }

  return (
    <>
      <Field
        label="Chest"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.chest"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.chest)}
      />
      <Field
        label="Waist"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.waist"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.waist)}
      />
      <Field
        label="Inseam"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.inseam"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.inseam)}
      />
      <Field
        label="Hips"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.hip"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.hip)}
      />
      <Field
        label="Rise"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.rise"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.rise)}
      />
      <Field
        label="Head"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.head"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.head)}
      />
      {/* <Field
        label="Bust"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.bust"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.bust)}
      /> */}
      <Field
        label="Arm Length"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.armLength"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.armLength)}
      />
      <Field
        label="Sleeve Length"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.sleeveLength"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.sleeveLength)}
      />
      <Field
        label="Calf"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.calf"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.calf)}
      />
      <Field
        label="Shoulder"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.shoulder"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.shoulder)}
      />
      {/* <Field
        label="Arm"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.arm"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.arm)}
      /> */}
      <Field
        label="Ankle"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.ankle"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.ankle)}
      />
      <Field
        label="Wrist"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.wrist"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.wrist)}
      />
      {/* <Field
        label="Bicep"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.bicep"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.bicep)}
      /> */}
      <Field
        label="Knee"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.knee"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswearMeasurements.knee)}
      />
    </>
  );
}

MenswearMeasurements.defaultProps = defaultProps;
MenswearMeasurements.propTypes = propTypes;

export default MenswearMeasurements;
