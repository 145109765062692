import React from "react";

import useFormGroupAdapter from "hooks/useFormGroupAdapter";

import FormGroup from "./index";

function Adapter(props) {
  const [groupProps, inputProps] = useFormGroupAdapter(props);
  const { isInfo, titleInfo, smInput, margin } = props;
  return (
    <FormGroup
      isInfo={isInfo}
      smInput={smInput}
      margin={margin}
      titleInfo={titleInfo}
      {...groupProps}
      inputProps={inputProps}
    />
  );
}

export default Adapter;
