import { FORM_ERROR } from "final-form";
import { objectToFormData } from "object-to-formdata";
import * as R from "ramda";
import { getIn } from "./ramda";

import { decamelizeKeys, camelizeKeys } from "./humps";

import {
  currencyMask,
  decimalCurrencyMask,
  decimalNumberMask,
  numberMask,
  percentMask,
  phoneMask,
  dateMask,
  ssnMask,
  cvvMask,
} from "./masks";

export function throwSubmissionError(response) {
  const { title, source } = getIn("errors", response) || {};

  let errors = {};

  if (title && source === undefined) {
    errors = { ...errors, [FORM_ERROR]: title };
  }

  if (source && typeof source === "string") {
    errors = { ...errors, [FORM_ERROR]: source };
    // errorNotification(source);
  }

  if (source && typeof source !== "string") {
    const camelizedSource = camelizeKeys(source);
    errors = { ...errors, ...camelizedSource };
  }

  return errors;
}

export function throwDataSubmissionError(error) {
  if (R.path(["response", "status"], error) === 400) {
    return R.path(["response", "data"], error);
  }
  return undefined;
}

export const getName = (nameSpace, name) => (nameSpace ? `${nameSpace}.${name}` : name);

export const percentParse = (v) => (parseInt(v, 10) >= 100 ? "100%" : v);
export const upperLettersOnlyParse = (value) => value.toUpperCase().match(/[A-Z]+/g);

export const getMask = (mask, typeOfMask) => {
  if (mask) {
    if (typeof mask === "string") {
      const arrMask = mask.split("").map((char) => {
        if (char === "9") return /\d/;
        if (char === "a") return /[a-zA-Z]/;
        return char;
      });
      return arrMask;
    }
    return mask;
  }

  switch (typeOfMask) {
    case "currency":
      return currencyMask;
    case "decimalCurrency":
      return decimalCurrencyMask;
    case "decimalNumber":
      return decimalNumberMask;
    case "number":
      return numberMask;
    case "percent":
      return percentMask;
    case "phone":
      return phoneMask;
    case "date":
      return dateMask;
    case "ssn":
      return ssnMask;
    case "cvv":
      return cvvMask;
    default:
      return undefined;
  }
};

export const getPlaceholder = (placeholder, typeOfMask) => {
  if (placeholder) return placeholder;
  switch (typeOfMask) {
    case "phone":
      return "(123) 999-9999";
    case "currency":
      return "";
    case "decimalCurrency":
      return "";
    case "percent":
      return "0%";
    case "date":
      return "__/__/____";
    case "ssn":
      return "__-__-____";

    default:
      return undefined;
  }
};

export const hasCapitalLetterPred = R.test(/[A-Z]+/);
export const hasLowerCaseLetterPred = R.test(/[a-z]+/);
export const hasSpecialCharacterPred = R.test(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+/);
export const hasDigitPred = R.test(/[0-9]+/);
export const passwordsMustBeTheSamePred = R.equals;

export const decamelizeObjectToFormData = (obj) => objectToFormData(decamelizeKeys(obj));

export function immutableFormData(data) {
  const newData = new FormData();
  if (data) {
    for (const [n, v] of data) {
      newData.append(n, v);
    }
  }

  return newData;
}
