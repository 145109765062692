import { filter, contains, isEmpty } from "ramda";

const mutators = {
  clearEmblemField: ([callBack], state, { changeValue }) => {
    changeValue(state, "emblem", () => {
      callBack();
      return null;
    });
  },
  clearLogoField: ([callBack], state, { changeValue }) => {
    changeValue(state, "logo", () => {
      callBack();
      return null;
    });
  },
  // addDomainName: ([domainName], state, { changeValue }) => {
  //   const domainNames = state.formState.values.contact.domainNames || [];
  //   changeValue(state, "contact.domainName", () => null);
  //   if (!contains(domainName, domainNames) && !isEmpty(domainName)) {
  //     changeValue(state, "contact.domainNames", () => {
  //       return [...domainNames, domainName];
  //     });
  //   }
  // },
  // deleteDomainName: ([domainName], state, { changeValue }) => {
  //   changeValue(state, "contact.domainNames", () => {
  //     const domainNames = state.formState.values.contact.domainNames || [];
  //     return filter((domainNameOwn) => domainNameOwn !== domainName, domainNames);
  //   });
  // },
};

export default mutators;
