import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cl from "classnames";
import * as actions from "actions";
import { connect } from "react-redux";
import actionCable from "actioncable";
import { requestInProcess } from "selectors/request";
import * as requestTypes from "constants/requestTypes";
import { reverse } from "ramda";

import { getSessionData } from "utils/sessionData";
import { getActivityEvents } from "selectors/accounts";
import style from "./style.module.sass";
import Item from "./Item";

const propTypes = {
  accountId: PropTypes.string,
  id: PropTypes.string,
  fetchShopperCurrentEvents: PropTypes.func.isRequired,
  eventsCollection: PropTypes.arrayOf(PropTypes.object),
};
const defaultProps = {
  accountId: "",
  id: "",
  eventsCollection: [{}],
};

function ActivityStream({ fetchShopperCurrentEvents, accountId, id, eventsCollection, isLoadingEvents }) {
  const [connection, setConnection] = useState(0);
  useEffect(() => {
    fetchShopperCurrentEvents(accountId, id);

    const CableApp = {};
    const { accessToken } = getSessionData();
    CableApp.cable = actionCable.createConsumer(`${process.env.CABLE_URL}?access_token=${accessToken}`);
    const consumer = CableApp.cable.subscriptions.create(
      {
        channel: "Account::ActivityStream",
        account_id: accountId,
        source: "dashboard",
      },
      {
        received: (res) => {
          const { action, data, contactId } = res;
          // if (action === "message") {
          //   addMessageToChat(data);
          // }
        },
      },
    );

    setConnection(consumer);
  }, []);

  if (isLoadingEvents) {
    return null;
  }
  return (
    <div className={style.wrapper}>
      <h3 className={style.title}>Activity Stream</h3>
      {eventsCollection && eventsCollection[0] && eventsCollection[0].product ? (
        <ul className={style.list}>
          {reverse(eventsCollection).map((event, i) => (
            <Item event={event} key={i} />
          ))}
        </ul>
      ) : (
        <p className={style.noEvents}>No events</p>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    eventsCollection: getActivityEvents(state),
    isLoadingEvents: requestInProcess(state, { requestType: requestTypes.ACCOUNTS_SHOPPER_CURRENT_EVENTS }),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchShopperCurrentEvents: (accountId, id) => dispatch(actions.fetchShopperCurrentEvents(accountId, id)),
});
ActivityStream.propTypes = propTypes;
ActivityStream.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ActivityStream);
