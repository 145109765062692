// import * as R from "ramda";
// import { without } from "ramda";
import { without, uniq, uniqBy, prepend, append } from "ramda";
import * as actionTypes from "constants/actionTypes";
import { initialState } from "selectors/accounts";

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACCOUNTS_FETCH_SUCCESS:
      return {
        ...state,
        collection: action.payload,
      };

    case actionTypes.ACCOUNT_CHAT_MESSAGES_FETCH_SUCCESS:
      return {
        ...state,
        messages: action.payload,
      };

    case actionTypes.ACCOUNT_APPEARANCE_FETCH_SUCCESS:
      return {
        ...state,
        appearance: action.payload,
      };

    case actionTypes.ACCOUNT_CHAT_MESSAGES_LOAD_FETCH_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          data: [...state.messages.data, ...action.payload.data],
          meta: action.payload.meta,
        },
      };

    case actionTypes.ADD_MESSAGE_TO_CHAT_SUCCESS:
      const data = state.messages.data || [];

      return {
        ...state,
        messages: {
          ...state.messages,
          // data: [...data, action.payload],
          data: prepend(action.payload, [...data]),
        },
      };

    case actionTypes.ACCOUNTS_SHOPPER_CURRENT_EVENTS_SUCCESS:
      return {
        ...state,
        activityEvents: action.payload,
      };

    case actionTypes.ADD_ACTIVITY_EVENT_SUCCESS:
      const collection = state.activityEvents.data || [];

      return {
        ...state,
        activityEvents: {
          ...state.activityEvents,
          // data: [action.payload],
          data: append(action.payload, [...collection]),
        },
      };
    case actionTypes.ACCOUNTS_INSTAGRAM_FEED_FETCH_SUCCESS:
      return {
        ...state,
        instagramFeed: action.payload,
      };

    case actionTypes.ACCOUNTS_CONSULTANT_SUCCESS:
      return {
        ...state,
        consultant: action.payload,
      };

    case actionTypes.ACCOUNTS_COUNT_MESSAGE_SUCCESS:
      return {
        ...state,
        countMessages: [
          ...state.countMessages,
          {
            id: action.payload,
          },
        ],
      };

    case actionTypes.ACCOUNTS_REMOVE_COUNT_MESSAGE_SUCCESS:
      return {
        ...state,
        countMessages: action.payload,
      };

    case actionTypes.ACCOUNTS_REMOVE_STATE_SUCCESS:
      return {};

    default:
      return state;
  }
};
