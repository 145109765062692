import React from "react";
import PropTypes from "prop-types";
import { Switch, Redirect, Route, NavLink } from "react-router-dom";
import cl from "classnames";

import { Appearance, ChatWidget, Integrate } from "../blocks";
import setting from "../setting.module.sass";
import {
  DASHBOARD_ACCOUNT_SETTINGS_SETUP_INTEGRATE_PATH,
  DASHBOARD_ACCOUNT_SETTINGS_SETUP_EMBED_PATH,
  DASHBOARD_ACCOUNT_SETTINGS_SETUP_APPEARANCE_PATH,
  DASHBOARD_ACCOUNT_SETTINGS_SETUP_PATH,
  getPath,
} from "../../../constants/routes";

const propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
const defaultProps = {
  accountId: null,
};

function SettingsTabs({ accountId }) {
  return (
    <div className={setting.content}>
      <div className={setting.tabs}>
        {/* <NavLink
          exact
          className={setting.tabLink}
          activeClassName={setting.tabLinkActive}
          to={getPath(DASHBOARD_ACCOUNT_SETTINGS_SETUP_INTEGRATE_PATH, { accountId })}
        >
          Integrate
        </NavLink> */}
        <NavLink
          exact
          className={setting.tabLink}
          activeClassName={setting.tabLinkActive}
          to={getPath(DASHBOARD_ACCOUNT_SETTINGS_SETUP_EMBED_PATH, { accountId })}
        >
          Chat Widget
        </NavLink>
        <NavLink
          exact
          className={setting.tabLink}
          activeClassName={setting.tabLinkActive}
          to={getPath(DASHBOARD_ACCOUNT_SETTINGS_SETUP_APPEARANCE_PATH, { accountId })}
        >
          Appearance
        </NavLink>
      </div>
      <Switch>
        <Route
          path={DASHBOARD_ACCOUNT_SETTINGS_SETUP_PATH}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: getPath(DASHBOARD_ACCOUNT_SETTINGS_SETUP_EMBED_PATH, { accountId }),
                state: { from: location },
              }}
            />
          )}
          exact
        />
        <Route path={DASHBOARD_ACCOUNT_SETTINGS_SETUP_INTEGRATE_PATH} component={Integrate} exact />
        <Route path={DASHBOARD_ACCOUNT_SETTINGS_SETUP_EMBED_PATH} component={ChatWidget} exact />
        <Route path={DASHBOARD_ACCOUNT_SETTINGS_SETUP_APPEARANCE_PATH} component={Appearance} exact />
      </Switch>
    </div>
  );
}

SettingsTabs.propTypes = propTypes;
SettingsTabs.defaultProps = defaultProps;

export default SettingsTabs;
