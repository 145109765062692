import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  baseClass: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  baseClass: undefined,
  className: undefined,
};

function Checkbox({ onChange, name, className, baseClass, ...props }) {
  return <input name={name} onChange={onChange} type="checkbox" className={className} {...props} />;
}

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
