import React, { useState, useEffect } from "react";
import cl from "classnames";
import { useDrag } from "react-dnd";

import editor from "./editor.module.sass";

const propTypes = {};
const defaultProps = {};

function EditorItem(props) {
  const { product, index, provided, isDropped, onRemoveProductEditor, isDrop } = props;
  const { id, featuredImage, formattedPrice } = product;
  const [{ opacity }, drag] = useDrag({
    item: { product, type: "product" },
    options: {
      dropEffect: true ? "copy" : "move",
    },
    end: (item, monitor, index) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onRemoveProductEditor(item.product.id);
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  return (
    <>
      <li data-name="productCart" key={index} ref={drag} style={{ opacity }} className={editor.item}>
        <div className={editor.dragWrapper} />
        <i className="iconRemoveEditor" onClick={() => onRemoveProductEditor(id)}></i>
        <div className={editor.imgBlock}>
          <img className={editor.img} src={featuredImage.src} alt="product img" />
        </div>
        <div>
          <p className={editor.itemPrice}>{formattedPrice}</p>
        </div>
      </li>
    </>
  );
}

EditorItem.propTypes = propTypes;
EditorItem.defaultProps = defaultProps;

export default EditorItem;
