import React from "react";
import PropTypes from "prop-types";

import cl from "classnames";

import { Item } from "../../shared";
import styles from "../../index.module.sass";

const propTypes = {
  menswear: PropTypes.shape({
    jean: PropTypes.string,
    usShoe: PropTypes.string,
    euShoe: PropTypes.string,
    shoeWidth: PropTypes.string,
    shirtSize: PropTypes.string,
    alphaTops: PropTypes.string,
    bottoms: PropTypes.string,
    alphaBottoms: PropTypes.string,
    belt: PropTypes.string,
    undies: PropTypes.string,
    ring: PropTypes.string,
    hat: PropTypes.string,
    suitSize: PropTypes.string,
    neck: PropTypes.string,
    blazer: PropTypes.string,
  }),
};

const defaultProps = {
  menswear: {
    jean: "",
    usShoe: "",
    euShoe: "",
    shoeWidth: "",
    shirtSize: "",
    alphaTops: "",
    bottoms: "",
    alphaBottoms: "",
    belt: "",
    undies: "",
    ring: "",
    hat: "",
    suitSize: "",
    neck: "",
    blazer: "",
  },
};

function Menswear({ menswear }) {
  const {
    jean,
    usShoe,
    euShoe,
    shoeWidth,
    shirtSize,
    alphaTops,
    bottoms,
    alphaBottoms,
    belt,
    undies,
    ring,
    hat,
    suitSize,
    neck,
    blazer,
  } = menswear;
  return (
    <ul className={styles.sizingDetails}>
      <Item label="Jeans" value={jean} />
      <Item label="US Shoe" value={usShoe} />
      <Item label="EU Shoe" value={euShoe} />
      <Item label="Shoe Width" value={shoeWidth} />
      <Item label="Shirt Size" value={shirtSize} />
      <Item label="Alpha Tops" value={alphaTops} />
      <Item label="Bottoms" value={bottoms} />
      <Item label="Alpha Bottoms" value={alphaBottoms} />
      <Item label="Belt" value={belt} />
      <Item label="Undies" value={undies} />
      <Item label="Ring" value={ring} />
      <Item label="Hat" value={hat} />
      <Item label="SuitSize" value={suitSize} />
      <Item label="Neck" value={neck} />
      <Item label="Blazer" value={blazer} />
    </ul>
  );
}

Menswear.defaultProps = defaultProps;
Menswear.propTypes = propTypes;

export default Menswear;
