import React from "react";
import PropTypes from "prop-types";

import cl from "classnames";

import { Item } from "../../../shared";
import styles from "../../../index.module.sass";

const propTypes = {
  womenswear: PropTypes.shape({
    bust: PropTypes.string,
    waist: PropTypes.string,
    inseam: PropTypes.string,
    hip: PropTypes.string,
    rise: PropTypes.string,
    upperBust: PropTypes.string,
    underBust: PropTypes.string,
    highHip: PropTypes.string,
    sleeveLength: PropTypes.string,
    calf: PropTypes.string,
    shoulder: PropTypes.string,
    armLength: PropTypes.string,
    wrist: PropTypes.string,
  }),
};

const defaultProps = {
  womenswear: {
    bust: "",
    waist: "",
    inseam: "",
    hip: "",
    rise: "",
    upperBust: "",
    underBust: "",
    highHip: "",
    sleeveLength: "",
    calf: "",
    shoulder: "",
    armLength: "",
    wrist: "",
  },
};

function Women({ womenswear }) {
  const {
    bust,
    waist,
    inseam,
    hip,
    rise,
    upperBust,
    underBust,
    highHip,
    sleeveLength,
    calf,
    shoulder,
    armLength,
    wrist,
  } = womenswear;
  return (
    <ul className={styles.sizingDetails}>
      <Item label="Bust" value={bust} />
      <Item label="Waist" value={waist} />
      <Item label="Inseam" value={inseam} />
      <Item label="Hips" value={hip} />
      <Item label="Rise" value={rise} />
      <Item label="Upper Bust" value={upperBust} />
      <Item label="Under Bust" value={underBust} />
      <Item label="High Hip" value={highHip} />
      <Item label="Sleeve Length" value={sleeveLength} />
      <Item label="Calf" value={calf} />
      <Item label="Shoulder" value={shoulder} />
      <Item label="Arm Length" value={armLength} />
      <Item label="Wrist" value={wrist} />
    </ul>
  );
}

Women.defaultProps = defaultProps;
Women.propTypes = propTypes;

export default Women;
