import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { map, find, propEq, filter, contains, isNil } from "ramda";
import * as actions from "actions";
import cl from "classnames";
import setFieldData from "final-form-set-field-data";

import { REFERENCES_PATH } from "constants/routes";
import { requestInProcess } from "selectors/request";
import * as requestTypes from "constants/requestTypes";
import fetch from "apiAdapters/config/fetch";
import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import Input from "components/shared/formDefault/Input";
import InputFile from "components/shared/formDefault/InputFile";
import InputPhotoFile from "components/shared/formDefault/InputPhotoFile";
import Mask from "components/shared/formDefault/Mask";
import ReactSelect from "components/shared/formDefault/ReactSelect";
import Switch from "components/shared/formDefault/Switch";
import PhoneAdapter from "components/shared/formDefault/PhoneAdapter";
import RadioGroup from "components/shared/formDefault/RadioGroup";
import ErrorMessage from "components/shared/formDefault/ErrorMessage";
import Button from "components/shared/formDefault/Button";
import { getShopperData } from "selectors/shopperProfile";

import {
  composeValidators,
  validateRequired,
  validatePhone,
  validateDate,
  validateCheckbox,
  validateEmail,
  validateImage,
} from "components/shared/formDefault/validations";
import SizingDetails from "./FormBlocks/SizingDetails";
import AutoSave from "./AutoSave";

import {
  FormBlock,
  FieldsCollection,
  BtnGroup,
  DataCompliance,
  NavTab,
  Womenswear,
  WomenswearMeasurements,
  Menswear,
  MenswearMeasurements,
  Childrenswear,
  Pets,
  PasswordUpdate,
  SelectedItems,
  Pereferences,
  PersonalInfo,
  TagsInput,
  Social,
  Photos,
  Avatar,
} from "./shared";

import { languagesOptions, hobbiesOptions, levelsOptions } from "./shared/mockData";

import styles from "./style.module.sass";

const propTypes = {
  fetchShopperProfile: PropTypes.func,
  initialValues: PropTypes.any,
  isLoadingShoppers: PropTypes.bool,
  onSubmitProfileData: PropTypes.func,
  shopper: PropTypes.shape({
    profile: PropTypes.shape({
      hobbies: PropTypes.array,
    }),
  }),
};

const defaultProps = {
  shopper: {
    profile: {
      hobbies: [],
    },
  },
};

const testOptions = [
  { value: 23, label: "49ers" },
  { value: 58, label: "76ers" },
  { value: 13, label: "Bears" },
  { value: 15, label: "Bengals" },
  { value: 3, label: "Bills" },
];

const radioOptions = [
  { value: "No", label: "No" },
  { value: "Yes", label: "Yes" },
];

function Profile(props) {
  const { onSubmitProfileData, fetchShopperProfile, shopper, initialValues, isLoadingShoppers } = props;
  const [isOpen, setIsOpen] = useState("women");
  useEffect(() => {
    fetchShopperProfile();
    getReferences();
  }, []);

  localStorage.setItem("startingURL", "/shopper/profile");
  const [references, setReferences] = useState({});

  function getOptions(value) {
    const options =
      value &&
      value.map((item) => {
        return { value: item, label: item };
      });
    return options;
  }

  // вынести в action, добавить селектор и сделать дефолтные пропсы!!!
  const getReferences = async () => {
    const response = await fetch(REFERENCES_PATH);
    setReferences(response.data);
  };

  const PhoneComponent = (props) => <PhoneAdapter {...props} />;

  const wrapperClasses = cl({
    [`${styles.wrapper}`]: true,
    [`${styles.opacity}`]: isLoadingShoppers,
  });

  // ______________________________________________
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const save = async (values) => {
    onSubmitProfileData(values);
    await sleep(1000);
  };

  function stateOptions() {
    const options = references.usStates || [];
    const optionsWithBlank = [{ label: "None", value: "" }, ...options];
    return optionsWithBlank;
  }

  return (
    <div className={wrapperClasses}>
      <div className={styles.profilePage}>
        <Form
          initialValues={initialValues}
          mutators={{
            setFieldData,
            clearAvataField: ([callBack], state, { changeValue }) => {
              changeValue(state, "avatar", () => {
                callBack();
                onSubmitProfileData({
                  ...state.formState.values,
                  avatar: null,
                });
                return null;
              });
            },
            clearPhoto: ([callBack], state, { changeValue }) => {
              changeValue(state, "photos", () => {
                callBack();
                console.log(state);
                onSubmitProfileData({
                  ...state.formState.values,
                  photos: [
                    ...state.formState.values.photos,
                    {
                      ...state.formState.values.photos[0],
                      destroy: true,
                    },
                  ],
                });
                return null;
              });
            },
            addLanguage: ([language], state, { changeValue }) => {
              changeValue(state, "profile.languages", () => {
                const languages = state.formState.values.profile.languages || [];
                return [...languages, language];
              });
            },
            deleteLanguage: ([languageId], state, { changeValue }) => {
              changeValue(state, "profile.languages", () => {
                const languagesIds = state.formState.values.profile.languages || [];
                const newLanguages = filter((id) => id !== languageId, languagesIds);
                onSubmitProfileData({
                  ...state.formState.values,
                  profile: { ...state.formState.values.profile, languages: newLanguages },
                });
                return newLanguages;
              });
            },
            addHobbie: ([hobbie], state, { changeValue }) => {
              changeValue(state, "profile.hobbies", () => {
                const hobbies = state.formState.values.profile.hobbies || [];
                const newHobbies = [...hobbies, hobbie];
                onSubmitProfileData({
                  ...state.formState.values,
                  profile: { ...state.formState.values.profile, hobbies: newHobbies },
                });
                return newHobbies;
              });
            },
            deleteHobbie: ([hobbie], state, { changeValue }) => {
              changeValue(state, "profile.hobbies", () => {
                onSubmitProfileData({
                  ...state.formState.values,
                  profile: { ...state.formState.values.profile, hobbies: hobbie },
                });
                return hobbie;
              });
            },
            deleteChildren: ([children], state, { changeValue }) => {
              changeValue(state, "sizingInfo.children", () => {
                onSubmitProfileData({
                  ...state.formState.values,
                  sizingInfo: { ...state.formState.values.sizingInfo, children },
                });
                return children;
              });
            },
            deletePet: ([pets], state, { changeValue }) => {
              changeValue(state, "sizingInfo.pets", () => {
                onSubmitProfileData({
                  ...state.formState.values,
                  sizingInfo: { ...state.formState.values.sizingInfo, pets },
                });
                return pets;
              });
            },
          }}
          onSubmit={save}
          render={({ handleSubmit, hasSubmitErrors, submitError, submitting, form, form: { mutators }, values }) => (
            <form
              // onSubmit={handleSubmit}
              onSubmit={handleSubmit}
              onKeyPress={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
              className={styles.formContainer}
            >
              <AutoSave debounce={1000} setFieldData={mutators.setFieldData} save={save} />
              <div className={styles.formBlocks}>
                <FormBlock>
                  <FieldsCollection title="PROFILE PHOTO">
                    <div className={styles.photoWrapper}>
                      {/* <Field
                        component={FormGroupAdapter}
                        inputComponent={InputFile}
                        name="avatar"
                        type="file"
                        form={form}
                        handleClear={mutators.clearAvataField}
                        validate={validateImage()}
                      /> */}
                      <Avatar shopper={shopper} handleClear={() => mutators.clearAvataField} />
                      <Photos shopper={shopper} />
                    </div>
                  </FieldsCollection>
                  <FieldsCollection title="BASIC INFO">
                    <div style={{ width: "100%" }}>
                      <>
                        <Field
                          label="First Name"
                          component={FormGroupAdapter}
                          inputComponent={Input}
                          name="firstName"
                          placeholder="First Name"
                          type="text"
                          className="inputFlexFill"
                          validate={validateRequired()}
                        />
                      </>

                      <>
                        <Field
                          label="Last Name"
                          component={FormGroupAdapter}
                          inputComponent={Input}
                          name="lastName"
                          placeholder="Last Name"
                          type="text"
                          className="inputFlexFill"
                          validate={validateRequired()}
                        />
                      </>

                      <Field
                        label="Preference"
                        component={FormGroupAdapter}
                        inputComponent={ReactSelect}
                        name="profile.wearPreference.value"
                        placeholder="Preferences"
                        type="select"
                        // mutators={{ setFieldData }}
                        className="inputFlexFill"
                        options={references.profile && references.profile.wearPreferences}
                      />

                      <Field
                        label="Country"
                        className="inputFlexFill"
                        component={FormGroupAdapter}
                        inputComponent={ReactSelect}
                        name="country"
                        options={references.groupedCountries}
                        placeholder="Country"
                        type="select"
                      />

                      <Field
                        label="State"
                        className="inputFlexFill"
                        component={FormGroupAdapter}
                        inputComponent={ReactSelect}
                        name="state"
                        options={stateOptions()}
                        placeholder="State"
                        type="select"
                        parse={(value) => (value === "" ? null : value)}
                      />

                      <Field
                        label="City"
                        component={FormGroupAdapter}
                        inputComponent={Input}
                        name="city"
                        placeholder="City"
                        className="inputFlexFill"
                        type="text"
                        parse={(value) => (value === "" ? null : value)}
                      />

                      <Field
                        label="Timezone"
                        component={FormGroupAdapter}
                        inputComponent={ReactSelect}
                        name="timeZone"
                        placeholder="Timezone"
                        type="select"
                        className="inputFlexFill"
                        options={references.timezones}
                      />

                      <Field
                        label="Languages"
                        component={FormGroupAdapter}
                        inputComponent={ReactSelect}
                        name="profile.languages"
                        placeholder="select"
                        type="select"
                        onChange={mutators.addLanguage}
                        className="inputFlexFill"
                        parse={(value) => (value === "" ? null : value)}
                        options={filter(
                          (testOption) =>
                            !contains(testOption.value, (values.profile && values.profile.languages) || []),
                          languagesOptions,
                        )}
                      />

                      <FormSpy subscription={{ languages: true }}>
                        {(props) => {
                          const {
                            values: { profile },
                          } = props.form.getState();
                          return (
                            <SelectedItems
                              items={map(
                                (selectedItemId) => find(propEq("value", selectedItemId), languagesOptions),
                                (profile && profile.languages) || [],
                              )}
                              handleDelete={mutators.deleteLanguage}
                            />
                          );
                        }}
                      </FormSpy>
                    </div>
                  </FieldsCollection>
                  <FieldsCollection title="Account Info">
                    <Field
                      label="Login Email"
                      component={FormGroupAdapter}
                      inputComponent={Input}
                      name="email"
                      placeholder="Email"
                      type="email"
                      isInfo
                      titleInfo="We’ll only email you transactional emails like; reset password; we hate email too!"
                      className="inputFlexFill"
                      disabled
                    />
                    <Field
                      label="Mobile Number"
                      component={PhoneComponent}
                      inputComponent={Input}
                      name="phone"
                      parse={(value) => (isNil(value) ? null : value)}
                      isInfo
                      titleInfo="It’s your data, take it with you and unlock your preferences whenever you want with Luxlock. Personalized service, not ads. We hate ads!"
                      className="inputFlexFill"
                      validate={validatePhone()}
                    />
                    <Field
                      label="Style Id"
                      component={FormGroupAdapter}
                      inputComponent={Input}
                      name="id"
                      placeholder="Style Id"
                      type="text"
                      isInfo
                      titleInfo="This is your special Luxlock Style ID that is only yours; all your preferences will aggregate so we can help brands spoil you instead of advertising to you."
                      className="inputFlexFill"
                      disabled
                    />
                    <PasswordUpdate />
                  </FieldsCollection>
                  <FieldsCollection
                    title="Pereferences"
                    subtitle="Say it once, use it as you wish"
                    paragraph="Your preferences power your Luxlock recommendation engine and they can be shared with a stylist for premium service. This is data-on-demand, so you don’t have to repeat yourself to get personalized service across all the brands that use Luxlock. We believe your shopping experiences should be as unique as you are."
                  >
                    <Pereferences {...props} references={references} />
                  </FieldsCollection>
                  <FieldsCollection title="INTERESTS & HOBBIES">
                    {/* <InfoBlock title="It’s your data, take it with you and unlock your preferences whenever you want with Luxlock. Personalized service, not ads. We hate ads!" /> */}
                    <Switch
                      title="I am an Equinox member"
                      radioToggle
                      isInfo
                      description="We’re working on a partnership and want to make sure you get spoiled!"
                      options={[
                        { title: "No", value: "false", name: "profile.equinoxMember" },
                        { title: "Yes", value: "true", name: "profile.equinoxMember" },
                      ]}
                    />
                    {shopper.profile && (
                      <TagsInput
                        label="I enjoy"
                        placeholder="Type hobbies"
                        type="select"
                        tags={shopper.profile && shopper.profile.hobbies}
                        handleAddHobbie={mutators.addHobbie}
                        handleDeleteHobbie={mutators.deleteHobbie}
                      />
                    )}
                  </FieldsCollection>
                </FormBlock>

                <FormBlock>
                  <FieldsCollection
                    title="Find my data"
                    paragraph="Verify additional email addresses and we’ll sync your data when we find it and provide you a copy. Luxlock will automatically build your digital closet . Don’t worry, we will never sell your data or use it for advertising purposes."
                  >
                    <Field
                      label="Enter Email Address"
                      component={FormGroupAdapter}
                      inputComponent={Input}
                      name="emailAdress"
                      placeholder="email"
                      className="inputFlexFill"
                      type="text"
                      disabled
                    />
                  </FieldsCollection>

                  <FieldsCollection title="CCPA/GDPR Data Compliance">
                    <DataCompliance shopper={shopper} />
                    <Switch
                      title="I am a California resident"
                      radioToggle
                      options={[
                        { title: "No", value: "false", name: "profile.californiaResident" },
                        { title: "Yes", value: "true", name: "profile.californiaResident" },
                      ]}
                    />
                    <Switch
                      title="I reside in the EU"
                      radioToggle
                      options={[
                        { title: "No", value: "false", name: "profile.euResident" },
                        { title: "Yes", value: "true", name: "profile.euResident" },
                      ]}
                    />
                  </FieldsCollection>
                  <FieldsCollection title="Personal info">
                    <div style={{ width: "100%" }}>
                      <PersonalInfo {...props} references={references} />
                    </div>
                  </FieldsCollection>
                  <FieldsCollection
                    title="Sizing"
                    subtitle="Sizing data and convertions"
                    paragraph="We’re fit masters, but we have to start somewhere. Tell us once so we can make sure you’re buying the right size with each brand. Your shopping assistant will have access to your sizing only when you are actively shopping. The more information you share with Luxlock, the better the fit and recommendation engine you build."
                  >
                    <div className={styles.heightWrapper}>
                      <span>Height</span>
                      <Field
                        component={FormGroupAdapter}
                        inputComponent={ReactSelect}
                        name="sizingInfo.height.foot"
                        placeholder="Ft"
                        type="select"
                        smInput
                        foot
                        margin={0}
                        options={getOptions(references.sizingInfo && references.sizingInfo.heights.foots)}
                      />
                      <Field
                        component={FormGroupAdapter}
                        inputComponent={ReactSelect}
                        name="sizingInfo.height.inch"
                        placeholder="In"
                        type="select"
                        smInput
                        inch
                        margin={0}
                        options={getOptions(references.sizingInfo && references.sizingInfo.heights.inches)}
                      />
                    </div>

                    <Field
                      label="Fit Preference"
                      component={FormGroupAdapter}
                      inputComponent={ReactSelect}
                      name="sizingInfo.fitPreference.value"
                      placeholder="select"
                      className="inputFlex"
                      type="select"
                      options={references.sizingInfo && references.sizingInfo.fitPreferences}
                    />

                    <Field
                      label="Shape"
                      component={FormGroupAdapter}
                      inputComponent={ReactSelect}
                      name="sizingInfo.bodyShape.value"
                      placeholder="select"
                      className="inputFlex"
                      type="select"
                      options={references.sizingInfo && references.sizingInfo.bodyShapes}
                    />
                    <Field
                      label="Length"
                      component={FormGroupAdapter}
                      inputComponent={ReactSelect}
                      name="sizingInfo.bodyLength.value"
                      placeholder="select"
                      className="inputFlex"
                      type="select"
                      options={references.sizingInfo && references.sizingInfo.bodyLengths}
                    />
                    <Field
                      label="Waist"
                      component={FormGroupAdapter}
                      inputComponent={ReactSelect}
                      name="sizingInfo.bodyWaist.value"
                      placeholder="select"
                      className="inputFlex"
                      type="select"
                      options={references.sizingInfo && references.sizingInfo.bodyWaists}
                    />
                  </FieldsCollection>
                  <SizingDetails shopper={initialValues} references={references} />
                </FormBlock>
              </div>
              {/* {hasSubmitErrors && submitError && ( */}
              <div className={styles.errorMessageContainer}>
                <ErrorMessage>{submitError}</ErrorMessage>
              </div>
              {/* )} */}
              {/* <div className={styles.btnFooter}>
                <Button type="submit" disable={submitting}>
                  {submitting ? "saving..." : "save"}
                </Button>
              </div> */}
            </form>
          )}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const shopperData = state.shopperProfile.data || {};
  const { profile, sizingInfo } = shopperData;
  return {
    isLoadingShoppers: requestInProcess(state, { requestType: requestTypes.SHOPPER_PROFILE_FETCH }),
    shopper: getShopperData(state),
    initialValues: {
      ...shopperData,
      profile: {
        ...profile,
        californiaResident: (profile && profile.californiaResident) || "false",
        euResident: (profile && profile.euResident) || "false",
        personalStylistBefore: (profile && profile.personalStylistBefore) || "false",
        jeansGoal: (profile && profile.jeansGoal) || "false",
        sustainableProductsFan: (profile && profile.sustainableProductsFan) || "false",
        equinoxMember: (profile && profile.equinoxMember) || "false",
      },
      sizingInfo: {
        ...sizingInfo,
        height: {
          foot: (sizingInfo && sizingInfo.height && sizingInfo.height.foot) || "",
          inch: (sizingInfo && sizingInfo.height && sizingInfo.height.inch) || "",
        },
        // womenswear: {
        //   jean: (sizingInfo && sizingInfo.womenswear && sizingInfo.womenswear.jean) || "",
        // },
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchShopperProfile: () => dispatch(actions.fetchShopperProfile()),
  onSubmitProfileData: (props) => dispatch(actions.postProfileData(props)),
});

Profile.defaultProps = defaultProps;
Profile.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));
