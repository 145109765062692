import React from "react";
import PropTypes from "prop-types";

import footer from "./footer.module.sass";

const defaultProps = {
  className: null,
};

const propTypes = {
  className: PropTypes.string,
};

function Footer({ className }) {
  return (
    <footer className={className}>
      Copyright 2018 
      <a href="https://luxlock.com/" className={footer.link}>
        FullStack Retail
      </a>
      , Inc. All Rights Reserved.
    </footer>
  );
}
Footer.defaultProps = defaultProps;
Footer.propTypes = propTypes;

export default Footer;
