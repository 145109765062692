export const SUCCESS_USER_ACTIVATED = "The user <strong>:userName</strong> has been <b>activated</b>";

export const SUCCESS_USER_DEACTIVATED = "The user <strong>:userName</strong> has been <b>deactivated</b>";

export const SUCCESS_USER_CREATE = "The user <strong>:userName</strong> has been <b>created</b>";

export const SUCCESS_USER_EDIT = "The user <strong>:userName</strong> was <b>updated</b>";

export const getMsg = (msg, params) => {
  let result = msg;
  Object.keys(params).forEach((key) => {
    result = result.replace(`:${key}`, params[key]);
  });
  return result;
};
