import React from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { getIn } from "utils/ramda";

import {
  PROFILE_DASHBOARD_ACCOUNT_SHOPPER_PATH,
  DASHBOARD_ACCOUNT_SHOPPER_STYLE_WISHLIST_PATH,
  SHOP_DASHBOARD_ACCOUNT_SHOPPER_PATH,
  getPath,
} from "../../../../constants/routes";

import header from "../../header.module.sass";

const propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
const defaultProps = {
  accountId: null,
  id: null,
};

function StylePageNav(props) {
  const { accountId, id } = props;

  return (
    <div className={header.chatNav}>
      <NavLink
        className={header.chatNavLink}
        activeClassName={header.chatNavLinkActive}
        to={getPath(PROFILE_DASHBOARD_ACCOUNT_SHOPPER_PATH, { accountId, id })}
        data-name="tabProfile"
      >
        <i className="iconHeader iconProffile" />
        Profile
      </NavLink>
      <NavLink
        className={header.chatNavLink}
        activeClassName={header.chatNavLinkActive}
        to={getPath(DASHBOARD_ACCOUNT_SHOPPER_STYLE_WISHLIST_PATH, { accountId, id })}
        data-name="tabStyle"
      >
        <i className="iconHeader iconStyle" />
        style
      </NavLink>
      <NavLink
        className={header.chatNavLink}
        activeClassName={header.chatNavLinkActive}
        to={getPath(SHOP_DASHBOARD_ACCOUNT_SHOPPER_PATH, { accountId, id })}
        data-name="tabShop"
      >
        <i className="iconHeader iconShop" />
        shop
      </NavLink>
    </div>
  );
}

StylePageNav.propTypes = propTypes;
StylePageNav.defaultProps = defaultProps;

export default withRouter(StylePageNav);
