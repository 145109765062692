import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "stylesheets/index.sass";
import "normalize.css";

import * as actions from "actions";
import ToastContainer from "./Toast/Container";

import { configureStore, history } from "../stores/configureStore";
import RootRouter from "./Router/chooseCloset";

function App(props) {
  const { state } = props;
  const store = configureStore(state);

  useEffect(() => {
    async function fetchMe() {
      store.dispatch(actions.fetchMe());
    }
    // fetchMyProfile();
    fetchMe();
  }, []);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <RootRouter />
        <ToastContainer />
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
