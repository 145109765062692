import React, { useState } from "react";
import PropTypes from "prop-types";

import { shape } from "../Image";
import chat from "../../../chat.module.sass";
import Grid from "./Grid";
import SliderGallery from "./SliderGallery";
// import moment from "moment";
// import { compose, lifecycle } from "recompose";
// import { connect } from "react-redux";
// import * as actions from "actions";

// import { paperPlane, profileAvatar, shape } from "../shared/Image";

// import chat from "../../chat.module.sass";

const propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  products: [],
};

function ProductsGallery(props) {
  const [isGrid, setIsGrid] = useState(true);

  const { products } = props;
  function handleToggle() {
    isGrid ? setIsGrid(false) : setIsGrid(true);
  }

  return (
    <>
      <img className={chat.icon} src={shape} alt="shape" onClick={handleToggle} />
      {isGrid ? (
        <Grid {...props} />
      ) : (
        <div className="productCard">
          <SliderGallery products={products} />
        </div>
      )}
    </>
  );
}

ProductsGallery.propTypes = propTypes;
ProductsGallery.defaultProps = defaultProps;

export default ProductsGallery;
