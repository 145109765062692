import { createSelector } from "reselect";
import { getIn } from "utils/ramda";
import { always, identity, propOr, find, propEq, includes } from "ramda";

import { entityDetailGetter, entityListGetter } from "utils/entities";
import { getEntities } from "../entities";

export const initialState = {
  ids: [],
  productsShop: {
    ids: [],
    meta: {},
    params: {},
  },
  productsStyle: {
    ids: [],
  },
  productsRecommended: {
    ids: [],
  },
  productsEditor: {
    ids: [],
  },
  productsFavorites: {
    ids: [],
  },
  productMessages: [],
};

// const getProps = (state, props) => props;

export function productsSelector(state) {
  return state.products || initialState;
}

function productsSelectorTest(state) {
  return state.products.ids || initialState.ids;
}

export const getProductsIds = createSelector(productsSelectorTest, identity);

function productsStyleSelector(state) {
  return state.products.productsStyle.ids || initialState.productsStyle.ids;
}

export const getProductsStyleIds = createSelector(productsStyleSelector, identity);

export const getProductsStyleDetail = createSelector(
  [always("products"), getProductsStyleIds, getEntities],
  entityListGetter,
);

function productsEditorSelector(state) {
  return state.products.productsEditor.ids || initialState.productsEditor.ids;
}

function productsFavoritesSelector(state) {
  return state.products.productsFavorites.ids || initialState.productsFavorites.ids;
}

function productsRecommendedSelector(state) {
  return state.products.productsRecommended.ids || initialState.productsRecommended.ids;
}

export const getProductsEditorIds = createSelector(productsEditorSelector, identity);

export const getProductsEditorDetail = createSelector(
  [always("products"), getProductsEditorIds, getEntities],
  entityListGetter,
);

export const getProductsRecommendedIds = createSelector(productsRecommendedSelector, identity);

export const getProductsRecommendedDetail = createSelector(
  [always("products"), getProductsRecommendedIds, getEntities],
  entityListGetter,
);

export const getProductsFavoritesIds = createSelector(productsFavoritesSelector, identity);

export const getProductsfavoritesDetail = createSelector(
  [always("products"), getProductsFavoritesIds, getEntities],
  entityListGetter,
);

export const getProductDetail = (state = initialState, id) => entityDetailGetter("products", id, state.entities);

export const getProductsDetail = createSelector([always("products"), getProductsIds, getEntities], entityListGetter);

// export const getProductsDetail = (state = initialState, ids) => entityListGetter("products", ids, state.entities);
// _____________________

export const getProducts = createSelector(productsSelector, identity);

export const getShopProductsCollection = createSelector(getProducts, (collection) => {
  return collection.productsShop;
});

export const getShopProductsCollectionMeta = createSelector(getShopProductsCollection, (collection) => {
  return collection.meta;
});

export const getParams = createSelector(getShopProductsCollection, (collection) => {
  return collection.params;
});

export const getStyleWishlistProductsCollection = createSelector(getProducts, (collection) => {
  return collection.productsStyle;
});

export const getStyleProductsCollectionMeta = createSelector(getStyleWishlistProductsCollection, (collection) => {
  return collection.meta;
});

export const getProductsEditorCollectionIds = createSelector(getProducts, (products) => {
  return products.productsEditor.ids;
});

export const getProductsFavoritesCollectionIds = createSelector(getProducts, (products) => {
  return products.productsFavorites.ids;
});

export const getProductsForEditor = createSelector(
  [getProductsEditorDetail, getProductsEditorCollectionIds],
  (productsCollection, ids) => {
    const productsEditors =
      productsCollection && productsCollection.filter((product) => product && includes(product.id, ids));

    return productsEditors;
  },
);

export const getProductsForFavorites = createSelector(
  [getProductsfavoritesDetail, getProductsFavoritesCollectionIds],
  (productsCollection, ids) => {
    const productsFavorites =
      productsCollection && productsCollection.filter((product) => product && includes(product.id, ids));

    return productsFavorites;
  },
);

// export const getProduct = createSelector(getProducts, getProps, (productsCollection, props) => {
//   const data = propOr([], "productMessages", productsCollection);
//   const id = getIn("id", props);
//   const product = find(propEq(id))(data);
//   return {
//     product,
//   };
// });
