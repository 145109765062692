import React from "react";
import PropTypes from "prop-types";
import cl from "classnames";

import styles from "./index.module.sass";

const propTypes = {};

const defaultProps = {};

function NavTabMeasurements({ setTabMeasurements, tabMeasurements }) {
  return (
    <ul className={styles.navTab}>
      <li
        onClick={() => setTabMeasurements("womenMeasurements")}
        className={cl(styles.navItem, tabMeasurements === "womenMeasurements" && styles.navItemActive)}
      >
        Women
      </li>
      <li
        onClick={() => setTabMeasurements("menMeasurements")}
        className={cl(styles.navItem, tabMeasurements === "menMeasurements" && styles.navItemActive)}
      >
        Men
      </li>
    </ul>
  );
}

NavTabMeasurements.defaultProps = defaultProps;
NavTabMeasurements.propTypes = propTypes;

export default NavTabMeasurements;
