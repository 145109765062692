import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import account from "./account";
import entities from "./entities";
import products from "./products";
import shoppers from "./shoppers";
import session from "./session";
import staff from "./staff";
import request from "./request";
import shopperProfile from "./shopperProfile";

export default (history) =>
  combineReducers({
    account,
    entities,
    products,
    shoppers,
    session,
    staff,
    request,
    shopperProfile,
    router: connectRouter(history),
  });
