import React, { useState, useEffect, useRef } from "react";
import { Switch, Route } from "react-router-dom";
import { getIn } from "utils/ramda";
import * as routes from "constants/routes";
import actionCable from "actioncable";
import * as actions from "actions";
import { connect } from "react-redux";
import { getSessionData } from "utils/sessionData";
import { getSessionUser } from "selectors/session";
import { getCountMessages } from "selectors/accounts";
import Noty from "react-web-notification";
import { pipe, map, includes, prop, without } from "ramda";

import { history } from "stores/configureStore";
import logo from "../shared/Image/favicon.ico";
import { Analytics, ClaimClient, ComingSoon, Chat, Header, Dashboard, SideBar, Settings, StaffRoutes } from "../index";

function DashboardRouter(props) {
  const {
    fetchAccountShoppers,
    removeStoreUnassignmentShopper,
    shoppers,
    currentUser,
    history,
    addMessageToChat,
    addActivityEvent,
    fetchMyProfile,
    countMessages,
    newCountMessage,
  } = props;

  const ref = useRef();
  const [showNav, setShowNav] = useState(false);
  const accountId = getIn("match.params.accountId", props);
  const [isNewShopperId, setIsNewShopperId] = useState("");
  const [stateNotification, setStateNotification] = useState({
    ignore: true,
    title: "",
    options: {},
  });
  const [connection, setConnection] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [shopperIsTyping, setShopperIsTyping] = useState(false);
  const [typingState, setTypingState] = useState([]);
  // вынесу все в utils !!!

  const changeStorage = () => {
    const AccountIds = JSON.parse(localStorage.getItem(`OpenAccounts:`));
    const valuesToRemove = [accountId];
    const filteredItems = without(valuesToRemove, AccountIds);
    localStorage.setItem(`OpenAccounts:`, JSON.stringify(filteredItems));
  };

  window.onunload = function() {
    changeStorage();
  };

  addEventListener(
    "popstate",
    function(e) {
      changeStorage();
    },
    false,
  );

  useEffect(() => {
    fetchMyProfile(accountId);
    let openAccountsStorage;
    if (localStorage.getItem(`OpenAccounts:`) === null) {
      openAccountsStorage = [];
    } else {
      openAccountsStorage = JSON.parse(localStorage.getItem(`OpenAccounts:`));
    }
    includes(accountId, openAccountsStorage) ? null : openAccountsStorage.push(accountId);
    localStorage.setItem(`OpenAccounts:`, JSON.stringify(openAccountsStorage));

    const CableApp = {};
    const { accessToken } = getSessionData();
    CableApp.cable = actionCable.createConsumer(`${process.env.CABLE_URL}?access_token=${accessToken}`);
    const consumer = CableApp.cable.subscriptions.create(
      {
        channel: "Account::ConsultantChannel",
        account_id: accountId,
        source: "dashboard",
      },
      {
        received: (res) => {
          const { action, data, contactId } = res;

          if (action === "assignment_create") {
            fetchAccountShoppers(accountId);
            setIsNewShopperId(`${contactId}`);
            const title = "Luxlock | New shopper joined chat";
            handleButtonClick(title);
          }

          if (action === "assignment_active") {
            fetchAccountShoppers(accountId);
          }

          if (action === "assignment_stale") {
            const id = contactId ? [`${contactId}`] : [];
            fetchAccountShoppers(accountId);
            removeStoreUnassignmentShopper(id);
            // Temporary fix. Please close your eyes if you see it
            if (
              history.location.pathname === `/accounts/${accountId}/shoppers/${id}/profile` ||
              history.location.pathname === `/accounts/${accountId}/shoppers/${id}/shop` ||
              history.location.pathname === `/accounts/${accountId}/shoppers/${id}/style/closet` ||
              history.location.pathname === `/accounts/${accountId}/shoppers/${id}/style/wishlist` ||
              history.location.pathname === `/accounts/${accountId}/shoppers/${id}/style/browse` ||
              history.location.pathname === `/accounts/${accountId}/shoppers/${id}/style/abandoned` ||
              history.location.pathname === `/accounts/${accountId}/shoppers/${id}/style/returns`
            ) {
              history.push(`/accounts/${accountId}/shoppers`);
            }
          }
          if (action === "message") {
            addMessageToChat(data);
          }

          if (action === "activity") {
            addActivityEvent(data);
          }

          if (action === "message" && contactId === data.contact.id) {
            const title = "Luxlock | New message from shopper";
            handleButtonClick(title);
            // const isIncludesId = findIndex(propEq("id", contactId))(newMessageCount);
            // newMessageCount.map((item) =>
            //   -isIncludesId ? setNewMessageCount([...newMessageCount, initItem(contactId)]) : incCount(item),
            // );
            // setNewMessageCount();
            // testFunc(contactId);
            newCountMessage(contactId);
          }

          if (action === "typing") {
            setTypingState([...typingState, res]);
          }
        },
      },
    );

    // const unsubscribe = () => {
    //   consumer.unsubscribe();
    // };

    setConnection(consumer);
    // return unsubscribe;
  }, []);

  // const [newMessageCount, setNewMessageCount] = useState([]);

  // function testFunc(contactId) {
  //   console.log("countMessages", countMessages);
  //   // const isIncludes = pipe(map(prop("id")), includes(contactId))(newMessageCount);
  //   // if (isIncludes) {
  //   //   console.log("@!!!!");
  //   //   pipe(
  //   //     map((item) => (item.id === contactId ? incCount(item) : item)),
  //   //     setNewMessageCount,
  //   //   )(newMessageCount);
  //   //   return;
  //   // }
  //   // setNewMessageCount(["32324e"]);
  //   // setIsTest({ filter: "dsdsd" });
  //   newCountMessage(contactId);
  // }

  // function initItem(id) {
  //   return { count: 1, id };
  // }
  // function incCount(item) {
  //   return { ...item, count: item.count + 1 };
  // }

  function handleButtonClick(text) {
    const now = Date.now();

    const title = text;
    const body = "🧥👚👞👕👖👔👗👜👙🧢👠👜";
    const tag = now;
    const icon = logo;

    const options = {
      body,
      icon,
      tag,
      lang: "en",
      dir: "ltr",
      // sound: './sound.mp3'
    };
    setStateNotification({
      title,
      options,
    });
  }

  // function handleMessageCount(value) {
  //   // console.log("Permission Denied");
  //   setNewMessageCount(value);
  // }

  // function handlePermissionDenied() {
  //   console.log("Permission Denied");
  // }

  function handleNotificationOnShow(e, tag) {
    console.log(e, `Notification shown tag:${tag}`);
  }

  function requestPermission() {
    return new Promise(function(resolve, reject) {
      const permissionResult = Notification.requestPermission(function(result) {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function(permissionResult) {
      if (permissionResult !== "granted") {
        throw new Error("Permission not granted.");
      }
    });
  }

  function onTyping(id) {
    // FIXME. It's not work in react, please add debounce
    // if (event.keyCode === 13) {
    //   event.preventDefault();
    //   setIsTyping(false);
    //   connection.perform("typing", { typing: false });
    // }
    if (isTyping !== true) {
      setIsTyping(true);
      connection.perform("typing", { contact_id: id, typing: true });
      clearTimeout(typingTimer);
      const typingTimer = setTimeout(() => {
        setIsTyping(false);
        return connection.perform("typing", { contact_id: id, typing: false });
      }, 2000);
    }
  }

  return (
    <>
      <Header isStylist isPaused={currentUser && currentUser.isPaused} showNav={showNav} accountId={accountId} />
      <SideBar isStylist accountId={accountId} />
      <Noty
        ref={ref}
        // ignore={stateNotification.ignore && stateNotification.title !== ""}
        // onPermissionDenied={handlePermissionDenied}
        onShow={handleNotificationOnShow}
        timeout={5000}
        title={stateNotification.title}
        options={stateNotification.options}
      />
      <Switch>
        <Route exact path={routes.DASHBOARD_ACCOUNT_PATH} component={Dashboard} />
        <Route
          path={routes.DASHBOARD_ACCOUNT_SHOPPERS_PATH}
          render={(p) => (
            <Chat
              countMessages={countMessages}
              // setNewMessageCount={setNewMessageCount}
              // newMessageCount={newMessageCount}
              setIsNewShopperId={setIsNewShopperId}
              isNewShopperId={isNewShopperId}
              setShowNav={setShowNav}
              accountId={accountId}
              shoppers={shoppers}
              onTyping={onTyping}
              shopperIsTyping={shopperIsTyping}
              typingState={typingState}
              {...p}
            />
          )}
        />
        <Route path={routes.DASHBOARD_ACCOUNT_ANALYTICS_PATH} component={Analytics} />

        <Route path={routes.DASHBOARD_ACCOUNT_SETTINGS_SETUP_PATH} component={Settings} />

        <Route path={routes.DASHBOARD_ACCOUNT_STAFF_LIST_PATH} component={StaffRoutes} />

        <Route path={routes.DASHBOARD_ACCOUNT_STORE_PATH} component={ClaimClient} />

        <Route path={routes.DASHBOARD_ACCOUNT_REPORTS_PATH} component={ComingSoon} />
      </Switch>
      {/* <Footer /> */}
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: getSessionUser(state),
    countMessages: getCountMessages(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchAccountShoppers: (accountId) => dispatch(actions.fetchAccountShoppers(accountId)),
  removeStoreUnassignmentShopper: (id) => dispatch(actions.removeStoreUnassignmentShopper(id)),
  addMessageToChat: (message) => dispatch(actions.addMessageToChat(message)),
  addActivityEvent: (event) => dispatch(actions.addActivityEvent(event)),
  fetchMyProfile: (accountId) => dispatch(actions.fetchMyProfile(accountId)),
  newCountMessage: (test) => dispatch(actions.newCountMessage(test)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRouter);
