import React from "react";
import PropTypes from "prop-types";

// import useClassNames from 'hooks/useClassNames';

const propTypes = {
  baseClass: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

const defaultProps = {
  baseClass: undefined,
  className: undefined,
  type: "text",
};

function Input({ type, onChange, name, className, baseClass, refComponent, ...props }) {
  const handleChange = ({ target }) => {
    onChange(target.files[0]);
  };

  return (
    <input
      name={name}
      onChange={handleChange}
      type={type}
      {...props}
      ref={refComponent}
      className={className}
      value=""
    />
  );
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
