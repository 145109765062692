import React from "react";
import PropTypes from "prop-types";

import cl from "classnames";

import styles from "./index.module.sass";

const propTypes = {};

const defaultProps = {};

function Item({ label, value, styleColumn }) {
  const initialValue = value ? value : "—";

  const shareClasses = cl({
    [`${styles.infoItem}`]: !styleColumn,
    [`${styles.infoItemCol}`]: styleColumn,
  });

  return (
    <>
      <li className={shareClasses}>
        <label>{label}</label>
        <span>{initialValue}</span>
      </li>
    </>
  );
}

Item.defaultProps = defaultProps;
Item.propTypes = propTypes;

export default Item;
