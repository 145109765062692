import { setSessionData, removeSessionData } from "utils/sessionData";
import { FETCH_ME_SUCCESS, FETCH_MY_PROFILE_SUCCESS } from "constants/actionTypes";
import { pushToast } from "components";

import { SessionApiAdapter } from "apiAdapters";
import { history } from "stores/configureStore";
import { CHOOSE_ACCOUNT_PATH, DASHBOARD_AUTH_SIGN_IN_PATH, NOT_FOUND_PAGE } from "constants/routes";
import * as requestTypes from "constants/requestTypes";
import { throwSubmissionError } from "utils/form";
import setRequestInProcess from "../request";

export const sendSignInCredentials = (body) => async (dispatch) => {
  const url = sessionStorage.getItem("redirectUrl");

  const requestType = requestTypes.POST_SIGN_IN_CREDENTIALS;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await SessionApiAdapter.postSignInCredentials(body);

    setSessionData(data);
    // window.location.href = CHOOSE_ACCOUNT_PATH;

    if (url !== "undefined") {
      history.push(url);
    } else {
      history.push(CHOOSE_ACCOUNT_PATH);
    }
  } catch (error) {
    if (error) {
      return throwSubmissionError(error);
    }
    return throwSubmissionError({ errors: { message: "Something went wrong" } });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const sendSignUpCredentials = (body) => async (dispatch) => {
  const requestType = requestTypes.POST_SIGN_UP_CREDENTIALS;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await SessionApiAdapter.postSignUpCredentials(body);

    setSessionData(data);
    // dispatch(fetchMyProfile());
    pushToast({
      text: "Registration completed successfully",
    });
    history.push(DASHBOARD_AUTH_SIGN_IN_PATH);
  } catch (error) {
    if (error) {
      return throwSubmissionError(error);
    }
    return throwSubmissionError({ errors: { message: "Something went wrong" } });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const fetchMyProfile = (accountId) => async (dispatch) => {
  const requestType = requestTypes.MY_PROFILE_FETCH;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await SessionApiAdapter.getMyProfile(accountId);

    dispatch(fetchMyProfileSuccess(data));
  } catch (err) {
    removeSessionData();
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const fetchMe = () => async (dispatch) => {
  const requestType = requestTypes.ME_FETCH;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await SessionApiAdapter.getMe();
    dispatch(fetchMeSuccess(data));
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const postNewPassword = (userData, token) => async (dispatch) => {
  const requestType = requestTypes.POST_UPDATE_PASSWORD;
  dispatch(setRequestInProcess(true, requestType));
  try {
    await SessionApiAdapter.postNewPassword(userData, token);

    // window.location.href = DASHBOARD_AUTH_SIGN_IN_PATH;
    history.push(DASHBOARD_AUTH_SIGN_IN_PATH);
    pushToast({
      text: "Password has been successfully changed",
      autoClose: 10000,
    });
  } catch (err) {
    // history.push("http://dashboard.luxlock.local:3000/404");
    // return throwSubmissionError(err);
    // WIP!!!!!
    return throwSubmissionError(err);
  }
};

export const checkToken = (token) => async (dispatch) => {
  const requestType = requestTypes.PASSWORD_TOKEN;
  dispatch(setRequestInProcess(true, requestType));
  try {
    await SessionApiAdapter.postCheckToken(token);
  } catch (err) {
    window.location.href = NOT_FOUND_PAGE;
    return throwSubmissionError(err);
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const postEmailToRestorePassword = (userData, token) => async (dispatch) => {
  const requestType = requestTypes.POST_EMAIL_TO_RESTORE_PASSWORD;
  dispatch(setRequestInProcess(true, requestType));
  try {
    await SessionApiAdapter.postEmailToRestorePassword(userData, token);

    // window.location.href = DASHBOARD_AUTH_SIGN_IN_PATH;
    history.push(DASHBOARD_AUTH_SIGN_IN_PATH);
    pushToast({
      text: "Recovery Email has been sent. Please check it to login to your Luxlock account",
      autoClose: 10000,
    });
  } catch (err) {
    return throwSubmissionError(err);
  }
};

const fetchMyProfileSuccess = (payload) => ({
  type: FETCH_MY_PROFILE_SUCCESS,
  payload,
});

const fetchMeSuccess = (payload) => ({
  type: FETCH_ME_SUCCESS,
  payload,
});
