import React from "react";
import PropTypes from "prop-types";
import cl from "classnames";

import { Item } from "../../shared";
import styles from "../../index.module.sass";

const propTypes = {
  pets: PropTypes.arrayOf(),
};

const defaultProps = {
  pets: [],
};

function Pets({ pets }) {
  return (
    <>
      {pets.map((item, index) => (
        <ul className={cl(`${styles.personalList}`)}>
          {index > 0 && <div className={styles.border} />}
          <Item label="Name" value={item.name} styleColumn />
          <Item label="Preference" value={item.preference} styleColumn />
          <Item label="Type" value={item.type} styleColumn />
          <Item label="Age" value={item.age} styleColumn />
          <Item label="Collar Size" value={item.collarSize} styleColumn />
          <Item label="Sweater Size" value={item.sweaterSize} styleColumn />
        </ul>
      ))}
    </>
  );
}

Pets.defaultProps = defaultProps;
Pets.propTypes = propTypes;

export default Pets;
