import React from "react";
import classNames from "classnames";

import BaseRadioButton from "../../formBase/RadioButton";
import styles from "./index.module.sass";

function RadioButton({ className, children, name, isError, checked, ...props }) {
  return (
    <label className={classNames(styles.radioLable, checked && styles.active)}>
      <BaseRadioButton name={name} {...props} className={styles.hiddenCheckbox} />
      {/* <div active={props.checked} isError={isError} /> */}
      <div className={styles.title}>{children}</div>
    </label>
  );
}

export default RadioButton;
