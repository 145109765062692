import React from "react";
// import PropTypes from "prop-types";

import setting from "../../setting.module.sass";

const propTypes = {};
const defaultProps = {};

function Integrate() {
  return (
    <div className={setting.contentLeft}>
      <h2>Integrate</h2>
    </div>
  );
}

Integrate.propTypes = propTypes;
Integrate.defaultProps = defaultProps;

export default Integrate;
