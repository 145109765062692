import { StaffApiAdapter } from "apiAdapters";
import { staffNormalizr, staffsNormalizr } from "schemas";
import {
  SUCCESS_USER_DEACTIVATED,
  SUCCESS_USER_ACTIVATED,
  SUCCESS_USER_CREATE,
  SUCCESS_USER_EDIT,
  getMsg,
} from "constants/notificationTexts";
import { pushToast } from "components";
import { getContactStaffFullName } from "selectors/staffs";
import * as requestTypes from "constants/requestTypes";
import * as types from "constants/actionTypes";
import { history } from "stores/configureStore";
import { throwSubmissionError } from "utils/form";

import setRequestInProcess from "../request";
import { mergeEntities } from "../entities";

const fetchAccountStaff = (accountId) => async (dispatch) => {
  const requestType = requestTypes.STAFF_FETCH;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await StaffApiAdapter.getAccountStaff(accountId);
    const { entities, result } = staffsNormalizr(data);
    dispatch(mergeEntities(entities));
    dispatch(fetchAccountStaffSuccess(result));
    dispatch(setRequestInProcess(false, requestType));
  } catch (error) {
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const accountStaffDeactivate = (accountId, staffId) => async (dispatch, getState) => {
  const requestType = requestTypes.ACCOUNTS_STAFF_UPDATE;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await StaffApiAdapter.accountStaffDeactivate(accountId, staffId);
    const { entities, result } = staffNormalizr(data);
    pushToast({
      text: getMsg(SUCCESS_USER_DEACTIVATED, { userName: getContactStaffFullName(getState(), result) }),
    });
    dispatch(mergeEntities(entities));
  } catch (error) {
    pushToast({
      type: "error",
      text: "Error",
    });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const accountStaffActivate = (accountId, staffId) => async (dispatch, getState) => {
  const requestType = requestTypes.ACCOUNTS_STAFF_UPDATE;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await StaffApiAdapter.accountStaffActivate(accountId, staffId);
    const { entities, result } = staffNormalizr(data);
    pushToast({
      text: getMsg(SUCCESS_USER_ACTIVATED, { userName: getContactStaffFullName(getState(), result) }),
    });
    dispatch(mergeEntities(entities));
  } catch (error) {
    return throwSubmissionError(error);
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const postStaffData = (accountId, staff) => async (dispatch, getState) => {
  const requestType = requestTypes.ACCOUNTS_STAFF_UPDATE;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await StaffApiAdapter.createStaffData(accountId, staff);
    const { entities, result } = staffNormalizr(data);
    dispatch(mergeEntities(entities));
    pushToast({
      text: getMsg(SUCCESS_USER_CREATE, { userName: getContactStaffFullName(getState(), result) }),
    });
    history.push(`/accounts/${accountId}/staff/${data.id}`);
  } catch (error) {
    if (error) {
      return throwSubmissionError(error);
    }
    return throwSubmissionError({ errors: { message: "Something went wrong" } });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const editStaffData = (accountId, staffId, staff) => async (dispatch, getState) => {
  const requestType = requestTypes.ACCOUNT_STAFF_DATA_POST;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { data } = await StaffApiAdapter.editStaffData(accountId, staffId, staff);
    const newData = data.contact.avatar ? data : { ...data, contact: { ...data.contact, avatar: null } };
    const { entities, result } = staffNormalizr(newData);
    dispatch(mergeEntities(entities));
    pushToast({
      text: getMsg(SUCCESS_USER_EDIT, { userName: getContactStaffFullName(getState(), result) }),
    });
  } catch (error) {
    if (error) {
      return throwSubmissionError(error);
    }
    return throwSubmissionError({ errors: { message: "Something went wrong" } });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

function fetchAccountStaffSuccess(payload) {
  return {
    type: types.STAFF_LIST_FETCH_SUCCESS,
    payload,
  };
}

export default fetchAccountStaff;
