import React from "react";
import PropTypes from "prop-types";

// import useClassNames from 'hooks/useClassNames';

const propTypes = {
  baseClass: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

const defaultProps = {
  baseClass: undefined,
  className: undefined,
  type: "text",
};

function InputCSV({ type, onChange, name, className, baseClass, ...props }) {
  const handleChange = ({ target }) => {
    onChange(target.files[0]);
  };

  return <input name={name} onChange={handleChange} type={type} {...props} className={className} value="" />;
}

InputCSV.propTypes = propTypes;
InputCSV.defaultProps = defaultProps;

export default InputCSV;
