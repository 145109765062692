import { normalize, schema } from "normalizr";
import { products } from "./products";
import { shoppers } from "./shoppers";

const account = new schema.Object({
  products: [products],
  shoppers: [shoppers],
});
const accountNormalizr = (accountData) => normalize(accountData, { account });

export { account, accountNormalizr };
