import React from "react";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";

import * as actions from "actions";
import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import Input from "components/shared/formDefault/Input";
import InputPassword from "components/shared/formDefault/InputPassword";
import Button from "components/shared/formDefault/Button";

import { composeValidators, validateRequired, validateEmail } from "components/shared/formDefault/validations";

import styles from "./index.module.sass";

const defaultProps = {};

const propTypes = {
  sendCredentials: PropTypes.func.isRequired,
};

function SignUpForm({ sendCredentials }) {
  return (
    <Form
      initialValues={{
        grant_type: "password",
        client_secret: process.env.CLIENT_SECRET,
        client_id: process.env.CLIENT_ID,
      }}
      onSubmit={(props) => sendCredentials(props)}
      render={({ handleSubmit, hasSubmitErrors, submitError, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <div className={styles.fieldsCollection}>
            {/* <Field
              component={FormGroupAdapter}
              inputComponent={Input}
              name="firstName"
              placeholder="First Name"
              type="text"
              validate={composeValidators(validateRequired())}
            /> */}

            <Field
              component={FormGroupAdapter}
              inputComponent={Input}
              name="user.full_name"
              placeholder="Full Name"
              type="text"
              autoFocus
              validate={composeValidators(validateRequired())}
            />
            <Field
              component={FormGroupAdapter}
              inputComponent={Input}
              name="user.email"
              placeholder="Email"
              type="email"
              validate={composeValidators(validateRequired(), validateEmail())}
            />
            <Field
              component={FormGroupAdapter}
              inputComponent={InputPassword}
              name="user.password"
              help=""
              placeholder="Password"
              type="password"
              validate={validateRequired()}
            />

            {/* <Field
              component={FormGroupAdapter}
              inputComponent={Input}
              name="userSession.passwordConfirmation"
              help=""
              placeholder="Password Confirmation"
              type="password"
              validate={validateRequired()}
            /> */}
            {hasSubmitErrors && submitError && (
              <div className={styles.errorMessageContainer}>
                <span className={styles.errorMessage}>{submitError}</span>
              </div>
            )}
          </div>

          <Button type="submit" disable={submitting} auth>
            {submitting ? "Submitting..." : "Register"}
          </Button>
        </form>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  sendCredentials: (userData) => dispatch(actions.sendSignUpCredentials(userData)),
});

const enhance = compose(connect(null, mapDispatchToProps));

SignUpForm.defaultProps = defaultProps;
SignUpForm.propTypes = propTypes;

export default enhance(SignUpForm);
