import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.sass";

const propTypes = {
  isSimple: PropTypes.bool,
};

const defaultProps = {
  isSimple: false,
};

function ItemsList({ items, handleDelete, isSimple }) {
  if (isSimple) {
    return (
      <div>
        {items.map((label, index) => (
          <div key={index} className={styles.ItemsListWrapper}>
            <span>{label}</span>
            <div onClick={() => handleDelete(label)} className={styles.closeWrapper}>
              <div className={styles.close} />
            </div>
          </div>
        ))}
      </div>
    );
  }
  return (
    <div>
      {items.map(({ label, value }) => (
        <div key={value} className={styles.ItemsListWrapper}>
          <span>{label}</span>
          <div onClick={() => handleDelete(value)} className={styles.closeWrapper}>
            <div className={styles.close} />
          </div>
        </div>
      ))}
    </div>
  );
}

ItemsList.defaultProps = defaultProps;
ItemsList.propTypes = propTypes;

export default ItemsList;
