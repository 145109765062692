import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, FormSpy } from "react-final-form";
import { withRouter } from "react-router-dom";
import * as actions from "actions";

import ErrorMessage from "components/shared/formDefault/ErrorMessage";

import AutoSave from "../../AutoSave";

import {
  FieldsCollection,
  NavTab,
  Womenswear,
  WomenswearMeasurements,
  Menswear,
  MenswearMeasurements,
  Childrenswear,
  Pets,
} from "../../shared";

import styles from "../../style.module.sass";

const propTypes = {};

const defaultProps = {};

function Profile(props) {
  const { onSubmitProfileData, shopper, references } = props;
  const [isOpen, setIsOpen] = useState("women");

  // ______________________________________________
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const save = async (values) => {
    onSubmitProfileData(values);
    await sleep(1000);
  };

  // ______________________________________________

  return (
    <Form
      initialValues={shopper}
      mutators={{
        deleteChildren: ([children], state, { changeValue }) => {
          changeValue(state, "sizingInfo.children", () => {
            onSubmitProfileData({
              ...state.formState.values,
              sizingInfo: { ...state.formState.values.sizingInfo, children },
            });
            return children;
          });
        },
        deletePet: ([pets], state, { changeValue }) => {
          changeValue(state, "sizingInfo.pets", () => {
            onSubmitProfileData({
              ...state.formState.values,
              sizingInfo: { ...state.formState.values.sizingInfo, pets },
            });
            return pets;
          });
        },
      }}
      onSubmit={save}
      render={({ handleSubmit, submitError, form, form: { mutators } }) => (
        <form
          // onSubmit={handleSubmit}
          onSubmit={handleSubmit}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          className={styles.formContainer}
        >
          <AutoSave debounce={500} setFieldData={mutators.setFieldData} save={save} />
          <h4 className={styles.fieldsCollectionSubtitle}>Sizing details</h4>
          <NavTab isOpen={isOpen} setIsOpen={setIsOpen} />
          {isOpen === "women" && (
            <>
              <FieldsCollection>
                <div className={styles.sizingDetails}>
                  <Womenswear {...props} references={references} />
                </div>
              </FieldsCollection>
              <FieldsCollection>
                <div className={styles.sizingDetails}>
                  <h3 className={styles.sizingDetailsTitle}>MEASUREMENTS</h3>
                  <WomenswearMeasurements {...props} references={references} />
                </div>
              </FieldsCollection>
            </>
          )}
          {isOpen === "men" && (
            <>
              <FieldsCollection>
                <div className={styles.sizingDetails}>
                  <Menswear {...props} references={references} />
                </div>
              </FieldsCollection>
              <FieldsCollection>
                <div className={styles.sizingDetails}>
                  <h3 className={styles.sizingDetailsTitle}>MEASUREMENTS</h3>
                  <MenswearMeasurements {...props} references={references} />
                </div>
              </FieldsCollection>
            </>
          )}
          {isOpen === "children" && (
            <>
              <FieldsCollection>
                <div className={styles.sizingDetails}>
                  <Childrenswear
                    {...props}
                    childrens={shopper.sizingInfo && shopper.sizingInfo.children}
                    references={references}
                    handleAddChildren={mutators.addChildren}
                    handleDeleteChildren={mutators.deleteChildren}
                  />
                </div>
              </FieldsCollection>
            </>
          )}
          {isOpen === "pet" && (
            <>
              <FieldsCollection>
                <div className={styles.sizingDetails}>
                  <Pets
                    {...props}
                    pets={shopper.sizingInfo && shopper.sizingInfo.pets}
                    references={references}
                    handleDeletePet={mutators.deletePet}
                  />
                </div>
              </FieldsCollection>
            </>
          )}
          {/* {hasSubmitErrors && submitError && ( */}
          <div className={styles.errorMessageContainer}>
            <ErrorMessage>{submitError}</ErrorMessage>
          </div>
          {/* )} */}
          {/* <div className={styles.btnFooter}>
                <Button type="submit" disable={submitting}>
                  {submitting ? "saving..." : "save"}
                </Button>
              </div> */}
        </form>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  onSubmitProfileData: (props) => dispatch(actions.postProfileData(props)),
});

Profile.defaultProps = defaultProps;
Profile.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(withRouter(Profile));
