import { isNil, isEmpty } from "ramda";
import { dateIsValid, dataAfter } from "utils/format";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from "react-phone-number-input";

const REG_EMAIL = /^(([^<>()\]\\.,;:\s@“]+(\.[^<>()\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateRequired({ message = "Can’t be blank" } = {}) {
  return (value) => {
    if (isNil(value)) return message;
    return undefined;
  };
}

export function validateCheckbox({ message = "Checkbox must be selected" } = {}) {
  return (value) => {
    if (isNil(value)) return message;
    return undefined;
  };
}

export function validateEmpty({ message = "Can’t be blank" } = {}) {
  return (value) => {
    if (isEmpty(value)) return message;
    return undefined;
  };
}

export function validateRequiredNotStrict({ message = "Can’t be blank" } = {}) {
  return (value) => {
    if (value === undefined) return message;
    return undefined;
  };
}
export function validateEmail({ message = "Is invalid", reg = REG_EMAIL } = {}) {
  return (email) => {
    if (email && !reg.test(String(email).toLowerCase())) return message;
    return undefined;
  };
}
export function validatePhone({ message = "Please enter valid mobile number" } = {}) {
  return (phone) => {
    const onlyNums = phone ? phone.replace(/[^\d]/g, "") : undefined;
    if (isNil(onlyNums)) return undefined;
    if (!isValidPhoneNumber(phone)) return message;
    return undefined;
  };
}

export function validateImage({ message = "Please upload .jpg, .jpeg, or .png file" } = {}) {
  return (image) => {
    if (image && image.type && !image.type.match(/\image\/(jpg|jpeg|png)$/)) {
      return message;
    }
    return undefined;
  };
}

export function validateCSV({ message = "Please upload .csv or xlsx file" } = {}) {
  return (file) => {
    if (
      file &&
      file.type &&
      !file.type.match(/\text\/(csv)$/) &&
      file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return message;
    }
    return undefined;
  };
}

export function validateDate({ message = "Please enter valid date" } = {}) {
  return (date) => {
    if (isNil(date)) return undefined;
    if (dateIsValid(date) && dataAfter(date)) return undefined;
    return message;
  };
}
export function composeValidators(...validators) {
  return (...args) => validators.reduce((error, validator) => error || validator(...args), undefined);
}
