import "react-toastify/dist/ReactToastify.css";
import "./index.sass";

import React from "react";
import PropTypes from "prop-types";
import { ToastContainer as ReactToastifyContainer } from "react-toastify";

import toastConfigure from "./shared/configure";

const propTypes = {};

const defaultProps = {};

function ToastContainer() {
  return <ReactToastifyContainer {...toastConfigure} />;
}

ToastContainer.defaultProps = defaultProps;
ToastContainer.propTypes = propTypes;

export default ToastContainer;
