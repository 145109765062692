import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.sass";

const propTypes = {
  isOnline: PropTypes.bool.isRequired,
};

const defaultProps = {};

function OnlineStatus({ isOnline }) {
  return (
    <span className={classNames(styles.StaffOnlineStatus, isOnline ? styles.online : styles.offline)}>
      {isOnline ? "online" : "offline"}
    </span>
  );
}

OnlineStatus.defaultProps = defaultProps;
OnlineStatus.propTypes = propTypes;

export default OnlineStatus;
