import React from "react";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";
import * as actions from "actions";

import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import Input from "components/shared/formDefault/Input";
import Button from "components/shared/formDefault/Button";
import ErrorMessage from "components/shared/formDefault/ErrorMessage";
import { composeValidators, validateRequired, validateEmail } from "components/shared/formDefault/validations";

import styles from "./index.module.sass";

const defaultProps = {};

const propTypes = {
  postCredentials: PropTypes.func.isRequired,
};

function RestorePasswordForm({ postCredentials }) {
  return (
    <Form
      onSubmit={(props) => postCredentials(props)}
      render={({ handleSubmit, hasSubmitErrors, submitError, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <div className={styles.fieldsCollection}>
            <div className={styles.textContainer}>
              <span>Enter your email address to receive a secure link to reset your password.</span>
            </div>

            <Field
              component={FormGroupAdapter}
              inputComponent={Input}
              autoFocus
              name="userSession.email"
              help=""
              placeholder="Email"
              type="text"
              validate={composeValidators(validateRequired(), validateEmail())}
            />

            {hasSubmitErrors && submitError && (
              <div className={styles.errorMessageContainer}>
                <span className={styles.errorMessage}>{submitError}</span>
              </div>
            )}
          </div>

          <Button type="submit" disable={submitting} auth>
            {submitting ? "Submitting..." : "Submit"}
          </Button>
        </form>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  postCredentials: (userData) => dispatch(actions.postEmailToRestorePassword(userData)),
});

const enhance = compose(connect(null, mapDispatchToProps));

RestorePasswordForm.defaultProps = defaultProps;
RestorePasswordForm.propTypes = propTypes;

export default enhance(RestorePasswordForm);
