import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node.isRequired,
};

function ErrorMessage({ children }) {
  return <span>{children}</span>;
}

ErrorMessage.propTypes = propTypes;
export default ErrorMessage;
