import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Field } from "react-final-form";
// import PropTypes from "prop-types";
import * as actions from "actions";

import { getIn } from "utils/ramda";
import { getSessionUser } from "selectors/session";

import Button from "components/shared/formDefault/Button";
import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import InputCSV from "components/shared/formDefault/InputCSV";
import ErrorMessage from "components/shared/formDefault/ErrorMessage";

import mutators from "./mutators";
import styles from "./index.module.sass";

const propTypes = {};
const defaultProps = {};

function AppearanceForm({ currentUser, match, onSubmit }) {
  const staffId = getIn("id", currentUser);
  const { accountId } = match.params;

  return (
    <Form
      initialValues={{
        client_secret: process.env.CLIENT_SECRET,
        client_id: process.env.CLIENT_ID,
      }}
      onSubmit={(data) => onSubmit(accountId, staffId, data)}
      mutators={mutators}
      render={({ handleSubmit, hasSubmitErrors, submitError, submitting, form }) => (
        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <Field
            component={FormGroupAdapter}
            inputComponent={InputCSV}
            handleClear={form.mutators.clearCSVField}
            name="file"
            type="file"
            form={form}
          />

          {hasSubmitErrors && submitError && (
            <div className={styles.errorMessageContainer}>
              <ErrorMessage>{submitError}</ErrorMessage>
            </div>
          )}

          <div className={styles.btnGroup}>
            <div className={styles.btnGroupContent}>
              <Button type="submit" disable={submitting}>
                {submitting ? "Submitting…" : "Submit"}
              </Button>
            </div>
          </div>
        </form>
      )}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: getSessionUser(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (accountId, staffId, data) => dispatch(actions.postBulkUploadClients(accountId, staffId, data)),
});

AppearanceForm.defaultProps = defaultProps;
AppearanceForm.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppearanceForm));
