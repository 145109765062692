import React, { useEffect, useState } from "react";
import classNames from "classnames";

import fetch from "apiAdapters/config/fetch";
import { WIDGET_CODE_PATH, getPath } from "constants/routes";
import styles from "./index.module.sass";

// const snippet =
//   '<iframe \n\tid="luxFrame" \n\tsrc="https://demo-chat.luxlock.com" \n\tstyle="\n\t\tz-index:999;\n\t\twidth:500px;\n\t\theight: 100%;\n\t\tposition: fixed; \n\t\tbottom: 0px;\n\t\tright: 0;\n\t\tborder: none;">\n</iframe>';

function StaffRole(props) {
  const { accountId } = props.match.params;

  useEffect(() => {
    getSnippet();
  }, []);

  const [snippet, setSnippet] = useState();

  const getSnippet = async () => {
    const response = await fetch(getPath(WIDGET_CODE_PATH, { accountId }));
    setSnippet(response.data.src);
  };

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    const referalCodefield = document.createElement("textarea");
    referalCodefield.innerText = snippet;
    document.body.appendChild(referalCodefield);
    referalCodefield.select();
    document.execCommand("copy");
    referalCodefield.remove();
    setIsCopied(true);
    // Toast({
    //   text: "Embed code copied to clipboard",
    //   timeout: 1200,
    //   type: "warning",
    //   progressBar: false,
    //   animation: {
    //     open: null,
    //     close: null,
    //   },
    // });
    setTimeout(() => {
      setIsCopied(false);
    }, 2500);
  };

  return (
    <div className={styles.snippetWrapper}>
      <pre className={styles.snippetContainer}>{snippet}</pre>
      {isCopied ? (
        <span className={classNames(styles.scippetCopyIcon, styles.successMsg)}>Copied!</span>
      ) : (
        <i className={classNames(styles.scippetCopyIcon, "iconCopy")} onClick={() => copyToClipboard()} />
      )}
    </div>
  );
}

export default StaffRole;
