import React from "react";

import defaultAvatar from "./defaultAvatar.svg";
import styles from "./index.module.sass";

function Avatar({ avatar }) {
  return <img src={avatar ? avatar : defaultAvatar} alt="Avatar" className={styles.avatarContainer} />;
}

export default Avatar;
