import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as actions from "actions";
import actionCable from "actioncable";
import * as requestTypes from "constants/requestTypes";
import { requestInProcess } from "selectors/request";
import { getAccountChatMessages } from "selectors/accounts";
import { getSessionUser } from "selectors/session";
import { getSessionData } from "utils/sessionData";
import { getProductsIds } from "selectors/products";

import Editor from "./Editor";
import Messenger from "./Messenger";
import Router from "./Router";

const propTypes = {};
const defaultProps = {};

function Contact(props) {
  const {
    match,
    messages,
    loadingMessages,
    onSubmit,
    fetchAccountChatMessages,
    fetchProductsCollection,
    addMessageToChat,
    currentUser,
    productIds,
    profilePage,
    onTyping,
    shopperIsTyping,
    typingState,
  } = props;
  const { accountId, id } = match.params;
  const [isProfilePage, setIsProfilePageg] = useState(false);

  useEffect(() => {
    fetchAccountChatMessages(accountId, id);
    fetchProductsCollection(accountId);
  }, []);

  return (
    <>
      {!isProfilePage && <Editor fetchProductsCollection={fetchProductsCollection} {...props} />}
      <Messenger shopperIsTyping={shopperIsTyping} onTyping={onTyping} {...props} />
      <Router setIsProfilePageg={setIsProfilePageg} {...props} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    messages: getAccountChatMessages(state),
    loadingMessages: requestInProcess(state, { requestType: requestTypes.ACCOUNT_CHAT_MESSAGES_FETCH }),
    currentUser: getSessionUser(state),
    productIds: getProductsIds(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchAccountChatMessages: (accountId, id) => dispatch(actions.fetchAccountChatMessages(accountId, id)),
  onSubmit: (accountId, id, isMessage) => dispatch(actions.postMessageInChat(accountId, id, isMessage)),
  fetchProductsCollection: (accountId) => dispatch(actions.fetchProductsCollection(accountId)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default enhance(Contact);
