import React from "react";
import PropTypes from "prop-types";
import cl from "classnames";

import { Item } from "../../shared";
import styles from "../../index.module.sass";

const propTypes = {
  height: PropTypes.object,
  fitPreference: PropTypes.string,
  bodyShape: PropTypes.string,
  bodyLength: PropTypes.string,
  bodyWaist: PropTypes.string,
};

const defaultProps = {
  height: {},
  fitPreference: "",
  bodyShape: "",
  bodyLength: "",
  bodyWaist: "",
};

function SizingData({ height, fitPreference, bodyShape, bodyLength, bodyWaist }) {
  const heightConvert = height.foot !== undefined ? `${height.foot}' ${height.inch}"` : "—";
  return (
    <ul className={cl(styles.personalList)}>
      <Item label="Height" value={heightConvert} styleColumn />
      <Item label="Fit Preference" value={fitPreference} styleColumn />
      <Item label="Shape" value={bodyShape} styleColumn />
      <Item label="Length" value={bodyLength} styleColumn />
      <Item label="Waist" value={bodyWaist} styleColumn />
    </ul>
  );
}

SizingData.defaultProps = defaultProps;
SizingData.propTypes = propTypes;

export default SizingData;
