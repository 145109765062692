import React, { useState, useRef } from "react";
import { history } from "stores/configureStore";
import PropTypes from "prop-types";
import classnames from "classnames";

import useOnClickOutside from "hooks/useOnClickOutside";
import { DASHBOARD_ACCOUNT_STAFF_PATH, getPath } from "constants/routes";
import { connect } from "react-redux";
import { getSessionUser } from "selectors/session";
import * as actions from "actions";

import DropDowItem from "./DropDownItem";
import styles from "./index.module.sass";

const propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
};

const defaultProps = {
  user: {
    role: "",
  },
};

function DropDownMenu({ accountStaffDeactivate, accountStaffActivate, isActive, accountId, staffId, user }) {
  const { role } = user;
  const dropDownAdminOptions = [
    {
      id: 0,
      title: "Profile",
      action: () => history.push(getPath(DASHBOARD_ACCOUNT_STAFF_PATH, { accountId, staffId })),
      iconClassName: "profileIcon",
    },
    {
      id: 2,
      title: isActive ? "Deactivate" : "Reactivate",
      action: () => (isActive ? accountStaffDeactivate() : accountStaffActivate()),
      iconClassName: isActive ? "deactivateIcon" : "activateIcon",
    },
  ];
  const dropDownOptions = [
    {
      id: 0,
      title: "Profile",
      action: () => history.push(getPath(DASHBOARD_ACCOUNT_STAFF_PATH, { accountId, staffId })),
      iconClassName: "profileIcon",
    },
  ];

  const options = role === "admin" ? dropDownAdminOptions : dropDownOptions;

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  const toogleDropDown = () => setIsOpen((oldIsOpen) => !oldIsOpen);

  const dropDOwnList = classnames({
    [`${styles.dropDownList}`]: true,
    [`${styles.dropDownListActive}`]: isOpen,
  });

  return (
    <div ref={ref}>
      <div className={styles.actionsStaff} onClick={toogleDropDown}>
        <span>Actions</span>
        <i className="staffDropdownIcon" />
      </div>
      <ul className={dropDOwnList}>
        {options.map((option) => (
          <DropDowItem key={option.id} {...option} />
        ))}
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: getSessionUser(state),
  };
}

DropDownMenu.defaultProps = defaultProps;
DropDownMenu.propTypes = propTypes;

export default connect(mapStateToProps, null)(DropDownMenu);
