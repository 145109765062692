import React from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import styles from "./index.module.sass";

function TabItem({ title, url, isActive, iconClassName, ...props }) {
  return (
    <NavLink className={classnames(styles.tabItem, isActive ? styles.active : "")} to={url} {...props}>
      <i className={iconClassName} />
      <span>{title}</span>
    </NavLink>
  );
}

export default TabItem;
