function configureRefreshFetch({ refreshSessionData, fetch }) {
  let refreshingTokenPromise = null;

  return (endpoint, request, params) => {
    if (refreshingTokenPromise !== null) {
      return refreshingTokenPromise
        .then(() => fetch(endpoint, request, params))
        .catch(() => fetch(endpoint, request, params));
    }

    return fetch(endpoint, request, params).catch((error) => {
      const {
        statusCode,
        errors: { title },
      } = error;

      if (statusCode === 401 && title === "The access token expired") {
        if (refreshingTokenPromise === null) {
          refreshingTokenPromise = new Promise((resolve, reject) => {
            refreshSessionData()
              .then(() => {
                refreshingTokenPromise = null;
                resolve();
              })
              .catch((refreshSessionDataError) => {
                refreshingTokenPromise = null;
                reject(refreshSessionDataError);
                window.location.reload();
              });
          });
        }

        return refreshingTokenPromise
          .catch(() => {
            throw error;
          })
          .then(() => fetch(endpoint, request, params));
      } else {
        throw error;
      }
    });
  };
}

export default configureRefreshFetch;
