import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "actions";
import { getIn } from "utils/ramda";
import * as requestTypes from "constants/requestTypes";
import { requestInProcess } from "selectors/request";

import {
  getProductsStyleDetail,
  getStyleProductsCollectionMeta,
  getProductsDetail,
  getShopProductsCollectionMeta,
} from "selectors/products";
import { Products, FilterPanel, Slider } from "../../../shared";

const propTypes = {
  productsClosetCollection: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};
const defaultProps = {
  productsReturnCollection: [],
  isLoading: false,
};

function Returns({
  accountId,
  id,
  fetchStyleProductsCollection,
  fetchProductsCollection,
  productsReturnCollection,
  productsReturnCollectionMeta,
  handleChangeData,
  isLoading,
  namePage,
  shopProductsCollectionMeta,
  shopProductsCollection,
}) {
  useEffect(() => {
    const param = {};
    fetchStyleProductsCollection(accountId, id, param, namePage);
  }, []);

  return (
    <>
      {/* <Slider accountId={accountId} id={id} /> */}
      <FilterPanel handleChangeData={handleChangeData} accountId={accountId} id={id} namePage={namePage} />
      <Products
        productsCollection={productsReturnCollection}
        meta={productsReturnCollectionMeta}
        accountId={accountId}
        id={id}
        namePage={namePage}
        withoutPrice
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    productsReturnCollection: getProductsStyleDetail(state),
    productsReturnCollectionMeta: getStyleProductsCollectionMeta(state),
    isLoading: requestInProcess(state, { requestType: requestTypes.STYLE_PRODUCTS_COLLECTION_FETCH }),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeData: (accountId, id, params, namePage) =>
      dispatch(actions.fetchStyleProductsCollection(accountId, id, params, namePage)),
    fetchStyleProductsCollection: (accountId, id, params = {}, namePage) =>
      dispatch(actions.fetchStyleProductsCollection(accountId, id, (params = {}), namePage)),
  };
};
Returns.propTypes = propTypes;
Returns.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Returns);
