import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.sass";

const propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
};

const defaultProps = {
  title: null,
};

function ToastTemplate({ title, text }) {
  return (
    <div className={styles.toastTemplateContainer}>
      {title && <span className={styles.toastTemplateTitle}>{title}</span>}
      <p className={styles.toastTemplateText} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}

ToastTemplate.defaultProps = defaultProps;
ToastTemplate.propTypes = propTypes;

export default ToastTemplate;
