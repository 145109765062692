import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as actions from "actions";
import { connect } from "react-redux";
import cl from "classnames";
import moment from "moment";
import { requestInProcess } from "selectors/request";
import * as requestTypes from "constants/requestTypes";

import { getInstagramFeed, getConsultant } from "selectors/accounts";
import dashboard from "./dashboard.module.sass";
import { luxIcon, postImg, loading } from "./Image";
import Item from "./Item";

const propTypes = {
  fetchAccountInstagramFeed: PropTypes.func.isRequired,
  match: PropTypes.object,
  instagramFeed: PropTypes.arrayOf({}),
  isLoadingInstagramFeed: PropTypes.bool,
};
const defaultProps = {
  instagramFeed: [{}],
  match: {},
  isLoadingInstagramFeed: false,
};

function Dashboard({
  match,
  fetchAccountInstagramFeed,
  instagramFeed,
  isLoadingInstagramFeed,
  fetchAccountConsultant,
  consultant,
  isLoadingСonsultant,
}) {
  const { accountId } = match.params;
  useEffect(() => {
    fetchAccountInstagramFeed(accountId);
    fetchAccountConsultant(accountId);
  }, []);

  localStorage.setItem("startingURL", `/accounts/${accountId}`);
  const oauthUrl = "/auth/instagram_graph";
  const handleSubmitMessage = (url) => {
    // const response = fetch(url, {
    //   method: "GET",
    // });
    // return response;
    window.location = url;
  };

  return (
    <div className="contentWrapper contentWrapperDark">
      <div className="container">
        <div className={dashboard.headerSection}>
          <h1 className={dashboard.title}>LATEST POSTS</h1>
          {/* <button className={cl(`btn ${dashboard.btn}`)}>manage following</button> */}
        </div>
        {isLoadingInstagramFeed ? (
          <div className={dashboard.loading}>
            {/* Uploading photos */}
            {/* <img src={loading} alt="loading..." /> */}
            <div className="loading" />
          </div>
        ) : (
          <div className={dashboard.postListingouter}>
            {instagramFeed && instagramFeed.length > 0 && (
              <ul className="listFlex">
                <Item
                  instagramFeed={instagramFeed}
                  username={consultant.data && consultant.data.contact.oauthRecord.name}
                />
              </ul>
            )
            //  : (
            //   <div className={dashboard.btnGroup}>
            //     <div className={dashboard.btn} onClick={() => handleSubmitMessage(oauthUrl)}>
            //       Instagram Connect
            //     </div>
            //   </div>
            // )
            }
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    instagramFeed: getInstagramFeed(state),
    isLoadingInstagramFeed: requestInProcess(state, { requestType: requestTypes.ACCOUNTS_INSTAGRAM_FEED_FETCH }),
    isLoadingСonsultant: requestInProcess(state, { requestType: requestTypes.ACCOUNTS_CONSULTANT }),
    consultant: getConsultant(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAccountInstagramFeed: (accountId) => dispatch(actions.fetchAccountInstagramFeed(accountId)),
  fetchAccountConsultant: (accountId) => dispatch(actions.fetchAccountConsultant(accountId)),
});

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
