import React from "react";
import classNames from "classnames";

import Mask from "../../formBase/Mask";

import styles from "./index.module.sass";

const defaultProps = {
  isError: false,
};

function ClassicMask({ isError, ...props }) {
  return <Mask className={classNames(styles.inputGroup, isError && styles.isError)} {...props} />;
}

ClassicMask.defaultProps = defaultProps;

export default ClassicMask;
