import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import cl from "classnames";

import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import ReactSelect from "components/shared/formDefault/ReactSelect";

// import styles from "./index.module.sass";

const propTypes = {
  references: PropTypes.shape({
    sizingInfo: PropTypes.shape({
      womenswear: PropTypes.shape({
        jean: PropTypes.arrayOf(),
      }),
    }),
  }),
};

const defaultProps = {
  references: {
    sizingInfo: {
      womenswear: {
        jean: [],
      },
    },
  },
};

function Menswear({ references }) {
  function getOptions(value) {
    const options =
      value &&
      value.map((item) => {
        return { value: item, label: item };
      });
    return options;
  }

  return (
    <>
      <Field
        label="Jeans"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.jean"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.jean)}
      />
      <Field
        label="Suit Size"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.suitSize"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.suitSize)}
      />
      <Field
        label="US Shoe"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.usShoe"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.usShoe)}
      />
      <Field
        label="EU Shoe"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.euShoe"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.euShoe)}
      />
      <Field
        label="Shoe Width"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.shoeWidth"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.shoeWidth)}
      />
      <Field
        label="Shirt Size"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.shirtSize"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.shirtSize)}
      />
      <Field
        label="Alpha Tops"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.alphaTops"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.alphaTops)}
      />
      <Field
        label="Bottoms"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.bottoms"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.bottoms)}
      />
      <Field
        label="Alpha Bottoms"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.alphaBottoms"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.alphaBottoms)}
      />
      <Field
        label="Neck"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.neck"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.neck)}
      />
      <Field
        label="Blazer"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.blazer"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.blazer)}
      />
      <Field
        label="Belt"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.belt"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.belt)}
      />
      <Field
        label="Undies"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.undies"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.undies)}
      />
      <Field
        label="Ring"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.ring"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.ring)}
      />
      <Field
        label="Hat"
        component={FormGroupAdapter}
        inputComponent={ReactSelect}
        name="sizingInfo.menswear.hat"
        placeholder=""
        type="select"
        smInput
        options={getOptions(references.sizingInfo && references.sizingInfo.menswear.hat)}
      />
    </>
  );
}

Menswear.defaultProps = defaultProps;
Menswear.propTypes = propTypes;

export default Menswear;
