import React, { useEffect } from "react";

import { DASHBOARD_AUTH_PATH, DASHBOARD_AUTH_RESTORE_PASSWORD_PATH } from "constants/routes";
import { getSessionData } from "utils/sessionData";
import SignUpForm from "./shared/Form";
import NavigateLink from "../shared/NavigateLink";
import styles from "./index.module.sass";

function SignUp() {
  return (
    <div className={styles.formWrapper}>
      <SignUpForm />
      <div className={styles.linkWrapper}>
        <NavigateLink to={DASHBOARD_AUTH_PATH}>Login</NavigateLink>
        <NavigateLink to={DASHBOARD_AUTH_RESTORE_PASSWORD_PATH}>Forgot password</NavigateLink>
      </div>
    </div>
  );
}

export default SignUp;
