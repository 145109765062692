import React from "react";
import PropTypes from "prop-types";
import cl from "classnames";

import styles from "./index.module.sass";

const propTypes = {};

const defaultProps = {};

function NavTab({ setIsOpen, isOpen }) {
  return (
    <ul className={styles.navTab}>
      <li onClick={() => setIsOpen("women")} className={cl(styles.navItem, isOpen === "women" && styles.navItemActive)}>
        Womenswear
      </li>
      <li onClick={() => setIsOpen("men")} className={cl(styles.navItem, isOpen === "men" && styles.navItemActive)}>
        MensWear
      </li>
      <li
        onClick={() => setIsOpen("children")}
        className={cl(styles.navItem, isOpen === "children" && styles.navItemActive)}
      >
        Childrenwear
      </li>
      <li onClick={() => setIsOpen("pet")} className={cl(styles.navItem, isOpen === "pet" && styles.navItemActive)}>
        Pet
      </li>
    </ul>
  );
}

NavTab.defaultProps = defaultProps;
NavTab.propTypes = propTypes;

export default NavTab;
