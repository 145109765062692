import {
  DASHBOARD_ACCOUNT_STAFF_LIST_PATH,
  DASHBOARD_ACCOUNT_STAFF_ACTIVATE_PATH,
  DASHBOARD_ACCOUNT_STAFF_DEACTIVATE_PATH,
  DASHBOARD_ACCOUNT_STAFF_PATH,
  getPath,
} from "constants/routes";
import { objectToFormData } from "object-to-formdata";
import { decamelizeKeys } from "../../utils/humps";
import { getRequest, patchRequest, postRequest, putRequest } from "../config/requests";

import fetch, { cfetchFormData } from "../config/fetch";

export default class StaffApiAdapter {
  static getAccountStaff(accountId) {
    return fetch(getPath(DASHBOARD_ACCOUNT_STAFF_LIST_PATH, { accountId }), getRequest());
  }

  static accountStaffActivate(accountId, staffId) {
    return fetch(getPath(DASHBOARD_ACCOUNT_STAFF_ACTIVATE_PATH, { accountId, staffId }), patchRequest({}));
  }

  static accountStaffDeactivate(accountId, staffId) {
    return fetch(getPath(DASHBOARD_ACCOUNT_STAFF_DEACTIVATE_PATH, { accountId, staffId }), patchRequest({}));
  }

  // static postStaffData(accountId, staff) {
  //   return fetch(
  //     getPath(DASHBOARD_ACCOUNT_STAFF_LIST_PATH, { accountId }),
  //     postRequest({
  //       body: {
  //         staff,
  //       },
  //     }),
  //   );
  // }

  static createStaffData = async (accountId, staff) => {
    const url = getPath(DASHBOARD_ACCOUNT_STAFF_LIST_PATH, { accountId });

    const formData = objectToFormData(decamelizeKeys({ staff }));
    formData.delete("staff[contact][avatar]", staff.contact.avatar);

    if (staff.contact.avatar && typeof staff.contact.avatar !== "string") {
      formData.append("staff[contact][avatar]", staff.contact.avatar);
    }
    if (staff.contact.avatar === null) {
      formData.append("staff[contact][avatar]", "");
    }

    const response = await cfetchFormData(url, {
      method: "POST",
      body: formData,
    });
    return response;
  };

  static editStaffData = async (accountId, staffId, staff) => {
    const url = getPath(DASHBOARD_ACCOUNT_STAFF_PATH, { accountId, staffId });

    const formData = new FormData();

    formData.append("staff[id]", staff.id);
    formData.append("staff[employee_id]", staff.employeeId);
    formData.append("staff[is_online]", staff.isOnline);
    formData.append("staff[is_active]", staff.isActive);
    formData.append("staff[role]", staff.role);
    formData.append("staff[alias]", staff.alias ? staff.alias : "");
    formData.append("staff[store_number]", staff.storeNumber ? staff.storeNumber : "");
    formData.append("staff[store_location]", staff.storeLocation ? staff.storeLocation : "");
    formData.append("staff[level]", staff.level ? staff.level : "");
    formData.delete("staff[contact][avatar]", staff.contact.avatar);
    staff.languages && staff.languages.map((item) => formData.append("staff[languages][]", item));
    if (staff.contact.avatar && typeof staff.contact.avatar !== "string") {
      formData.append("staff[contact][avatar]", staff.contact.avatar);
    }
    if (staff.contact.avatar === null) {
      formData.append("staff[contact][avatar]", "");
    }
    if (staff.timezone) {
      formData.append("staff[timezone]", staff.timezone);
    }
    const response = await cfetchFormData(url, {
      method: "PATCH",
      body: formData,
    });
    return response;
  };
}
