import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import {
  DASHBOARD_AUTH_PATH,
  DASHBOARD_AUTH_REGISTRATION_PATH,
  DASHBOARD_AUTH_SIGN_IN_PATH,
  DASHBOARD_AUTH_RESTORE_PASSWORD_PATH,
  DASHBOARD_AUTH_UPDATE_PASSWORD_PATH,
} from "constants/routes";

import Footer from "../Footer";
import Title from "./shared/Title";
import loginBackground from "./shared/image/loginBackground.jpg";
import SignInPage from "./SignInPage";
import RestorePasswordPage from "./RestorePasswordPage";
import UpdatePasswordPage from "./UpdatePasswordPage";
import SignUpPage from "./SignUpPage";

import styles from "./index.module.sass";

const pageTitles = [
  {
    id: 1,
    url: DASHBOARD_AUTH_SIGN_IN_PATH,
    title: "Welcome back, we think you’re wonderful!",
  },
  {
    id: 2,
    url: DASHBOARD_AUTH_RESTORE_PASSWORD_PATH,
    title: "Reset Password",
  },
  {
    id: 3,
    url: DASHBOARD_AUTH_PATH,
    title: "Welcome back, we think you’re wonderful!",
  },
  {
    id: 4,
    url: DASHBOARD_AUTH_UPDATE_PASSWORD_PATH,
    title: "Update Password",
  },
  {
    id: 4,
    url: DASHBOARD_AUTH_REGISTRATION_PATH,
    title: "Register to feel the difference and receive VIP status for being an early adoptor",
  },
];

const getPageTitle = (currentUrl) => pageTitles.find((pageTitle) => pageTitle.url === currentUrl).title;

function AuthPage({ location: { pathname } }) {
  return (
    <>
      <div className={styles.authPage}>
        <div className={styles.authPageWrapper}>
          <div className={styles.leftSection}>
            <Title>{getPageTitle(pathname)}</Title>
            <Switch>
              <Route path={DASHBOARD_AUTH_UPDATE_PASSWORD_PATH} component={UpdatePasswordPage} exact />
              <Route path={[DASHBOARD_AUTH_PATH, DASHBOARD_AUTH_SIGN_IN_PATH]} component={SignInPage} exact />
              <Route path={DASHBOARD_AUTH_REGISTRATION_PATH} component={SignUpPage} exact />
              <Route path={DASHBOARD_AUTH_RESTORE_PASSWORD_PATH} component={RestorePasswordPage} exact />
            </Switch>
          </div>
          <div className={styles.rightSection} style={{ backgroundImage: `url(${loginBackground})` }}>
            <div className={styles.title}>
              <h3>"Clothes mean nothing until someone lives in them"</h3>
              <p>- Marc Jacobs</p>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer className={styles.authPage__Footer} /> */}
    </>
  );
}

export default withRouter(AuthPage);
