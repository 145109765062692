import React from "react";

import styles from "./index.module.sass";

const headerKeys = [
  {
    id: 0,
    title: "User",
    dataType: "text-short",
  },
  {
    id: 1,
    title: "Role",
    dataType: "text-short",
  },
  {
    id: 2,
    title: "Status",
    dataType: "text-short",
  },
  {
    id: 3,
    title: "Active",
    dataType: "text-short",
  },
  {
    id: 4,
    title: "",
    dataType: "text-short",
  },
];

function StaffTableHeader() {
  return (
    <thead className={styles.tableHead}>
      <tr>
        {headerKeys.map(({ id, title, dataType }) => (
          <th key={id} datatype={dataType}>
            {title}
          </th>
        ))}
      </tr>
    </thead>
  );
}

export default StaffTableHeader;
