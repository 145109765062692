import numeral from "numeral";
import moment from "moment";
import { format, isDate, isToday, isYesterday } from "date-fns";

import pluralize from "pluralize";

export function currencyFormat(value) {
  return numeral(value).format("$0,0");
}

export function decimalCurrencyFormat(value) {
  return numeral(value).format("$0,0.00");
}

export function percentFormat(value) {
  return numeral(value / 100).format("0.00%");
}

export function dateFormat(date) {
  return moment(date).format("MM/DD/YYYY");
}

export function dateHumanFormat(date) {
  return moment(date).format("DD MMM YYYY");
}

export function dateWithTimeFormat(date) {
  return moment(date).format("YYYY-MM-DD h:mm A");
}

export function dateIsValid(date) {
  return moment(date).isValid();
}

export function dataAfter(date) {
  const now = moment(new Date()).format("MM/DD/YYYY");
  const isBefore = new Date(date) < new Date(now);
  return isBefore;
}

export function displayTerm(term) {
  return `${term} ${pluralize("month", term)}`;
}

const toDate = (date) => (isDate(date) ? date : new Date(date));

export const formatDateTime = (value) => {
  const date = toDate(value);

  return format(date, "h:mm a");
};

export const formatChatDate = (value) => {
  const date = toDate(value);

  if (isToday(date)) {
    return "Today";
  }

  if (isYesterday(date)) {
    return "Yesterday";
  }

  return format(date, "E, M/dd/yy");
};
