import Avatar from "./Avatar";
import FormBlock from "./FormBlock";
import FieldsCollection from "./FieldsCollection";
import BtnGroup from "./BtnGroup";
import NavTab from "./NavTab";
import Womenswear from "./Womenswear";
import WomenswearMeasurements from "./WomenswearMeasurements";
import Menswear from "./Menswear";
import MenswearMeasurements from "./MenswearMeasurements";
import Childrenswear from "./Childrenswear";
import Pets from "./Pets";
import PasswordUpdate from "./PasswordUpdate";
import SelectedItems from "./SelectedItems";
import DataCompliance from "./DataCompliance";
import Pereferences from "./Pereferences";
import PersonalInfo from "./PersonalInfo";
import TagsInput from "./TagsInput";
import Social from "./Social";
import Photos from "./Photos";

export {
  Avatar,
  FormBlock,
  FieldsCollection,
  BtnGroup,
  NavTab,
  Womenswear,
  WomenswearMeasurements,
  Menswear,
  MenswearMeasurements,
  Childrenswear,
  Pets,
  PasswordUpdate,
  SelectedItems,
  DataCompliance,
  PersonalInfo,
  Pereferences,
  TagsInput,
  Social,
  Photos,
};
