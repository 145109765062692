import React from "react";
import PropTypes from "prop-types";
import cl from "classnames";

import info from "./info.svg";
import styles from "./index.module.sass";

const propTypes = {};

const defaultProps = {};

function InfoBlock({ title }) {
  return (
    <div className={styles.wrapper}>
      <img src={info} alt="info" />
      <span>{title}</span>
    </div>
  );
}

InfoBlock.defaultProps = defaultProps;
InfoBlock.propTypes = propTypes;

export default InfoBlock;
