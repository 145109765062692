import React from "react";
import { Field } from "react-final-form";

import FormGroupAdapter from "../FormGroup/Adapter";
import SwitchItem from "../SwitchItem";
import InfoBlock from "./shared/InfoBlock";
import styles from "../FormGroup/index.module.sass";

function Switch({ title, options, radioToggle, isInfo, description, appearance }) {
  return (
    <div className={styles.formGroup}>
      <span
        className={radioToggle ? styles.formGroupRadioToggleLabel : styles.formGroupRadioLabel}
        style={appearance && { paddingBottom: "0", minWidth: "120px" }}
      >
        {title}
      </span>
      <div className={styles.radioGroup}>
        {options.map((option) => {
          return (
            <Field
              {...option}
              type="radio"
              key={option.value}
              component={FormGroupAdapter}
              inputComponent={(props) => {
                return (
                  <SwitchItem {...props} radioToggle={radioToggle}>
                    {option.value}
                  </SwitchItem>
                );
              }}
            />
          );
        })}
      </div>
      {isInfo && <InfoBlock title={description} />}
    </div>
  );
}

export default Switch;
