import React, { useState } from "react";
import { without, reverse } from "ramda";
import { useDrop } from "react-dnd";
import * as actions from "actions";
import { connect } from "react-redux";
import cx from "classnames";

import editor from "./editor.module.sass";

function TargetBox({
  onSendEditorProductCollection,
  accountId,
  id,
  onDrop,
  onRemoveProductToEditor,
  fetchProductsCollection,
  onAddProductToEditor,
  dropProducts,
  setDropProducts,
}) {
  // const [dropProducts, setDropProducts] = useState([]);
  const isDisabled = dropProducts.length === 0;

  const [{ isActive, collection, opacity }, drop] = useDrop({
    accept: "product",
    drop: onDrop,
    collect: (monitor) => {
      return {
        isActive: monitor.canDrop(),
        collection:
          monitor.getDropResult() && dropProducts.length < 4 && setDropProducts([...dropProducts, monitor.getItem()]),
        opacity: isActive && dropProducts.length !== 0 ? 0.4 : 1,
      };
    },
  });

  // WIP !!!!!

  const productIds = dropProducts.map((productData) => productData.product.id);

  const handleProductsCollection = () => {
    const editorCollection = JSON.parse(localStorage.getItem(`ProductsToEditor: ${id}`));
    const filteredItems = without(productIds, editorCollection);

    localStorage.setItem(`ProductsToEditor: ${id}`, JSON.stringify(filteredItems));
    onSendEditorProductCollection(accountId, id, reverse(productIds));
    onRemoveProductToEditor(productIds);
    fetchProductsCollection(accountId);
    setDropProducts([]);
  };

  const shareClasses = cx({
    [`${editor.btn}`]: true,
    [`${editor.btnDisabled}`]: isDisabled,
  });

  const resetClasses = cx({
    [`${editor.reset}`]: true,
    [`${editor.resetDisabled}`]: isDisabled,
  });

  const handleRemoveProduct = (id) => {
    onAddProductToEditor(id);
    setDropProducts(dropProducts.filter((item) => item.product.id !== id));
  };

  const handleRemoveProducts = () => {
    productIds.map((id) => onAddProductToEditor(id));
    setDropProducts([]);
  };

  return (
    <div className={editor.targetBox}>
      <div className={editor.targetBoxContainer}>
        <ul
          ref={drop}
          className={cx(editor.products, isActive && dropProducts.length === 4 && editor.pointerNone)}
          data-name="areaDrag_and_drop"
          // style={{ pointerEvents: dropProducts.length >= 4 ? "none" : "initial" }}
        >
          <div
            style={{ position: "relative" }}
            // className={`editor_gallery editor_gallery-${isActive ? dropProducts.length + 1 : dropProducts.length}`}
            className={`editor_gallery editor_gallery-${dropProducts.length}`}
          >
            {reverse(dropProducts).map((productData) => (
              <li className="editor_gallery__item">
                <i className="iconRemoveEditor" onClick={() => handleRemoveProduct(productData.product.id)} />
                <img className="gallery__img" src={productData.product.featuredImage.src} alt="product img" />
              </li>
            ))}
            {(isActive || dropProducts.length === 0) && dropProducts.length !== 4 && (
              <li style={{ opacity }} className={editor.dragBoxItem}>
                <span>
                  Drag & Drop
                  <br />
                  item
                </span>
              </li>
            )}
          </div>
        </ul>
        <div className={editor.buttonGroup}>
          <button
            data-name="buttonShare"
            onClick={handleProductsCollection}
            disabled={isDisabled}
            className={`${shareClasses}`}
          >
            Share
          </button>
          <button
            data-name="buttonReset"
            onClick={() => handleRemoveProducts()}
            disabled={isDisabled}
            className={resetClasses}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  onSendEditorProductCollection: (accountId, id, productIds) =>
    dispatch(actions.sendEditorProductCollection(accountId, id, productIds)),
  onRemoveProductToEditor: (productIds) => dispatch(actions.removeProductToEditorSucceses(productIds)),
  onAddProductToEditor: (productId) => dispatch(actions.addProductToEditorSucceses(productId)),
});

export default connect(null, mapDispatchToProps)(TargetBox);
