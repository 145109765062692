// @flow

import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.sass";

const defaultProps = {
  children: null,
};

const propTypes = {
  children: PropTypes.string,
  to: PropTypes.string.isRequired,
};

function NavigateLink({ children, to, signUpButton }) {
  return (
    <NavLink to={to} className={classNames(styles.forgotPaswordLink, signUpButton && styles.signUpButton)}>
      {children}
    </NavLink>
  );
}

NavigateLink.defaultProps = defaultProps;
NavigateLink.propTypes = propTypes;

export default NavigateLink;
