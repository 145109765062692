import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  products: [],
};

function Grid(props) {
  const { products } = props;

  return (
    <>
      <div className={`gallery gallery-${products.length}`}>
        {products.map((product, index) => (
          <div key={index} className="gallery__item">
            <img alt={product.title} className="gallery__img" src={product.featuredImage.src} />
          </div>
        ))}
      </div>
    </>
  );
}

Grid.propTypes = propTypes;
Grid.defaultProps = defaultProps;

export default Grid;
