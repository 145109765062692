import React from "react";
import classNames from "classnames";

import BaseRadioButton from "../../formBase/RadioButton";
import styles from "./index.module.sass";

function SwitchItem({ className, children, name, isError, checked, title, radioToggle, ...props }) {
  return (
    <label className={classNames(radioToggle ? styles.radioToggleLable : styles.radioLable, checked && styles.active)}>
      <BaseRadioButton name={name} {...props} className={styles.hiddenCheckbox} checked={checked} />
      {radioToggle ? (
        <div className={styles.redioToggleContainer} disabled>
          <div className={classNames(styles.radioIndicator, checked && styles.active)} />
        </div>
      ) : (
        <div className={styles.indicatorContainer} disabled>
          <div className={classNames(styles.indicator, checked && styles.active)} />
        </div>
      )}
      <div className={styles.title}>{title}</div>
    </label>
  );
}

export default SwitchItem;
