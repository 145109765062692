import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import fetch from "apiAdapters/config/fetch";

import { WEATHER_ACCOUNT_SHOPPER_PATH, getPath } from "constants/routes";

import weather from "./weather.module.sass";

const propTypes = {
  accountId: PropTypes.string,
  id: PropTypes.string,
};
const defaultProps = {
  accountId: "",
  id: "",
};

function Weather({ accountId, id }) {
  const url = getPath(WEATHER_ACCOUNT_SHOPPER_PATH, { accountId, id });

  // const { country, city } = location;
  useEffect(() => {
    getWeather();
  }, []);

  const [forecasts, setForecasts] = useState([]);
  const getWeather = async () => {
    const data = await fetch(url);
    setForecasts(data.dailyForecasts);
  };

  return (
    <div className={weather.wrapper}>
      <div className={weather.header}>
        <h3 className={weather.title}>Weather</h3>
        {/* <span>
          {country},{city}
        </span> */}
      </div>
      <ul className={weather.list}>
        {forecasts.map((item, index) => (
          <li key={index} className={weather.item}>
            <span>{moment(item.date).format("ddd")}</span>
            <img src={`https://static.luxlock.com/accuweather/${item.day.icon}.png`} />
            <strong>
              {Math.round((item.temperature.minimum.value - 32) * (5 / 9))}
              °C 
              {Math.round((item.temperature.maximum.value - 32) * (5 / 9))}
              °C
            </strong>
          </li>
        ))}
      </ul>
    </div>
  );
}

Weather.propTypes = propTypes;
Weather.defaultProps = defaultProps;

export default Weather;
