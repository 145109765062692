import React, { useRef } from "react";
import classNames from "classnames";

import BaseInput from "../../formBase/Input";
import styles from "./index.module.sass";

function ClassicInput({ isError, onKeyPress: onKeyPressFunction, ...props }) {
  const inputRef = useRef(null);

  const onKeyPress = (event) => {
    if (event.which === 13) {
      event.preventDefault();
      onKeyPressFunction(event.target.value);
      inputRef.current.value = "";
    }
  };

  return (
    <BaseInput
      {...props}
      className={classNames(styles.inputGroup, isError && styles.isError)}
      onKeyPress={onKeyPressFunction && onKeyPress}
      refComponent={inputRef}
    />
  );
}

export default ClassicInput;
