import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getIsActiveStatusStaffDetail,
  getIsOnlineStatusStaffDetail,
  getStaffRoleDetail,
  getContactStaffDetail,
} from "selectors/staffs";

import * as actions from "actions";

import AccountInfo from "./AccountInfo";
import ActiveStatus from "./ActiveStatus";
import OnlineStatus from "./OnlineStatus";
import StaffRole from "./StaffRole";
import DropDownMenu from "./DropDownMenu";

import styles from "./index.module.sass";

const propTypes = {
  role: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
};

const defaultProps = {
  role: "no role",
};

function Row({ isActive, isOnline, role, contact, ...props }) {
  return (
    <tr>
      <td>
        <AccountInfo {...contact} {...props} />
      </td>

      <td>
        <StaffRole role={role} />
      </td>

      <td>
        <OnlineStatus isOnline={isOnline} />
      </td>

      <td>
        <ActiveStatus isActive={isActive} />
      </td>

      <td>
        <DropDownMenu {...props} isActive={isActive} />
      </td>
    </tr>
  );
}

const mapStateToProps = (state, { staffId }) => ({
  isActive: getIsActiveStatusStaffDetail(state, staffId),
  isOnline: getIsOnlineStatusStaffDetail(state, staffId),
  contact: getContactStaffDetail(state, staffId),
  role: getStaffRoleDetail(state, staffId),
});

const mapDispatchToProps = (dispatch, { staffId, accountId }) => {
  return {
    accountStaffDeactivate: () => dispatch(actions.accountStaffDeactivate(accountId, staffId)),
    accountStaffActivate: () => dispatch(actions.accountStaffActivate(accountId, staffId)),
  };
};

Row.defaultProps = defaultProps;
Row.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(Row);
