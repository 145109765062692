import React from "react";
import { Field } from "react-final-form";

import FormGroupAdapter from "../FormGroup/Adapter";
import RadioButton from "../RadioButton";
import styles from "./index.module.sass";

function Switcher({ title, options }) {
  return (
    <div className={styles.radioGroup}>
      {options.map((option) => (
        <Field
          {...option}
          type="radio"
          key={option.id}
          component={FormGroupAdapter}
          inputComponent={(props) => <RadioButton {...props}>{option.value}</RadioButton>}
        />
      ))}
    </div>
  );
}

export default Switcher;
