import React from "react";
import PropTypes from "prop-types";
import cl from "classnames";

import { Item } from "../../shared";
import styles from "../../index.module.sass";

const propTypes = {
  womenswear: PropTypes.shape({
    jean: PropTypes.string,
    dressSize: PropTypes.string,
    usShoe: PropTypes.string,
    euShoe: PropTypes.string,
    shoeWidth: PropTypes.string,
    shirtSize: PropTypes.string,
    alphaTops: PropTypes.string,
    bottoms: PropTypes.string,
    alphaBottoms: PropTypes.string,
    belt: PropTypes.string,
    bra: PropTypes.string,
    cup: PropTypes.string,
    ring: PropTypes.string,
    undies: PropTypes.string,
    hat: PropTypes.string,
    rise: PropTypes.string,
  }),
};

const defaultProps = {
  womenswear: {
    jean: "",
    dressSize: "",
    usShoe: "",
    euShoe: "",
    shoeWidth: "",
    shirtSize: "",
    alphaTops: "",
    bottoms: "",
    alphaBottoms: "",
    belt: "",
    bra: "",
    cup: "",
    ring: "",
    hat: "",
    rise: "",
  },
};

function Womenswear({ womenswear }) {
  const {
    jean,
    dressSize,
    usShoe,
    euShoe,
    shoeWidth,
    shirtSize,
    alphaTops,
    bottoms,
    alphaBottoms,
    belt,
    bra,
    cup,
    undies,
    ring,
    hat,
    rise,
  } = womenswear;
  return (
    <ul className={styles.sizingDetails}>
      <Item label="Jeans" value={jean} />
      <Item label="DressSize" value={dressSize} />
      <Item label="US Shoe" value={usShoe} />
      <Item label="EU Shoe" value={euShoe} />
      <Item label="Shoe Width" value={shoeWidth} />
      <Item label="Shirt Size" value={shirtSize} />
      <Item label="Alpha Tops" value={alphaTops} />
      <Item label="Bottoms" value={bottoms} />
      <Item label="Alpha Bottoms" value={alphaBottoms} />
      <Item label="Belt" value={belt} />
      <Item label="Bra" value={bra} />
      <Item label="Cup" value={cup} />
      <Item label="Undies" value={undies} />
      <Item label="Ring" value={ring} />
      <Item label="Hat" value={hat} />
      <Item label="Rise" value={rise} />
    </ul>
  );
}

Womenswear.defaultProps = defaultProps;
Womenswear.propTypes = propTypes;

export default Womenswear;
