import React from "react";
import PropTypes from "prop-types";

import cl from "classnames";

import { Item } from "../../../shared";
import styles from "../../../index.module.sass";

const propTypes = {
  menswear: PropTypes.shape({
    chest: PropTypes.string,
    waist: PropTypes.string,
    inseam: PropTypes.string,
    hip: PropTypes.string,
    rise: PropTypes.string,
    head: PropTypes.string,
    armLength: PropTypes.string,
    sleeveLength: PropTypes.string,
    calf: PropTypes.string,
    shoulder: PropTypes.string,
    ankle: PropTypes.string,
    wrist: PropTypes.string,
    knee: PropTypes.string,
  }),
};

const defaultProps = {
  menswear: {
    chest: "",
    waist: "",
    inseam: "",
    hip: "",
    rise: "",
    head: "",
    armLength: "",
    sleeveLength: "",
    calf: "",
    shoulder: "",
    ankle: "",
    wrist: "",
    knee: "",
  },
};

function Men({ menswear }) {
  const {
    chest,
    waist,
    inseam,
    hip,
    rise,
    head,
    armLength,
    sleeveLength,
    calf,
    shoulder,
    ankle,
    wrist,
    knee,
  } = menswear;
  return (
    <ul className={styles.sizingDetails}>
      <Item label="Chest" value={chest} />
      <Item label="Waist" value={waist} />
      <Item label="Inseam" value={inseam} />
      <Item label="Hips" value={hip} />
      <Item label="Rise" value={rise} />
      <Item label="Head" value={head} />
      <Item label="Arm Length" value={armLength} />
      <Item label="Sleeve Length" value={sleeveLength} />
      <Item label="Calf" value={calf} />
      <Item label="Shoulder" value={shoulder} />
      <Item label="Ankle" value={ankle} />
      <Item label="Wrist" value={wrist} />
      <Item label="Knee" value={knee} />
    </ul>
  );
}

Men.defaultProps = defaultProps;
Men.propTypes = propTypes;

export default Men;
