import React from "react";
import { withRouter, Switch, Route } from "react-router-dom";

import {
  DASHBOARD_ACCOUNT_STAFF_LIST_PATH,
  DASHBOARD_ACCOUNT_STAFF_NEW_PATH,
  DASHBOARD_ACCOUNT_STAFF_PATH,
} from "constants/routes";

import BackButton from "./BackButton";
import TabItems from "./TabItems";
import styles from "./index.module.sass";

function Tabs() {
  return (
    <div className={styles.tabsContainer}>
      <Switch>
        <Route path={[DASHBOARD_ACCOUNT_STAFF_NEW_PATH, DASHBOARD_ACCOUNT_STAFF_PATH]} render={BackButton} exact />

        <Route path={DASHBOARD_ACCOUNT_STAFF_LIST_PATH} component={TabItems} />
      </Switch>
    </div>
  );
}

export default withRouter(Tabs);
