import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import * as actions from "actions";
import cl from "classnames";
import * as requestTypes from "constants/requestTypes";
import { requestInProcess } from "selectors/request";

import { getProductsDetail, getShopProductsCollectionMeta } from "selectors/products";
import { Products, FilterPanel, Slider } from "../shared";
import shop from "./shop.module.sass";

const propTypes = {
  setShowNav: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.string,
    }),
  }),
  shopProductsCollection: PropTypes.arrayOf(
    PropTypes.shape({
      meta: PropTypes.shape({
        isLastPage: PropTypes.bool,
        nextPage: PropTypes.number,
      }),
      data: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
  isLoading: PropTypes.bool,
  fetchShopProductsLoadCollection: PropTypes.func,
  handleChangeData: PropTypes.func.isRequired,
  // onAddProductToEditor: PropTypes.func.isRequired,
  // onRemoveProductToEditor: PropTypes.func.isRequired,
};
const defaultProps = {
  match: {
    params: {
      accountId: "",
    },
  },
  shopProductsCollection: [
    {
      meta: {},
      data: [],
    },
  ],
  isLoading: false,
};

// WIP!!!!

function Shop(props) {
  const {
    setShowNav,
    shopProductsCollection,
    isLoading,
    fetchProductsCollection,
    match,
    handleChangeData,
    productIds,
    shopProductsCollectionMeta,
    // onAddProductToEditor,
    // onRemoveProductToEditor,
  } = props;
  const { accountId, id } = match.params;

  useEffect(() => {
    // fetchAccountShopProductsCollection(accountId);
    setShowNav(true);
    return () => setShowNav(false);
  }, []);

  return (
    <>
      <div className={cl(`container ${shop.contentRight}`)}>
        <Slider accountId={accountId} id={id} />
        <FilterPanel handleChangeData={handleChangeData} accountId={accountId} id={id} />
        <Products
          productsCollection={shopProductsCollection}
          meta={shopProductsCollectionMeta}
          productIds={productIds}
          accountId={accountId}
          id={id}
        />
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    // products: getProductsDetail(state, props.productIds),
    shopProductsCollectionMeta: getShopProductsCollectionMeta(state),
    shopProductsCollection: getProductsDetail(state),
    isLoading: requestInProcess(state, { requestType: requestTypes.SHOP_PRODUCTS_COLLECTION_FETCH }),
  };
}

const mapDispatchToProps = (dispatch) => ({
  handleChangeData: (accountId, id, params) => dispatch(actions.fetchProductsCollection(accountId, id, params)),
  // onAddProductToEditor: (productId) => dispatch(actions.addProductToEditorSucceses(productId)),
  // onRemoveProductToEditor: (productId) => dispatch(actions.removeProductToEditorSucceses(productId)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

Shop.propTypes = propTypes;
Shop.defaultProps = defaultProps;

export default enhance(Shop);
