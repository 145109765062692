import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cl from "classnames";
import InputPhotoFile from "components/shared/formDefault/InputPhotoFile";
import { connect } from "react-redux";
import * as actions from "actions";
import { getShopperData } from "selectors/shopperProfile";

import clear from "./clear.svg";
import styles from "./index.module.sass";
import photoDefault from "./photoDefault.svg";

const propTypes = {};

const defaultProps = {};

function PhotoItem({ submitPhoto, item, handleRemoveImage, fetchShopperPhotos, shopper }) {
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const [isSelectedPhoto, setIsSelectedPhoto] = useState(false);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    // fetchShopperPhotos();
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    if (isSelectedPhoto) {
      clearTimeout(typingTimer);
      const typingTimer = setTimeout(() => {
        setIsSelectedPhoto(false);
      }, 500);
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
    submitPhoto(e.target.files[0]);
    setIsSelectedPhoto(true);
  };

  const removeImage = (id) => {
    handleRemoveImage(id);
    setIsSelectedPhoto(false);
  };

  // WIP !!!!!!!!!

  const InputFile = (props) => (
    <>
      <div className={styles.inputBlock}>
        <img className={styles.defaultImg} src={photoDefault} />
        {!isSelectedPhoto && <input type="file" onChange={onSelectFile} />}
      </div>
    </>
  );

  return (
    <>
      {shopper && shopper.photos && shopper.photos[0] ? (
        <div className={styles.photo}>
          <img src={shopper.photos[0].image} />
          <img className={styles.iconClear} src={clear} alt="clear" onClick={() => removeImage(shopper.photos[0].id)} />
        </div>
      ) : (
        <InputFile />
      )}
      {shopper && shopper.photos && shopper.photos[1] ? (
        <div className={styles.photo}>
          <img src={shopper.photos[1].image} />
          <img className={styles.iconClear} src={clear} alt="clear" onClick={() => removeImage(shopper.photos[1].id)} />
        </div>
      ) : (
        <InputFile />
      )}
      {shopper && shopper.photos && shopper.photos[2] ? (
        <div className={styles.photo}>
          <img src={shopper.photos[2].image} />
          <img className={styles.iconClear} src={clear} alt="clear" onClick={() => removeImage(shopper.photos[2].id)} />
        </div>
      ) : (
        <InputFile />
      )}
    </>
  );
}

const mapStateToProps = (state, props) => {
  return {
    // shopper: getShopperData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchShopperPhotos: () => dispatch(actions.fetchShopperPhotos()),
  submitPhoto: (photo) => dispatch(actions.submitPhoto(photo)),
  handleRemoveImage: (id) => dispatch(actions.removeImage(id)),
});

PhotoItem.defaultProps = defaultProps;
PhotoItem.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(PhotoItem);
