import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InfoBlock from "./shared/InfoBlock";
import styles from "./index.module.sass";
// import useClassNames from 'hooks/useClassNames';

const propTypes = {
  className: PropTypes.string,
  component: PropTypes.elementType.isRequired,
  error: PropTypes.node,
  help: PropTypes.node,
  inputProps: PropTypes.object,
  invalid: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  error: undefined,
  help: undefined,
  inputProps: {},
  invalid: false,
  label: undefined,
  required: false,
};

function FormGroup({
  error,
  help,
  component: Component,
  inputProps,
  invalid,
  label,
  name,
  required,
  className,
  isInfo,
  smInput,
  titleInfo,
  margin,
}) {
  // const groupClass = useClassNames(['form__group', className, invalid && 'form__group-errored']);

  const classes = classNames({
    [styles.fieldContainer]: true,
    [styles.fieldContainerSmall]: smInput,
  });

  const classesLabel = classNames({
    [styles.formGroupLabel]: true,
    [styles.formGroupLabelSmall]: smInput,
  });

  const classesFormGroup = classNames({
    [styles.formGroup]: true,
    [styles.formGroupSmall]: smInput,
  });

  return (
    <div
      className={classNames(classesFormGroup, invalid && error ? styles.isError : "", className && className)}
      style={{ margin: `${margin}` }}
    >
      {label && (
        <label className={classesLabel} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={classes}>
        <Component {...inputProps} isError={invalid} />
        {invalid && error && (
          <span className={styles.errorMessage}>
            <div dangerouslySetInnerHTML={{ __html: error }} />
          </span>
        )}
        {isInfo && <InfoBlock titleInfo={titleInfo} />}
      </div>
    </div>
  );
}

FormGroup.propTypes = propTypes;
FormGroup.defaultProps = defaultProps;

export default FormGroup;
