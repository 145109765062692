import { createSelector } from "reselect";

import { identity, propOr, find, propEq } from "ramda";

export const initialState = {
  messages: {},
  collection: [],
  consultant: {},
  appearance: {
    data: {},
  },
  instagramFeed: [],
  activityEvents: {},
  countMessages: [],
};

const getProps = (state, props) => props;

export function accountsSelector(state) {
  return state.account || initialState;
}

export const getAccountsStore = createSelector(accountsSelector, identity);

export const getAccountsCollection = createSelector(getAccountsStore, (accounts) => accounts.collection);

export const getAppearanceData = createSelector(getAccountsStore, (accounts) => accounts.appearance);

export const getAccountUsersActive = createSelector(getAccountsStore, (account) => {
  return account.shoppers;
});

export const getCountMessages = createSelector(getAccountsStore, (account) => {
  return account.countMessages;
});

// export const getAccountUser = createSelector(getAccountsStore, (collection) => collection.products);
export const getAccountUser = createSelector(getAccountUsersActive, getProps, (usersCollection, props) => {
  const data = propOr([], "data", usersCollection);
  const user = find(propEq("id", props))(data);
  return {
    user,
  };
});

export const getAccountChatMessages = createSelector(getAccountsStore, (account) => account.messages);

export const getConsultant = createSelector(getAccountsStore, (account) => account.consultant);

export const getInstagramFeed = createSelector(getAccountsStore, (account) => account.instagramFeed);

export const getActivityEvents = createSelector(getAccountsStore, (account) => account.activityEvents.data);

export const getBandWidthCount = createSelector(getAccountsStore, (account) => account.consultant.data);

export const getCurrentAccount = createSelector(getAccountsCollection, getProps, (accounts, props) => {
  const { accountId } = props;

  if (accounts && accounts.data) {
    const account = find(propEq("id", accountId))(accounts.data);
    return account;
  }

  return {};
});
