import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cl from "classnames";
import { requestInProcess } from "selectors/request";
import * as requestTypes from "constants/requestTypes";

import { getShopperInstagramFeed } from "selectors/shopperProfile";
import style from "./style.module.sass";
import loading from "./image/loading.gif";

const propTypes = {
  onSendInstagramMesage: PropTypes.func.isRequired,
  isLoadingInstagramFeed: PropTypes.bool,
};
const defaultProps = {};

function InsagramFeed({ onSendInstagramMesage, instagramFeed, isLoadingInstagramFeed }) {
  if (isLoadingInstagramFeed) {
    return (
      <div className={cl(style.bodyWrapper, style.social)}>
        <h3 className={style.title}>Social</h3>
        <div className="loading"></div>
      </div>
    );
  }

  return (
    <div className={cl(style.bodyWrapper, style.social)}>
      <h3 className={style.title}>Social</h3>
      {
        //   instagramFeed && instagramFeed.length > 0 ? (
        //   <ul className={style.socialListing}>
        //     {instagramFeed.map((item) => (
        //       <>
        //         <li key={item.id} className={style.socialListingItem}>
        //           <div className={style.socialContent}>
        //             <img src={item.thumbnailUrl ? item.thumbnailUrl : item.mediaUrl} />
        //           </div>
        //         </li>
        //       </>
        //     ))}
        //   </ul>
        // ) : (
      }
      {process.env.RAILS_ENV === "production" ? (
        <>
          <button disabled onClick={onSendInstagramMesage} className={cl(`btn btn-disabled ${style.btnInst}`)}>
            Request Instagram Access
          </button>
          <span className={style.comingSoon}>Coming Soon</span>
        </>
      ) : (
        <>
          <button onClick={onSendInstagramMesage} className={cl(`btn ${style.btnInst}`)}>
            Request Instagram Access
          </button>
        </>
      )}
    </div>
  );
}

// shopper: getShopper(state),

const mapStateToProps = (state) => {
  return {
    instagramFeed: getShopperInstagramFeed(state),
    isLoadingInstagramFeed: requestInProcess(state, { requestType: requestTypes.SHOPPER_INSTAGRAM_FETCH }),
  };
};

InsagramFeed.propTypes = propTypes;
InsagramFeed.defaultProps = defaultProps;

export default connect(mapStateToProps, null)(InsagramFeed);
