import createNumberMask from "text-mask-addons/dist/createNumberMask";

export const phoneMask = [
  "+",
  1,
  " ",
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
export const ssnMask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

export const currencyMask = createNumberMask({
  allowDecimal: true,
  prefix: "",
  decimalLimit: 2,
  allowNegative: false,
  maxValue: 1000000000,
});

export const decimalCurrencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  integerLimit: 10,
});

export const percentMask = createNumberMask({
  prefix: "",
  suffix: "%",
  allowDecimal: true,
  integerLimit: 3,
});

export const cvvMask = createNumberMask({
  prefix: "",
  integerLimit: 4,
  includeThousandsSeparator: false,
  allowLeadingZeroes: true,
});

export const numberMask = createNumberMask({
  prefix: "",
  integerLimit: 13,
});

export const decimalNumberMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  integerLimit: 13,
});
