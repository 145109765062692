import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
// import cl from "classnames";

import { dollar, productDefault } from "../image";
import style from "./item.module.sass";

const propTypes = {
  event: PropTypes.shape({
    product: {
      featuredImage: PropTypes.string,
    },
  }),
};
const defaultProps = {
  event: {
    product: {
      featuredImage: "",
    },
  },
};

function ActivityStream({ event, i }) {
  const { product, title, orderId, trackingNumber } = event;
  return (
    <>
      {product && (
        <li key={i} className={style.item}>
          <div className={style.imgBlock}>
            <img className={style.icon} src={dollar} alt="icon" />
          </div>
          <div className={style.title}>
            <h3>PURCHASED:</h3>
            <p>{product.title}</p>
            <span>Order #{orderId}</span>
          </div>
          <div className={style.trackingBlock}>
			  <h3>Tracking number:</h3>
			  <p>{trackingNumber}</p>
		  </div>
          <div className="flexEnd">
            <img className={style.img} src={product.featuredImage.src} alt="product" />
            <div className={style.description}>
              <p>
                {title}, {product.formattedPrice}
              </p>
              <em className={style.data}>{moment(event.createdAt).format("ddd MMM DD HH:mm:ss")}</em>
            </div>
          </div>
        </li>
      )}
    </>
  );
}

ActivityStream.propTypes = propTypes;
ActivityStream.defaultProps = defaultProps;

export default ActivityStream;
