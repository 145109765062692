import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Field } from "react-final-form";

import * as actions from "actions";

import { getIn } from "utils/ramda";
import { getSessionUser } from "selectors/session";

import Button from "components/shared/formDefault/Button";
import Checkbox from "components/shared/formDefault/Checkbox";
import FormGroupAdapter from "components/shared/formDefault/FormGroup/Adapter";
import Input from "components/shared/formDefault/Input";
import Mask from "components/shared/formDefault/Mask";
import Switch from "components/shared/formDefault/Switch";
import ErrorMessage from "components/shared/formDefault/ErrorMessage";
import PhoneAdapter from "components/shared/formDefault/PhoneAdapter";
import { validatePhone, validateEmail } from "components/shared/formDefault/validations";

import mutators from "./mutators";
import styles from "./index.module.sass";

const propTypes = {};
const defaultProps = {};

function ClaimClientForm({ currentUser, match, onSubmit }) {
  const staffId = getIn("id", currentUser);
  const { accountId } = match.params;

  return (
    <Form
      initialValues={{
        client_secret: process.env.CLIENT_SECRET,
        client_id: process.env.CLIENT_ID,
      }}
      mutators={mutators}
      onSubmit={(data) => onSubmit(accountId, staffId, data)}
      render={({ handleSubmit, hasSubmitErrors, submitError, submitting, form, values }) => (
        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <div className={styles.preference}>
            <Switch
              title="Preference"
              radioToggle={false}
              options={[
                { title: "Womenswear", value: "womenswear", name: "client.preference" },
                { title: "Menswear", value: "menswear", name: "client.preference" },
              ]}
            />
            {values.client && values.client.preference && (
              <button type="button" className={styles.resetButton} onClick={form.mutators.clearReference}>
                remove
              </button>
            )}
          </div>

          <Field
            label="Full Name"
            component={FormGroupAdapter}
            inputComponent={Input}
            name="client[fullName]"
            placeholder="Full Name"
            type="text"
          />
          <Field
            label="Email"
            component={FormGroupAdapter}
            inputComponent={Input}
            name="client[email]"
            placeholder="Email Address"
            type="text"
            validate={validateEmail()}
          />
          <Field
            label="Mobile Number"
            component={PhoneAdapter}
            inputComponent={Input}
            name="client[phone]"
            validate={validatePhone()}
          />
          <Field
            label="Birthdate"
            component={FormGroupAdapter}
            inputComponent={Mask}
            typeOfMask="date"
            placeholder="mm/dd/yyyy"
            name="client[dateOfBirth]"
            type="text"
          />
          <Field
            component={FormGroupAdapter}
            inputComponent={(props) => <Checkbox {...props}>Create credentials and invite</Checkbox>}
            name="client[sendEmail]"
            type="checkbox"
          />
          {hasSubmitErrors && submitError && (
            <div className={styles.errorMessageContainer}>
              <ErrorMessage>{submitError}</ErrorMessage>
            </div>
          )}
          <div className={styles.btnGroup}>
            <div className={styles.btnGroupContent}>
              <Button type="submit" disable={submitting}>
                {submitting ? "Submitting…" : "Submit"}
              </Button>
            </div>
          </div>
        </form>
      )}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: getSessionUser(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (accountId, staffId, data) => dispatch(actions.postInviteClient(accountId, staffId, data)),
});

ClaimClientForm.defaultProps = defaultProps;
ClaimClientForm.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClaimClientForm));
